import { Auth as IAuth } from "./interfaces/auth.interface";
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { User } from "./user";
import { LoginResponse } from "../responses/login.response";

export const initialState: IAuth = {
  apiToken: undefined,
  user: new User,
};

export class Auth implements IAuth {
  @JsonProperty("api_token")
  public apiToken: string;
  @JsonProperty({ clazz: User, name: "user" })
  public user: User;

  constructor(init?: Partial<IAuth>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parse(data: LoginResponse): Auth {
    return deserialize(Auth, data);
  }
}
