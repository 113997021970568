var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty } from "json-typescript-mapper";
var MessageUpdateRequest = /** @class */ (function () {
    function MessageUpdateRequest(init) {
        Object.assign(this, init);
    }
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], MessageUpdateRequest.prototype, "id", void 0);
    __decorate([
        JsonProperty("confirmed"),
        __metadata("design:type", Boolean)
    ], MessageUpdateRequest.prototype, "confirmed", void 0);
    return MessageUpdateRequest;
}());
export { MessageUpdateRequest };
