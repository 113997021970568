import { UserState } from "./user.state";
import { UserActions } from "./user.actions";
import { userAdapter } from "./user.adapter";

const initialState: UserState = userAdapter.getInitialState();

export function UserReducer(state: UserState = initialState, action): UserState {
  switch (action.type) {
    case UserActions.ADD_USERS:
      return userAdapter.addMany(
        action.payload,
        userAdapter.removeAll(state)
      );
    case UserActions.ADD_USER:
      return userAdapter.addOne(action.payload, state);
    case UserActions.EDIT_USER:
      return userAdapter.updateOne({
          id: action.payload.id,
          changes: action.payload,
        },
        state
      );
    case UserActions.DELETE_USER:
      return userAdapter.removeOne(action.payload, state);
    default:
      return state;
  }
}
