var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import * as UserSelectors from "../user/user.selectors";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Daypart } from "@models/enums/daypart.enum";
import { Resource } from "@dtos/resource.dto";
import { Sorting } from "@enums/sorting.enum";
import { User } from "@models/user";
import { Variables } from "@app/shared.variables";
import { getEmployeeActivityState } from "../employee-activity/employee-activity.selectors";
import { getStatusState } from "../status/status.selectors";
import { getVehicleState } from "../vehicle/vehicle.selectors";
import { vehicleActivityAdapter } from "./vehicle-activity.adapter";
export var getVehicleActivityState = createFeatureSelector("vehicleActivities");
export var getVehicleActivitySortState = createSelector(getVehicleActivityState, function (state) { return state.sort; });
export var selectIds = (_a = vehicleActivityAdapter.getSelectors(getVehicleActivityState), _a.selectIds), selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var getResources = function () {
    return createSelector(getVehicleState, getVehicleActivitySortState, function (vehicleState, sorting) {
        var resources = [];
        var keys = Object.keys(vehicleState.entities).sort(function (a, b) {
            var aName = vehicleState.entities[a].vehicleNumber.toUpperCase();
            var bName = vehicleState.entities[b].vehicleNumber.toUpperCase();
            var multiplier = sorting === Sorting.asc ? 1 : -1;
            if (aName < bName)
                return -1 * multiplier;
            if (aName > bName)
                return 1 * multiplier;
            return 0;
        });
        var keysLength = keys.length;
        for (var i = 0; i < keysLength; i++) {
            var vehicle = vehicleState.entities[keys[i]];
            var vehicleResourceDay = new Resource();
            vehicleResourceDay.id = vehicle.id;
            vehicleResourceDay.daypart = Daypart.Day;
            resources.push({
                id: vehicleResourceDay.resource,
                columns: [{ html: vehicle.vehicleNumber }, { html: "D" }]
            });
            var vehicleResourceNight = new Resource();
            vehicleResourceNight.id = vehicle.id;
            vehicleResourceNight.daypart = Daypart.Night;
            resources.push({
                id: vehicleResourceNight.resource,
                columns: [{ html: "" }, { html: "N" }]
            });
        }
        return resources;
    });
};
export var getEvents = function () {
    return createSelector(getVehicleState, UserSelectors.getUserState, getEmployeeActivityState, getVehicleActivityState, getStatusState, function (vehicleState, userState, employeeActivityState, vehicleActivityState, statusState) {
        var events = [];
        var employeeActivityKeys = Object.keys(employeeActivityState.entities);
        var employeeActivityKeysLength = employeeActivityKeys.length;
        for (var i = 0; i < employeeActivityKeysLength; i++) {
            var employeeActivity = employeeActivityState.entities[employeeActivityKeys[i]];
            if (employeeActivity) {
                employeeActivity.vehicle =
                    vehicleState.entities[employeeActivity.vehicleId];
                employeeActivity.user = new User(userState.entities[employeeActivity.userId]);
                if (employeeActivity.user && employeeActivity.vehicle) {
                    var isPlannable = employeeActivity.user.role.rules.filter(function (rule) {
                        return rule.name === Variables.PLANNABLE_RULE;
                    });
                    if (isPlannable.length > 0) {
                        if (employeeActivity.activityDaypart ===
                            Daypart.Both) {
                            events.push(createEmployeeEvent(__assign({}, employeeActivity, { activityDaypart: Daypart.Day }), Daypart.Both));
                            events.push(createEmployeeEvent(__assign({}, employeeActivity, { activityDaypart: Daypart.Night }), Daypart.Both));
                        }
                        else {
                            events.push(createEmployeeEvent(employeeActivity));
                        }
                    }
                }
            }
        }
        var vehicleActivityKeys = Object.keys(vehicleActivityState.entities);
        var vehicleActivityKeysLength = vehicleActivityKeys.length;
        for (var i = 0; i < vehicleActivityKeysLength; i++) {
            var vehicleActivity = vehicleActivityState.entities[vehicleActivityKeys[i]];
            if (vehicleActivity) {
                vehicleActivity.vehicle =
                    vehicleState.entities[vehicleActivity.vehicleId];
                vehicleActivity.color =
                    statusState.entities[vehicleActivity.statusId].color;
                if (vehicleActivity.vehicle) {
                    events.push(createVehicleEvent(vehicleActivity, Daypart.Day, statusState.entities[vehicleActivity.statusId]
                        .title));
                    events.push(createVehicleEvent(vehicleActivity, Daypart.Night, statusState.entities[vehicleActivity.statusId]
                        .title));
                }
            }
        }
        return events;
    });
};
export var createEmployeeEvent = function (activity, overwriteResourceDaypart) {
    if (overwriteResourceDaypart === void 0) { overwriteResourceDaypart = undefined; }
    var html = '<span class="description">';
    html += activity.user.lastName;
    html += "</span>";
    var activityResource = new Resource(activity, "vehicleId");
    return {
        id: activity.id,
        resource: activityResource.resource,
        bubbleHtml: activity.comment,
        start: activity.startDate,
        end: activity.endDate,
        html: html,
        activity: overwriteResourceDaypart !== undefined
            ? __assign({}, activity, { activityDaypart: Daypart.Both }) : activity,
        height: 20,
        moveVDisabled: true,
        moveHDisabled: true,
        resizeDisabled: true,
        type: "employee"
    };
};
export var createVehicleEvent = function (vehicleActivity, daypart, title) {
    var html = '<span class="description">';
    html += title;
    html += "</span>";
    var vehicleActivityResource = new Resource(vehicleActivity, "vehicleId");
    vehicleActivityResource.daypart = daypart;
    return {
        id: vehicleActivity.id + "_" + daypart,
        bubbleHtml: vehicleActivity.comment,
        resource: vehicleActivityResource.resource,
        start: vehicleActivity.startDate,
        end: vehicleActivity.endDate,
        html: html,
        vehicleActivity: vehicleActivity,
        height: 20,
        moveVDisabled: false,
        moveHDisabled: false,
        resizeDisabled: false,
        type: "vehicle"
    };
};
