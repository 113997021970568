<div class="modal-header">
  <h4 class="modal-title">Bestanden </h4>
  <h3>Overzicht van de bestanden</h3>
</div>

<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-hover">
    <thead>
      <tr>
        <th>Naam bestand</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of files">
        <td>{{ file.name }}</td>
        <td class="icons">
          <a (click)="downloadFile(file)">
            <i class="ion-ios-download downloadIcon"></i>
          </a>
          <i (click)="removeFile(file)" class="ion-ios-trash icons deleteIcon"></i>
        </td>
      </tr>
    </tbody>
    </table>
    <div class="modal-footer">
      <a class="close-modal float-left" (click)="close()">Sluiten</a>
    </div>
  </div>
</div>
