import { type } from "../utils/utils";
import { VehicleActivities as IVehicleActivities } from "../../models/interfaces/vehicle-activities.interface";
import { VehicleActivity as IVehicleActivity } from "../../models/interfaces/vehicle-activity.interface";

export class VehicleActivityActions {
  static ADD_VEHICLE_ACTIVITIES = type("ADD_VEHICLE_ACTIVITIES");
  static ADD_VEHICLE_ACTIVITY = type("ADD_VEHICLE_ACTIVITY");
  static EDIT_VEHICLE_ACTIVITY = type("EDIT_VEHICLE_ACTIVITY");
  static DELETE_VEHICLE_ACTIVITY = type("DELETE_VEHICLE_ACTIVITY");
  static TOGGLE_VEHICLE_SORT = type("TOGGLE_VEHICLE_SORT");

  static addVehicleActivities(payload: { vehicleActivities: IVehicleActivities, lastUpdate: string }) {
    return {
      type: VehicleActivityActions.ADD_VEHICLE_ACTIVITIES,
      payload
    };
  }

  static addVehicleActivity(payload: IVehicleActivity) {
    return {
      type: VehicleActivityActions.ADD_VEHICLE_ACTIVITY,
      payload
    };
  }

  static editVehicleActivity(payload: IVehicleActivity) {
    return {
      type: VehicleActivityActions.EDIT_VEHICLE_ACTIVITY,
      payload
    };
  }

  static deleteVehicleActivity(id: number) {
    return {
      type: VehicleActivityActions.DELETE_VEHICLE_ACTIVITY,
      payload: id
    };
  }

  public static toggleSort() {
    return {
      type: VehicleActivityActions.TOGGLE_VEHICLE_SORT
    };
  }

}
