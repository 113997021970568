var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Sorting } from "../../models/enums/sorting.enum";
import { EmployeeActivityActions } from "./employee-activity.actions";
import { employeeActivityAdapter } from "./employee-activity.adapter";
import { Sortable } from "@enums/sortable.enum";
// import {Sortable} from "@web/models/enums/sortable.enum";
// import { Sortable } from "../../../../shared/src/models/enums/sortable.enum";
var initialState = employeeActivityAdapter.getInitialState({
    sort: Sorting.asc,
    sortType: Sortable.user,
    lastUpdate: undefined
});
export function EmployeeActivityReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case EmployeeActivityActions.ADD_EMPLOYEE_ACTIVITIES:
            return __assign({}, employeeActivityAdapter.addMany(action.payload.employeeActivities, employeeActivityAdapter.removeAll(state)), { lastUpdate: action.payload.lastUpdate });
        case EmployeeActivityActions.ADD_EMPLOYEE_ACTIVITY:
            return employeeActivityAdapter.addOne(action.payload, state);
        case EmployeeActivityActions.EDIT_EMPLOYEE_ACTIVITY:
            return employeeActivityAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload,
            }, state);
        case EmployeeActivityActions.DELETE_EMPLOYEE_ACTIVITY:
            return employeeActivityAdapter.removeOne(action.payload, state);
        case EmployeeActivityActions.TOGGLE_EMPLOYEE_ACTIVITY_SORT:
            return __assign({}, state, { sort: state.sort === Sorting.asc ? Sorting.desc : Sorting.asc, sortType: action.payload });
        default:
            return state;
    }
}
