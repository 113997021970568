import { Injectable } from "@angular/core";
import { Repository } from "../repository.abstract";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { DashboardActions } from "../../states/dashboard/dashboard.actions";
import * as DashboardSelectors from "../../states/dashboard/dashboard.selectors";

@Injectable()
export class DashboardRepository extends Repository {
    public dashboard$ = this.store.select(DashboardSelectors.getDashboardState);

    constructor(
      protected store: Store<AppState>,
      protected notificationActions: NotificationActions,
      private dashboardService: DashboardService
    ) {
      super(store, notificationActions);
    }

    public setDashboardData(startDate, endDate) {
      this.dashboardService.getTotals(startDate, endDate).subscribe((res) => {
        this.store.dispatch(DashboardActions.addDashboard(res));
      });
    }
}
