var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { User } from "@models/user";
export var initialState = {
    id: undefined,
    name: undefined,
    users: [],
    steps: []
};
var Scheme = /** @class */ (function () {
    function Scheme(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, this.initialState(), init);
    }
    Scheme.parse = function (data) {
        return deserialize(Scheme, data);
    };
    Scheme.parseSocket = function (data) {
        return deserialize(Scheme, data.workscheme);
    };
    Scheme.parseMany = function (data) {
        return data.workschemes.map(function (workscheme) { return deserialize(Scheme, workscheme); });
    };
    Scheme.prototype.initialState = function () {
        return __assign({}, initialState, { users: [], steps: [] });
    };
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], Scheme.prototype, "id", void 0);
    __decorate([
        JsonProperty("name"),
        __metadata("design:type", String)
    ], Scheme.prototype, "name", void 0);
    __decorate([
        JsonProperty({ clazz: User, name: "users" }),
        __metadata("design:type", Array)
    ], Scheme.prototype, "users", void 0);
    __decorate([
        JsonProperty("steps"),
        __metadata("design:type", Array)
    ], Scheme.prototype, "steps", void 0);
    return Scheme;
}());
export { Scheme };
