<div class="notification-item-wrapper border-top">
  <div class="row m-0 py-2">
    <div class="col text-left text-truncate">
      <p class="m-0">{{ activity.user?.lastName }}, {{ activity.user?.firstName }} test</p>
      <p class="m-0 font-weight-bold">{{ activity.activityTypeDetailsDisplay ? activity.activityTypeDetailsDisplay : activity.activityTypeDisplay }}</p>
      <p class="m-0 font-weight-bold date">{{ moment(activity.startDate).format(dateFormat) }} - {{ moment(activity.endDate).format(dateFormat)}} ({{ activity.activityDaypartDisplayShort }})</p>
    </div>
    <div class="col-auto text-right buttons p-0">
      <i class="ion-md-checkmark text-primary border-right px-2" (click)="accept()"></i>
      <i class="ion-md-close text-danger px-2" (click)="decline()"></i>
    </div>
  </div>
</div>
