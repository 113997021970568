var _a;
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { vehicleAdapter } from "./vehicle.adapter";
import { Vehicle } from "@models/vehicle";
export var getVehicleState = createFeatureSelector("vehicles");
export var getAppState = function (state) { return state; }; // TODO: move to own file
export var selectIds = (_a = vehicleAdapter.getSelectors(getVehicleState), _a.selectIds), selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var selectById = function (id) { return createSelector(getVehicleState, function (state) { return state.entities[id]; }); };
export var selectAllWithoutDeleted = function () { return createSelector(getVehicleState, function (state) {
    return state.ids.reduce(function (result, id) {
        var vehicle = new Vehicle(state.entities[id]);
        if (!vehicle.deleted) {
            result.push(vehicle);
        }
        return result;
    }, []);
}); };
