import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthRepository } from "@repositories/auth/auth.repository";
import { Rule } from "@models/rule";
import { User } from "@models/user";

@Directive({
  selector: "[can]"
})
export class CanDirective implements OnInit, OnDestroy {
  @Input("can") action: string | string[];
  @Input("canAttribute") canAttribute;

  private subscription: Subscription;

  constructor(
    private auth: AuthRepository,
    public elem: ElementRef
  ) { }

  ngOnInit() {
    this.action = [].concat(this.action);

    this.subscription = this.auth.auth$.pipe(map(res => res.user || undefined)).subscribe((user: User) => {

      const allowed = user.role.rules.map((userRule: Rule) => {
        if (this.action.indexOf(userRule.name) > -1) {
          return userRule.name;
        }
      }).filter(notUndefined => notUndefined);

      if (allowed.length !== this.action.length) {
        const el = this.elem.nativeElement;
        if (!this.canAttribute && el && el.parentNode) {
          el.parentNode.removeChild(el);
        } else {
          el.setAttribute(this.canAttribute, "true");
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
