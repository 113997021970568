var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { Role } from "./role";
import { Variables } from "../shared.variables";
export var initialState = {
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    fullName: undefined,
    email: undefined,
    role: undefined,
    vehicleId: undefined,
    vehicle: undefined,
    isActive: undefined,
};
var User = /** @class */ (function () {
    function User(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    User.parse = function (data) {
        return deserialize(User, data);
    };
    User.parseSocket = function (data) {
        return deserialize(User, data.user);
    };
    User.parseMany = function (data) {
        return data.users.map(function (user) { return deserialize(User, user); });
    };
    Object.defineProperty(User.prototype, "isPlannable", {
        /**
         * Returns if a user is plannable
         */
        get: function () {
            var result = false;
            // TODO: Maybe find some fancy way to load the resources if they are unavailable
            // AWA: Should we make console logs or something to see if relations are missing. Maybe dev only?
            if (this.role && this.role.rules && this.role.rules.find(function (rule) { return rule.name === Variables.PLANNABLE_RULE; })) {
                result = true;
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], User.prototype, "id", void 0);
    __decorate([
        JsonProperty("first_name"),
        __metadata("design:type", String)
    ], User.prototype, "firstName", void 0);
    __decorate([
        JsonProperty("last_name"),
        __metadata("design:type", String)
    ], User.prototype, "lastName", void 0);
    __decorate([
        JsonProperty("full_name"),
        __metadata("design:type", String)
    ], User.prototype, "fullName", void 0);
    __decorate([
        JsonProperty("email"),
        __metadata("design:type", String)
    ], User.prototype, "email", void 0);
    __decorate([
        JsonProperty({ clazz: Role, name: "role" }),
        __metadata("design:type", Role)
    ], User.prototype, "role", void 0);
    __decorate([
        JsonProperty("vehicle_id"),
        __metadata("design:type", Number)
    ], User.prototype, "vehicleId", void 0);
    __decorate([
        JsonProperty("is_active"),
        __metadata("design:type", Boolean)
    ], User.prototype, "isActive", void 0);
    return User;
}());
export { User };
