import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RangePickerDirective } from "src/app/directives/rangepicker/rangepicker.directive";
import { RangePickerComponent } from "src/app/directives/rangepicker/rangepicker.component";

@NgModule({
  declarations: [
    RangePickerDirective,
    RangePickerComponent
  ],
  entryComponents: [
    RangePickerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RangePickerComponent,
    RangePickerDirective
  ]
})

export class RangePickerModule {}
