import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/guards/auth/auth.guard";
import { IsAuthenticatedGuard } from "src/app/guards/auth/is-authenticated.guard";
import { NgModule } from "@angular/core";

const routes: Routes = [

  {
    path: "", children: [
      { path: "scheduler", canActivate: [AuthGuard], loadChildren: "./modules/scheduler/scheduler.module#SchedulerModule" },
      { path: "tripfiles", canActivate: [AuthGuard], loadChildren: "./modules/tripfiles/tripfiles.module#TripfilesModule" },
      { path: "generalfiles", canActivate: [AuthGuard], loadChildren: "./modules/generalfiles/generalfiles.module#GeneralfilesModule" },
      { path: "dashboard", canActivate: [AuthGuard], loadChildren: "./modules/dashboard/dashboard.module#DashboardModule" },
      { path: "reports/hours", canActivate: [AuthGuard], loadChildren: "./modules/report-hours/report-hours.module#ReportHoursModule" },
      { path: "users", canActivate: [AuthGuard], loadChildren: "./modules/users/users.module#UsersModule" },
      { path: "statuses", canActivate: [AuthGuard], loadChildren: "./modules/statuses/statuses.module#StatusesModule" },
      { path: "vehicles", loadChildren: "./modules/vehicles/vehicles.module#VehiclesModule" },
      { path: "workschemes", loadChildren: "./modules/workschemes/schemes.module#SchemesModule" },
      { path: "", canActivate: [IsAuthenticatedGuard], loadChildren: "./modules/auth/auth.module#AuthModule" }
    ]
  },
  { path: "**", redirectTo: "", pathMatch: "full" } // Redirect to home
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
