import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FileService } from "@services/file/file.service";

@Component({
  selector: "app-file-table-viewer-modal",
  templateUrl: "./file-table-viewer-modal.component.html",
  styleUrls: ["./file-table-viewer-modal.component.scss"]
})
export class FileTableViewerModalComponent implements OnInit {
  public files: File[];

  constructor(
    private bsModalRef: BsModalRef,
    private fileService: FileService,
  ) { }

  ngOnInit() {
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public removeFile(file): void {
    this.fileService.delete(file.id)
      .subscribe(() => {
          this.bsModalRef.hide();
      });
  }

  public downloadFile(file): void {
    this.fileService.download(file.id)
      .subscribe(res => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const element = document.createElement("a");
        element.download = file.name;
        element.href = url;
        element.click();
      });
  }
}
