var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Repository } from "../repository.abstract";
import { Store } from "@ngrx/store";
import { NotificationActions } from "../../states/notification/notification.actions";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { DashboardActions } from "../../states/dashboard/dashboard.actions";
import * as DashboardSelectors from "../../states/dashboard/dashboard.selectors";
var DashboardRepository = /** @class */ (function (_super) {
    __extends(DashboardRepository, _super);
    function DashboardRepository(store, notificationActions, dashboardService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.dashboardService = dashboardService;
        _this.dashboard$ = _this.store.select(DashboardSelectors.getDashboardState);
        return _this;
    }
    DashboardRepository.prototype.setDashboardData = function (startDate, endDate) {
        var _this = this;
        this.dashboardService.getTotals(startDate, endDate).subscribe(function (res) {
            _this.store.dispatch(DashboardActions.addDashboard(res));
        });
    };
    return DashboardRepository;
}(Repository));
export { DashboardRepository };
