import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { prepareAuthHeaders } from "@helpers/http";
import { FilesResponse } from "@responses/files.response";
import { FileResponse } from "@responses/file.response";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class FileService {

  constructor(
    private http: HttpClient
  ) {
  }

  public index(): Observable<FilesResponse> {
    const headers = prepareAuthHeaders();
    return this.http.get<FilesResponse>(`${environment.api}/files`, {headers});
  }

  public upload(fd): Observable<FileResponse> {
    const headers = prepareAuthHeaders();
    return this.http.post<FileResponse>(`${environment.api}/files`, fd, {headers});
  }

  public delete(id: number): Observable<boolean> {
    const headers = prepareAuthHeaders();
    return this.http.delete<boolean>(`${environment.api}/files/${id}`, {headers});
  }

  public download(id: number): Observable<BlobPart> {
    const headers = prepareAuthHeaders();
    return this.http.get<BlobPart>(`${environment.api}/file-download/${id}`, {headers, responseType: "blob" as "json"});
  }

  public show(id: number): string {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return `${environment.api}/file-show/${id}?api_token=${auth.apiToken}`;
  }
}

