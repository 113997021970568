import { deserialize, JsonProperty } from "json-typescript-mapper";
import { Vehicle } from "./vehicle";
import { VehicleActivityResponse } from "../responses/vehicle-activity.response";
import { VehicleActivitiesResponse } from "../responses/vehicle-activities.response";
import { VehicleActivitySocketResponse } from "../responses/vehicle-activity-socket.response";
import { VehicleActivity as IVehicleActivity } from "./interfaces/vehicle-activity.interface";
import { VehicleActivities as IVehicleActivities } from "./interfaces/vehicle-activities.interface";
import { Status } from "./status";

export const initialState: IVehicleActivity = {
    id: undefined,
    startDate: undefined,
    endDate: undefined,
    vehicleId: undefined,
    vehicle: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    statusId: undefined,
    color: undefined,
    comment: undefined
};

export class VehicleActivity implements IVehicleActivity {
    @JsonProperty("id") public id: number;
    @JsonProperty("start_date") public startDate: string;
    @JsonProperty("end_date") public endDate: string;
    @JsonProperty("vehicle_id") public vehicleId: number;
    public vehicle: Vehicle;
    @JsonProperty("created_at") public createdAt: string;
    @JsonProperty("updated_at") public updatedAt: string;
    @JsonProperty("status_id") public statusId: number;
    public color: Status["color"];
    @JsonProperty("comment") public comment: string;

    constructor(init?: Partial<IVehicleActivity>) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }

    public static parse(data: VehicleActivityResponse): VehicleActivity {
        return deserialize(VehicleActivity, data);
    }

    public static parseSocket(
        data: VehicleActivitySocketResponse
    ): VehicleActivity {
        return deserialize(VehicleActivity, data.vehicle_activity);
    }

    public static parseMany(
        data: VehicleActivitiesResponse
    ): IVehicleActivities {
        return data.vehicle_activities.map(vehicleActivity =>
            deserialize(VehicleActivity, vehicleActivity)
        );
    }
}
