import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { RoleActions } from "../../states/role/role.actions";
import { RoleService } from "../../services/role/role.service";
import { Role } from "../../models/role";
import { Vehicle as IVehicle } from "../../models/interfaces/vehicle.interface";

@Injectable()
export class RoleRepository extends Repository {
  public roles$: Observable<Role[]> = this.store.select(s => s.roles.roles);

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private roleService: RoleService
  ) {
    super(store, notificationActions);
  }

  public index(): Observable<{} | IVehicle[]> {
    return this.roleService.index().pipe(map(response => {
        const roles = Role.parseMany(response);
        this.store.dispatch(RoleActions.addRoles(roles));
        return roles;
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }
}
