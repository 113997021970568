import { type } from "../utils/utils";
import { Status as IStatus } from "@app/models/interfaces/status.interface";

export class StatusActions {
  static ADD_STATUS = type("ADD_STATUS");
  static ADD_STATUSES = type("ADD_STATUSES");
  static REMOVE_STATUS = type("REMOVE_STATUS");
  static EDIT_STATUS = type("EDIT_STATUS");

  static addStatuses(payload: IStatus[]) {
    return {
      type: StatusActions.ADD_STATUSES,
      payload
    };
  }

  static addStatus(payload: IStatus) {
    return {
      type: StatusActions.ADD_STATUS,
      payload
    };
  }

  static editUser(payload: IStatus) {
    return {
      type: StatusActions.EDIT_STATUS,
      payload
    };
  }

  static removeStatus(payload: number) {
      return {
          type: StatusActions.REMOVE_STATUS,
          payload
      };
  }
}
