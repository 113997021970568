import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { prepareAuthHeaders, prepareBody } from "@helpers/http";
import { EmployeeActivitiesResponse } from "@responses/employee-activities.response";
import { EmployeeActivityCreateRequest } from "@requests/employee-activity-create.request";
import { EmployeeActivityResponse } from "@responses/employee-activity.response";
import { EmployeeActivityUpdateRequest } from "@requests/employee-activity-update.request";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { EmployeeActivityReportResponse } from "@responses/employee-activity-report.response";
import { EmployeeActivitySetLiveRequest } from "@requests/employee-activity-set-live.request";
import { EmployeeActivityExportRequest } from "@app/requests/employee-activity-export.request";

@Injectable()
export class EmployeeActivityService {
    constructor(private http: HttpClient) {}

    public index(
        date: string,
        weeks: number
    ): Observable<EmployeeActivitiesResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<EmployeeActivitiesResponse>(
            `${environment.api}/activities/${date}/${weeks}`,
            { headers }
        );
    }

    public list(
        startDate: string,
        endDate: string
    ): Observable<EmployeeActivitiesResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<EmployeeActivitiesResponse>(
            `${environment.api}/activitieslist/${startDate}/${endDate}`,
            { headers }
        );
    }

    public report(
        startDate: string,
        endDate: string
    ): Observable<EmployeeActivityReportResponse> {
        const headers = prepareAuthHeaders();

        let params = new HttpParams();
        params = params.append("start_date", startDate);
        params = params.append("end_date", endDate);

        return this.http.get<EmployeeActivityReportResponse>(
            `${environment.api}/activities/report`,
            { headers, params }
        );
    }

    public create(
        request: EmployeeActivityCreateRequest
    ): Observable<EmployeeActivityResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.post<EmployeeActivityResponse>(
            `${environment.api}/activity`,
            body,
            { headers }
        );
    }

    public update(
        id: number,
        request: EmployeeActivityUpdateRequest
    ): Observable<EmployeeActivityResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.put<EmployeeActivityResponse>(
            `${environment.api}/activity/${id}`,
            body,
            { headers }
        );
    }

    public delete(id: number): Observable<EmployeeActivityResponse> {
        const headers = prepareAuthHeaders();
        return this.http.delete<EmployeeActivityResponse>(
            `${environment.api}/activity/${id}`,
            { headers }
        );
    }

    public accept(id): Observable<EmployeeActivityResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<EmployeeActivityResponse>(
            `${environment.api}/activity/${id}/accept`,
            { headers }
        );
    }

    public decline(id): Observable<EmployeeActivityResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<EmployeeActivityResponse>(
            `${environment.api}/activity/${id}/decline`,
            { headers }
        );
    }

    public setLive(
        request: EmployeeActivitySetLiveRequest
    ): Observable<EmployeeActivitiesResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.post<EmployeeActivitiesResponse>(
            `${environment.api}/activity/set-live`,
            body,
            { headers }
        );
    }

    public excelExport(
        request: EmployeeActivityExportRequest
    ): Observable<EmployeeActivitiesResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.post<EmployeeActivitiesResponse>(
            `${environment.api}/activity/export-excel`,
            body,
            { headers }
        );
    }
}
