import * as UserSelectors from "../user/user.selectors";
import * as VehicleSelectors from "../vehicle/vehicle.selectors";

import { createFeatureSelector, createSelector } from "@ngrx/store";

import { DayPilot } from "daypilot-pro-angular";
import { Daypart } from "@enums/daypart.enum";
import { EmployeeActivity } from "@models/employee-activity";
import { EmployeeActivityState } from "./employee-activity.state";
import { EmployeeActivityStatus } from "@enums/employee-activity-status.enum";
import { EmployeeActivityType } from "@enums/employee-activity-type.enum";
import { EmployeeActivity as IEmployeeActivity } from "@interfaces/employee-activity.interface";
import { Resource } from "@dtos/resource.dto";
import { Sortable } from "@enums/sortable.enum";
import { Sorting } from "@enums/sorting.enum";
import { User } from "@models/user";
import { UserState } from "../user/user.state";
import { Vehicle } from "@models/vehicle";
import { VehicleState } from "../vehicle/vehicle.state";
import { employeeActivityAdapter } from "./employee-activity.adapter";

export const getEmployeeActivityState =
    createFeatureSelector<EmployeeActivityState>("employeeActivities");
export const getEmployeeActivitySortState = createSelector(
    getEmployeeActivityState,
    (state: EmployeeActivityState) => {
        return { sort: state.sort, type: state.sortType };
    }
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
    employeeActivityAdapter.getSelectors(getEmployeeActivityState);

export const selectAllMapped = () =>
    createSelector(
        selectAll,
        UserSelectors.getUserState,
        VehicleSelectors.getVehicleState,
        (
            employeeActivities: IEmployeeActivity[],
            userState: UserState,
            vehicleState: VehicleState
        ) => {
            return employeeActivities.map((activity: IEmployeeActivity) => {
                const employeeActivity = new EmployeeActivity(activity);
                employeeActivity.user = new User(
                    userState.entities[employeeActivity.userId]
                );
                employeeActivity.vehicle = employeeActivity.vehicleId
                    ? new Vehicle(
                          vehicleState.entities[employeeActivity.vehicleId]
                      )
                    : undefined;
                return employeeActivity;
            });
        }
    );

export const selectNotificationEmployeeActivites = () =>
    createSelector(
        selectAllMapped(),
        (employeeActivities: EmployeeActivity[]) => {
            const notifications = [];
            const length = employeeActivities.length;
            for (let i = 0; i < length; i++) {
                const employeeActivity = new EmployeeActivity(
                    employeeActivities[i]
                );
                if (
                    employeeActivity.activityType ===
                        EmployeeActivityType.Unavailable &&
                    employeeActivity.status === EmployeeActivityStatus.Pending
                ) {
                    notifications.push(employeeActivity);
                }
            }
            return notifications;
        }
    );

export const getResources = () =>
    createSelector(
        UserSelectors.planneableUsers(),
        getEmployeeActivityState,
        getEmployeeActivitySortState,
        (
            users: User[],
            employeeActivityState: EmployeeActivityState,
            sorting: { sort: Sorting; type: Sortable }
        ) => {
            const resources: DayPilot.ResourceData[] = [];

            users.sort((a: User, b: User) => {
                let aSort;
                let bSort;
                if (sorting.type === Sortable.user) {
                    aSort = a.lastName.toUpperCase();
                    bSort = b.lastName.toUpperCase();
                }

                if (sorting.type === Sortable.vehicle_label) {
                    aSort = a.vehicle ? a.vehicle.label.toUpperCase() : "";
                    bSort = b.vehicle ? b.vehicle.label.toUpperCase() : "";
                }

                const multiplier = sorting.sort === Sorting.asc ? 1 : -1;
                if (aSort < bSort) {
                    return -1 * multiplier;
                }
                if (aSort > bSort) {
                    return 1 * multiplier;
                }
                return 0;
            });

            const length = users.length;
            for (let i = 0; i < length; i++) {
                const employeeActivityResourceDay =
                    new Resource<EmployeeActivity>();

                const user = users[i];

                if (user.isActive) {
                    employeeActivityResourceDay.id = user.id;
                    employeeActivityResourceDay.daypart = Daypart.Day;

                    resources.push({
                        id: employeeActivityResourceDay.resource,
                        name: `${user.fullName} ${(user.vehicle ? user.vehicle.label : "")} `,
                        columns: [
                            { html: user.fullName, },
                            { html: "D" },
                            { html: user.vehicle ? user.vehicle.label : "" },
                        ],
                    });

                    const activities = (employeeActivityState.ids as number[]).map(
                        (x) => employeeActivityState.entities[x]
                    );
                    const employeeActivityResourceNight =
                        new Resource<EmployeeActivity>();
                    employeeActivityResourceNight.id = user.id;
                    employeeActivityResourceNight.daypart = Daypart.Night;

                    resources.push({
                        id: employeeActivityResourceNight.resource,
                        name: `${user.fullName} ${(user.vehicle ? user.vehicle.label : "")} `,
                        columns: [{ html: "N" }, {html: "", }, ],
                        events: activities.filter((x) => x.userId === user.id),
                    });
                }
            }

            return resources;
        }
    );

export const getEvents = () =>
    createSelector(
        VehicleSelectors.getVehicleState,
        UserSelectors.getUserState,
        getEmployeeActivityState,
        (
            vehicleState: VehicleState,
            userState: UserState,
            employeeActivityState: EmployeeActivityState
        ) => {
            const events = [];

            const employeeActivityKeys = Object.keys(
                employeeActivityState.entities
            );
            const employeeActivityKeysLength = employeeActivityKeys.length;
            for (let i = 0; i < employeeActivityKeysLength; i++) {
                const employeeActivity: IEmployeeActivity =
                    employeeActivityState.entities[employeeActivityKeys[i]];
                if (
                    employeeActivity &&
                    userState.entities[employeeActivity.userId].isActive === true
                ) {
                    employeeActivity.vehicle =
                        vehicleState.entities[employeeActivity.vehicleId];
                    employeeActivity.user = new User(
                        userState.entities[employeeActivity.userId]
                    );
                    if (employeeActivity.activityDaypart === Daypart.Both) {
                        events.push(
                            createEmployeeEvent(
                                {
                                    ...employeeActivity,
                                    activityDaypart: Daypart.Day,
                                },
                                Daypart.Both
                            )
                        );
                        events.push(
                            createEmployeeEvent(
                                {
                                    ...employeeActivity,
                                    activityDaypart: Daypart.Night,
                                },
                                Daypart.Both
                            )
                        );
                    } else {
                        events.push(createEmployeeEvent(employeeActivity));
                    }
                }
            }
            return events;
        }
    );

function getActivityResourceText(activity: EmployeeActivity) {
    switch (activity.activityType) {
        case +EmployeeActivityType.Work:
            return activity.vehicle.description;
        case +EmployeeActivityType.Unavailable:
            return activity.activityTypeDetails !== undefined
                ? activity.activityTypeDetailsDisplay
                : activity.activityTypeDisplay;
        case +EmployeeActivityType.Available:
            return "Beschikbaar";
        default:
            return "Onbekend";
    }
}

export const createEmployeeEvent = (
    activity: IEmployeeActivity,
    overwriteResourceDaypart: Daypart = undefined
): any => {
    const employeeActivity = new EmployeeActivity(activity);

    let html = '<span class="description">';
    html += getActivityResourceText(employeeActivity);
    html += "</span>";

    const activityResource = new Resource<EmployeeActivity>(
        employeeActivity,
        "userId"
    );

    return {
        id: employeeActivity.id + "-" + activity.activityDaypart, // MW: fix for duplicate id issue??
        bubbleHtml: activity.comment,
        resource: activityResource.resource,
        vehicle: employeeActivity.vehicle,
        start: employeeActivity.startDate,
        end: employeeActivity.endDate,
        html,
        employeeActivity:
            overwriteResourceDaypart !== undefined
                ? { ...employeeActivity, activityDaypart: Daypart.Both }
                : employeeActivity,
        height: 20,
        moveVDisabled:
            employeeActivity.activityType !== EmployeeActivityType.Work,
        moveHDisabled: false,
        resizeDisabled: false,
        type: "employee",
    };
};
