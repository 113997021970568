/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./notification-item/notification-item.component.ngfactory";
import * as i3 from "./notification-item/notification-item.component";
import * as i4 from "./message-item/message-item.component.ngfactory";
import * as i5 from "./message-item/message-item.component";
import * as i6 from "@angular/common";
import * as i7 from "./notifications.component";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-item", [], null, [[null, "accept"], [null, "decline"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("accept" === en)) {
        var pd_0 = (_co.onAccept($event) !== false);
        ad = (pd_0 && ad);
    } if (("decline" === en)) {
        var pd_1 = (_co.onDecline($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NotificationItemComponent_0, i2.RenderType_NotificationItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationItemComponent, [], { activity: [0, "activity"] }, { accepted: "accept", declined: "decline" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-item", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.markCompleted($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MessageItemComponent_0, i4.RenderType_MessageItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessageItemComponent, [], { message: [0, "message"] }, { completed: "completed" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isEmployeeActivity(_v.context.$implicit) && _co.shouldShow(_v.context.index)) && _co.canAcceptAndDecline(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isMessage(_v.context.$implicit) && _co.shouldShow(_v.context.index)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_NotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "ion-md-done-all"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Je bent helemaal up-to-date!"]))], null, null); }
function View_NotificationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prevPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Vorige"]))], null, null); }
function View_NotificationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Volgende"]))], null, null); }
function View_NotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_6)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_7)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentPage > 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.currentPage <= (_co.pendingActivities.length / _co.maxPageSize)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "notifications-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "inner-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notificaties"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "notitication-items-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_4)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_5)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingActivities; _ck(_v, 6, 0, currVal_0); var currVal_1 = (!_co.pendingActivities || (_co.pendingActivities.length < 1)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.shouldPaginate(); _ck(_v, 10, 0, currVal_2); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i7.NotificationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i7.NotificationsComponent, View_NotificationsComponent_Host_0, { pendingActivities: "activities" }, { accepted: "acceptActivity", declined: "declineActivity", completed: "completed" }, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
