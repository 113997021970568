import { Component } from "@angular/core";
import { version as appVersion } from "../../../../package.json";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavBarComponent {
  public version: string = appVersion;
}
