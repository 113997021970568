/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-create-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./category-create-modal.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../services/category/category.service";
var styles_CategoryCreateModalComponent = [i0.styles];
var RenderType_CategoryCreateModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryCreateModalComponent, data: {} });
export { RenderType_CategoryCreateModalComponent as RenderType_CategoryCreateModalComponent };
export function View_CategoryCreateModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nieuwe categorie"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vul de onderstaande gegevens in"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-body "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "row my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["for", "categoryName"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Naam categorie:"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["class", "form-control input-lg"], ["id", "categoryName"], ["placeholder", "Naam categorie"], ["type", "text"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onCategoryName($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary float-left"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Maak categorie"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "close-modal float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sluiten"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formInvalid(); _ck(_v, 13, 0, currVal_0); }); }
export function View_CategoryCreateModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-category-create-modal", [], null, null, null, View_CategoryCreateModalComponent_0, RenderType_CategoryCreateModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.CategoryCreateModalComponent, [i3.BsModalRef, i4.CategoryService], null, null)], null, null); }
var CategoryCreateModalComponentNgFactory = i1.ɵccf("app-category-create-modal", i2.CategoryCreateModalComponent, View_CategoryCreateModalComponent_Host_0, {}, {}, []);
export { CategoryCreateModalComponentNgFactory as CategoryCreateModalComponentNgFactory };
