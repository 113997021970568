import { Observable, of as observableOf, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { VehicleActions } from "../../states/vehicle/vehicle.actions";
import { VehicleService } from "../../services/vehicle/vehicle.service";
import { VehicleCreateRequest } from "../../requests/vehicle-create.request";
import { VehicleSocketResponse } from "../../responses/vehicle-socket.response";
import { VehicleUpdateRequest } from "../../requests/vehicle-update.request";
import { Vehicle } from "../../models/vehicle";
import { Vehicle as IVehicle } from "../../models/interfaces/vehicle.interface";
import * as VehicleSelectors from "../../states/vehicle/vehicle.selectors";
import { planneableUsers } from "../../states/user/user.selectors";

@Injectable()
export class VehicleRepository extends Repository {
  public vehicles$: Observable<IVehicle[]> = this.store.select(VehicleSelectors.selectAllWithoutDeleted());
  private readonly CREATE_SUCCESS: string = "Voertuig aangemaakt!";
  private readonly EDIT_SUCCESS: string = "Voertuig aangepast!";

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private vehicleService: VehicleService
  ) {
    super(store, notificationActions);
  }

  public index(): Observable<{} | IVehicle[]> {
    return this.vehicleService.index().pipe(map(response => {
      const vehicles = Vehicle.parseMany(response);
      this.store.dispatch(VehicleActions.addVehicles(vehicles));
      return vehicles;
    }), catchError(response => {
      this.setError(JSON.stringify(response.error));
      return observableOf(undefined);
    })
    );
  }

  public create(request: VehicleCreateRequest): Observable<{} | IVehicle> {
    return this.vehicleService.create(request).pipe(map(response => {
      const vehicle = Vehicle.parse(response);
      this.store.dispatch(VehicleActions.addVehicle(vehicle));
      this.setInfo(this.CREATE_SUCCESS);
      return vehicle;
    }), catchError((response: HttpErrorResponse) => {
      this.setError(JSON.stringify(response.error));
      return observableOf(undefined);
    })
    );
  }

  public createFromSocket(response: VehicleSocketResponse): void {
    const vehicle = Vehicle.parseSocket(response);
    this.store.dispatch(VehicleActions.addVehicle(vehicle));
  }

  public update(id: number, request: VehicleUpdateRequest): Observable<{} | IVehicle> {
    return this.vehicleService.update(id, request).pipe(map(response => {
      const vehicle = Vehicle.parse(response);
      this.store.dispatch(VehicleActions.editVehicle(vehicle));
      this.setInfo(this.EDIT_SUCCESS);
      return vehicle;
    }), catchError((response: HttpErrorResponse) => {
      this.setError(JSON.stringify(response.error));
      return observableOf(undefined);
    })
    );
  }

  public updateFromSocket(response: VehicleSocketResponse): void {
    const vehicle = Vehicle.parseSocket(response);
    this.store.dispatch(VehicleActions.editVehicle(vehicle));
  }

  public delete(id: number) {
    return this.vehicleService.delete(id).pipe(map(response => {
      this.store.dispatch(VehicleActions.deleteVehicle(id));
      return response;
    }), catchError((response: HttpErrorResponse) => {
      this.setError(JSON.stringify(response.error));
      return observableOf(undefined);
    })
    );
  }

  public deleteFromSocket(response: VehicleSocketResponse): void {
    const vehicle = Vehicle.parseSocket(response);
    this.store.dispatch(VehicleActions.deleteVehicle(vehicle.id));
  }

}
