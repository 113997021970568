import * as EmployeeActivitySelectors from "../../states/employee-activity/employee-activity.selectors";
import * as moment_ from "moment";

import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppState } from "@states/app/app.state";
import { EmployeeActivity } from "@models/employee-activity";
import { EmployeeActivityActions } from "@states/employee-activity/employee-activity.actions";
import { EmployeeActivityCreateRequest } from "@requests/employee-activity-create.request";
import { EmployeeActivityService } from "@services/employee-activity/employee-activity.service";
import { EmployeeActivitySocketResponse } from "@responses/employee-activity-socket.response";
import { EmployeeActivityUpdateRequest } from "@requests/employee-activity-update.request";
import { HttpErrorResponse } from "@angular/common/http";
import { EmployeeActivities as IEmployeeActivities } from "@models/interfaces/employee-activities.interface";
import { EmployeeActivity as IEmployeeActivity } from "@models/interfaces/employee-activity.interface";
import { Injectable } from "@angular/core";
import { NotificationActions } from "@states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { Sortable } from "@enums/sortable.enum";
import { Store } from "@ngrx/store";

const moment = moment_;

@Injectable()
export class EmployeeActivityRepository extends Repository {
    // Observables
    public employeeActivities$: Observable<IEmployeeActivities> =
        this.store.select(EmployeeActivitySelectors.selectEntities);
    public resources$ = (): Observable<any> =>
        this.store.select(EmployeeActivitySelectors.getResources());
    public events$ = (): Observable<any> =>
        this.store.select(EmployeeActivitySelectors.getEvents());
    public lastUpdate$: Observable<string> = this.store.select(
        (s) => s.employeeActivities.lastUpdate
    );
    // Activities mapped to flat activities, undone of the key-value pair construction
    public employeeActivitiesFlat$ = () =>
        this.store.select(EmployeeActivitySelectors.selectAllMapped());
    // Inputs & Outputs
    public employeeActivityNotifications$ = () =>
        this.store.select(
            EmployeeActivitySelectors.selectNotificationEmployeeActivites()
        );
    // Readonly fields
    private readonly CREATE_SUCCESS: string = "Activiteit aangemaakt!";
    private readonly EDIT_SUCCESS: string = "Activiteit aangepast!";
    private readonly DELETE_SUCCESS: string = "Activiteit verwijderd!";
    private readonly ACCEPT_SUCCESS: string = "Activiteit geaccepteerd!";
    private readonly DECLINE_SUCCESS: string = "Activiteit afgewezen!";
    private readonly SET_LIVE: string = "Activiteiten definitief gemaakt!";
    // Public properties
    // Private fields
    private schedulerStartDate: moment_.Moment = moment().startOf("isoWeek");

    constructor(
        protected store: Store<AppState>,
        protected notificationActions: NotificationActions,
        private employeeActivityService: EmployeeActivityService
    ) {
        super(store, notificationActions);
    }

    public get schedulerStart() {
        return this.schedulerStartDate;
    }

    public set schedulerStart(date: moment_.Moment) {
        this.schedulerStartDate = moment(date);
    }

    public index(
        date: string,
        weeks: number
    ): Observable<{} | EmployeeActivity[]> {
        return this.employeeActivityService.index(date, weeks).pipe(
            map((response) => {
                const employeeActivities = EmployeeActivity.parseMany(response);
                const lastUpdate = response.lastUpdate;
                this.store.dispatch(
                    EmployeeActivityActions.addEmployeeActivities({
                        employeeActivities,
                        lastUpdate,
                    })
                );
                return employeeActivities;
            }),
            catchError((response) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public list(
        startDate: string,
        endDate: string
    ): Observable<{} | EmployeeActivity[]> {
        return this.employeeActivityService.list(startDate, endDate).pipe(
            map((response) => {
                const employeeActivities = EmployeeActivity.parseMany(response);
                const lastUpdate = response.lastUpdate;
                this.store.dispatch(
                    EmployeeActivityActions.addEmployeeActivities({
                        employeeActivities,
                        lastUpdate,
                    })
                );
                return employeeActivities;
            }),
            catchError((response) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public report(startDate: string, endDate: string): Observable<{}[]> {
        return this.employeeActivityService.report(startDate, endDate).pipe(
            map((response) => {
                return response.data;
            }),
            catchError((response) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public create(
        request: EmployeeActivityCreateRequest
    ): Observable<{} | IEmployeeActivity> {
        return this.employeeActivityService.create(request).pipe(
            map((response) => {
                const employeeActivity = EmployeeActivity.parse(response);
                this.store.dispatch(
                    EmployeeActivityActions.addEmployeeActivity(
                        employeeActivity
                    )
                );
                this.setInfo(this.CREATE_SUCCESS);
                return employeeActivity;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public createFromSocket(response: EmployeeActivitySocketResponse): void {
        const employeeActivity = EmployeeActivity.parseSocket(response);
        this.store.dispatch(
            EmployeeActivityActions.addEmployeeActivity(employeeActivity)
        );
    }

    public update(
        id: number,
        request: EmployeeActivityUpdateRequest
    ): Observable<{} | IEmployeeActivity> {
        return this.employeeActivityService.update(id, request).pipe(
            map((response) => {
                const employeeActivity = EmployeeActivity.parse(response);
                this.store.dispatch(
                    EmployeeActivityActions.editEmployeeActivity(
                        employeeActivity
                    )
                );
                this.setInfo(this.EDIT_SUCCESS);
                return employeeActivity;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public updateFromSocket(response: EmployeeActivitySocketResponse): void {
        const employeeActivity = EmployeeActivity.parseSocket(response);
        this.store.dispatch(
            EmployeeActivityActions.editEmployeeActivity(employeeActivity)
        );
    }

    public delete(id: number): Observable<{} | IEmployeeActivity> {
        return this.employeeActivityService.delete(id).pipe(
            map((response) => {
                this.store.dispatch(
                    EmployeeActivityActions.deleteEmployeeActivity(id)
                );
                this.setInfo(this.DELETE_SUCCESS);
                return response;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public deleteFromSocket(response: EmployeeActivitySocketResponse): void {
        const employeeActivity = EmployeeActivity.parseSocket(response);
        this.store.dispatch(
            EmployeeActivityActions.deleteEmployeeActivity(employeeActivity.id)
        );
    }

    /**
     * Handles the business logic of accepting an EmployeeActivity
     * @param id
     */
    public accept(id: number): Observable<{} | boolean> {
        return this.employeeActivityService.accept(id).pipe(
            map((response) => {
                const employeeActivity = EmployeeActivity.parse(response);
                this.store.dispatch(
                    EmployeeActivityActions.editEmployeeActivity(
                        employeeActivity
                    )
                );
                this.setInfo(this.ACCEPT_SUCCESS);
                return response;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    /**
     * Handles the business logic of declining an EmployeeActivity
     * @param id
     */
    public decline(id: number): Observable<{} | boolean> {
        return this.employeeActivityService.decline(id).pipe(
            map((response) => {
                this.store.dispatch(
                    EmployeeActivityActions.deleteEmployeeActivity(id)
                );
                this.setInfo(this.DECLINE_SUCCESS);
                return response;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public setLive(req): Observable<{} | boolean> {
        return this.employeeActivityService.setLive(req).pipe(
            map((response) => {
                const employeeActivities = EmployeeActivity.parseMany(response);
                for (const a in employeeActivities) {
                    this.store.dispatch(
                        EmployeeActivityActions.editEmployeeActivity(
                            employeeActivities[a]
                        )
                    );
                    this.setInfo(this.SET_LIVE);
                }

                return employeeActivities;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public exportExcel(req): Observable<{ data: { id; name; url } }> {
        return this.employeeActivityService.excelExport(req).pipe(
            map((response) => {
                return response;
            }),
            catchError((response: HttpErrorResponse) => {
                this.setError(JSON.stringify(response.error));
                return observableOf(undefined);
            })
        );
    }

    public toggleSort() {
        this.store.dispatch(EmployeeActivityActions.toggleSort(Sortable.user));
    }

    public toggleSortVehicleLabel() {
        this.store.dispatch(
            EmployeeActivityActions.toggleSort(Sortable.vehicle_label)
        );
    }
}
