import { type } from "../utils/utils";
import { EmployeeActivity as IEmployeeActivity } from "../../models/interfaces/employee-activity.interface";
import { EmployeeActivities as IEmployeeActivities } from "../../models/interfaces/employee-activities.interface";
import { Sortable } from "@enums/sortable.enum";

export class EmployeeActivityActions {
  public static ADD_EMPLOYEE_ACTIVITIES = type("ADD_EMPLOYEE_ACTIVITIES");
  public static ADD_EMPLOYEE_ACTIVITY = type("ADD_EMPLOYEE_ACTIVITY");
  public static EDIT_EMPLOYEE_ACTIVITY = type("EDIT_EMPLOYEE_ACTIVITY");
  public static DELETE_EMPLOYEE_ACTIVITY = type("DELETE_EMPLOYEE_ACTIVITY");
  public static TOGGLE_EMPLOYEE_ACTIVITY_SORT = type("TOGGLE_EMPLOYEE_ACTIVITY_SORT");

  public static addEmployeeActivities(payload: { employeeActivities: IEmployeeActivities, lastUpdate: string }) {
    return {
      type: EmployeeActivityActions.ADD_EMPLOYEE_ACTIVITIES,
      payload
    };
  }

  public static addEmployeeActivity(payload: IEmployeeActivity) {
    return {
      type: EmployeeActivityActions.ADD_EMPLOYEE_ACTIVITY,
      payload
    };
  }

  public static editEmployeeActivity(payload: IEmployeeActivity) {
    return {
      type: EmployeeActivityActions.EDIT_EMPLOYEE_ACTIVITY,
      payload
    };
  }

  public static deleteEmployeeActivity(id: number) {
    return {
      type: EmployeeActivityActions.DELETE_EMPLOYEE_ACTIVITY,
      payload: id
    };
  }

  public static toggleSort(type: Sortable = Sortable.user) {
    return {
      type: EmployeeActivityActions.TOGGLE_EMPLOYEE_ACTIVITY_SORT,
      payload: type
    };
  }
}
