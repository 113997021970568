import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable()
export class DeviceInterceptor implements HttpInterceptor {

  private readonly DEVICE: string;

  constructor(
    private router: Router,
  ) {
    this.DEVICE = environment.device ? environment.device : "";
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = request.clone({
      headers: request.headers.set("X-Device", this.DEVICE)
    });
    return next.handle(req);
  }
}
