var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Repository } from "../repository.abstract";
import { NotificationActions } from "@states/notification/notification.actions";
import { VehicleActivityService } from "@services/vehicle-activity/vehicle-activity.service";
import { VehicleActivityActions } from "@states/vehicle-activity/vehicle-activity.actions";
import { VehicleActivity } from "@models/vehicle-activity";
import * as moment_ from "moment";
import * as VehicleActivitySelectors from "../../states/vehicle-activity/vehicle-activity.selectors";
var moment = moment_;
var VehicleActivityRepository = /** @class */ (function (_super) {
    __extends(VehicleActivityRepository, _super);
    function VehicleActivityRepository(store, notificationActions, vehicleActivityService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.vehicleActivityService = vehicleActivityService;
        // Readonly fields
        _this.CREATE_SUCCESS = "Activiteit aangemaakt!";
        _this.EDIT_SUCCESS = "Activiteit aangepast!";
        _this.DELETE_SUCCESS = "Activiteit verwijderd!";
        // Inputs & Outputs
        // Observables
        _this.vehicleActivities$ = _this.store.select(VehicleActivitySelectors.selectEntities);
        _this.resources$ = function () { return _this.store.select(VehicleActivitySelectors.getResources()); };
        _this.events$ = function () { return _this.store.select(VehicleActivitySelectors.getEvents()); };
        _this.lastUpdate$ = _this.store.select(function (s) { return s.vehicleActivities.lastUpdate; });
        // Activities mapped to flat activities, undone of the key-value pair construction
        _this.vehicleActivitiesFlat$ = _this.store.select(VehicleActivitySelectors.selectAll);
        // Public properties
        // Private fields
        _this.schedulerStartDate = moment().startOf("isoWeek");
        return _this;
    }
    Object.defineProperty(VehicleActivityRepository.prototype, "schedulerStart", {
        get: function () {
            return this.schedulerStartDate;
        },
        set: function (date) {
            this.schedulerStartDate = moment(date);
        },
        enumerable: true,
        configurable: true
    });
    VehicleActivityRepository.prototype.index = function () {
        var _this = this;
        return this.vehicleActivityService.index().pipe(map(function (response) {
            var vehicleActivities = VehicleActivity.parseMany(response);
            var lastUpdate = response.lastUpdate;
            _this.store.dispatch(VehicleActivityActions.addVehicleActivities({ vehicleActivities: vehicleActivities, lastUpdate: lastUpdate }));
            return vehicleActivities;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleActivityRepository.prototype.create = function (request) {
        var _this = this;
        return this.vehicleActivityService.create(request).pipe(map(function (response) {
            var vehicleActivity = VehicleActivity.parse(response);
            _this.store.dispatch(VehicleActivityActions.addVehicleActivity(vehicleActivity));
            _this.setInfo(_this.CREATE_SUCCESS);
            return vehicleActivity;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleActivityRepository.prototype.createFromSocket = function (response) {
        var vehicleActivity = VehicleActivity.parseSocket(response);
        this.store.dispatch(VehicleActivityActions.addVehicleActivity(vehicleActivity));
    };
    VehicleActivityRepository.prototype.update = function (id, request) {
        var _this = this;
        return this.vehicleActivityService.update(id, request).pipe(map(function (response) {
            var vehicleActivity = VehicleActivity.parse(response);
            _this.store.dispatch(VehicleActivityActions.editVehicleActivity(vehicleActivity));
            _this.setInfo(_this.EDIT_SUCCESS);
            return vehicleActivity;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleActivityRepository.prototype.updateFromSocket = function (response) {
        var vehicleActivity = VehicleActivity.parseSocket(response);
        this.store.dispatch(VehicleActivityActions.editVehicleActivity(vehicleActivity));
    };
    VehicleActivityRepository.prototype.delete = function (id) {
        var _this = this;
        return this.vehicleActivityService.delete(id).pipe(map(function (response) {
            _this.store.dispatch(VehicleActivityActions.deleteVehicleActivity(id));
            _this.setInfo(_this.DELETE_SUCCESS);
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleActivityRepository.prototype.deleteFromSocket = function (response) {
        var vehicleActivity = VehicleActivity.parseSocket(response);
        this.store.dispatch(VehicleActivityActions.deleteVehicleActivity(vehicleActivity.id));
    };
    VehicleActivityRepository.prototype.toggleSort = function () {
        this.store.dispatch(VehicleActivityActions.toggleSort());
    };
    return VehicleActivityRepository;
}(Repository));
export { VehicleActivityRepository };
