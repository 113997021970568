var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
/**
 * Default state for an empty IForgotPassword object
 */
export var initialState = {
    success: undefined,
    message: undefined,
};
var ForgotPassword = /** @class */ (function () {
    function ForgotPassword(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    ForgotPassword.parse = function (data) {
        return deserialize(ForgotPassword, data);
    };
    __decorate([
        JsonProperty("success"),
        __metadata("design:type", Boolean)
    ], ForgotPassword.prototype, "success", void 0);
    __decorate([
        JsonProperty("message"),
        __metadata("design:type", String)
    ], ForgotPassword.prototype, "message", void 0);
    return ForgotPassword;
}());
export { ForgotPassword };
