import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "./user.state";
import { userAdapter } from "./user.adapter";
import { getVehicleState } from "../vehicle/vehicle.selectors";
import { VehicleState } from "../vehicle/vehicle.state";
import { User } from "@models/user";
import { Vehicle } from "@models/vehicle";

export const getUserState = createFeatureSelector<UserState>("users");
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = userAdapter.getSelectors(getUserState);

export const selectById = (id: number) => createSelector(getUserState, (state: UserState) => state.entities[id]);

export const planneableUsers = () => createSelector(getUserState, getVehicleState, (state: UserState, vehicles: VehicleState) => {
  return (<number[]>state.ids).reduce((result: User[], id: number) => {
    const user = new User(state.entities[id]);
    if (user.isPlannable) {
      if (user.vehicleId && vehicles.entities[user.vehicleId]) {
        user.vehicle = new Vehicle(vehicles.entities[user.vehicleId]);
      }
      result.push(user);
    }
    return result;
  }, []);
});
