import * as moment from "moment";

import { Component, EventEmitter, HostListener, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Subscription, combineLatest } from "rxjs";
import { map, startWith } from "rxjs/operators";

import { AuthRepository } from "@repositories/auth/auth.repository";
import { EmployeeActivityRepository } from "@repositories/employee-activity/employee-activity.repository";
import { AppRepositoryEvents as IAppRepositoryEvents } from "@models/interfaces/app-repository-events.interface";
import { MessageRepository } from "@repositories/message/message.repository";
import { NotificationRepository } from "@repositories/notification/notification.repository";
import { RoleRepository } from "@repositories/role/role.repository";
import { ToastrService } from "ngx-toastr";
import { UserRepository } from "@repositories/user/user.repository";
import { VehicleActivityRepository } from "@repositories/vehicle-activity/vehicle-activity.repository";
import { VehicleRepository } from "@repositories/vehicle/vehicle.repository";
import { WebsocketService } from "@services/websocket/websocket.service";
import { WeekStateService } from "@services/week-state/week-state.service";
import { filter } from "rxjs/operators";
import { StatusRepository } from "./repositories/status/status.repository";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})

export class AppComponent implements OnInit, OnDestroy {
  public isAuthenticated$ = this.authRepository.auth$.pipe(map(x => x.isAuthenticated));
  public pendingActivities$ = combineLatest(
    this.employeeActivityRepository.employeeActivityNotifications$(),
    this.messageRepository.messages$
  ).pipe(
    map((data) => {
      return [
        ...data[0],
        ...data[1]
      ];
    })
  );
  private on: IAppRepositoryEvents = {
    load: new EventEmitter(),
  };
  public loaded$ = this.on.load.pipe(startWith(false));
  public sidebarIsClosed;
  private employeeActivitiesLoaded = false;
  private vehicleActivitiesLoaded = false;
  private rolesLoaded = false;
  private usersLoaded = false;
  private statusesLoaded = false;
  private vehiclesLoaded = false;
  private subscriptions: Subscription[] = [];
  private toastrSubscriptions: Subscription[] = [];

  constructor(
    private authRepository: AuthRepository,
    private notificationRepository: NotificationRepository,
    private toastr: ToastrService,
    private employeeActivityRepository: EmployeeActivityRepository,
    private vehicleActivityRepository: VehicleActivityRepository,
    private userRepository: UserRepository,
    private vehicleRepository: VehicleRepository,
    private roleRepository: RoleRepository,
    private messageRepository: MessageRepository,
    private websocketService: WebsocketService,
    private statusRepository: StatusRepository,
    private router: Router,
    private weekStateService: WeekStateService,
  ) {

  }

  ngOnInit(): void {
    this.websocketService.connect();
    this.setSubscriptions();
    this.onResize();
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
    this.clearSubscriptions();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.sidebarIsClosed = window.innerWidth < 768;
  }

  /**
   * Sets subscriptions
   */
  private setSubscriptions() {
    this.toastrSubscriptions.push(this.notificationRepository.error$.subscribe(x => {
      if (x) {
        this.toastr.error(x, "Oops!");
      }
    }));
    this.toastrSubscriptions.push(this.notificationRepository.info$.subscribe(x => {
      if (x) {
        this.toastr.info(x);
      }
    }));
    this.toastrSubscriptions.push(this.notificationRepository.success$.subscribe(x => {
      if (x) {
        this.toastr.success(x, "Success!");
      }
    }));
    this.toastrSubscriptions.push(this.notificationRepository.warning$.subscribe(x => {
      if (x) {
        this.toastr.warning(x, "Alert!");
      }
    }));

    this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (window.innerWidth < 768) {
          this.sidebarIsClosed = true;
        }
      }));

    // Fetch initial data
    this.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.weekStateService.init();

        this.subscriptions.push(this.employeeActivityRepository.index(
            moment()
              .year(this.weekStateService.getYear())
              .isoWeek(this.weekStateService.getWeek())
              .startOf("isoWeek")
              .format("DD[-]MM[-]YYYY"),
            1
          )
          .subscribe(data => {
            this.employeeActivitiesLoaded = true;
            this.handleRequiredSubscriptions();
          }));
        this.subscriptions.push(this.vehicleActivityRepository.index().subscribe(data => {
          this.vehicleActivitiesLoaded = true;
          this.handleRequiredSubscriptions();
        }));
        this.subscriptions.push(this.userRepository.index().subscribe(data => {
          this.usersLoaded = true;
          this.handleRequiredSubscriptions();
        }));
        this.subscriptions.push(this.statusRepository.index().subscribe(data => {
            this.statusesLoaded = true;
            this.handleRequiredSubscriptions();
        }));
        this.subscriptions.push(this.vehicleRepository.index().subscribe(data => {
          this.vehiclesLoaded = true;
          this.handleRequiredSubscriptions();
        }));
        this.subscriptions.push(this.roleRepository.index().subscribe(data => {
          this.rolesLoaded = true;
          this.handleRequiredSubscriptions();
        }));
        this.subscriptions.push(this.messageRepository.index().subscribe());
      } else {
        this.clearSubscriptions();
      }
    });
  }

  /**
   * Checks all required entities for being loaded and fires the loaded event
   */
  private handleRequiredSubscriptions(): void {
    // At this moment the following entities are required for a fully functional homepage (EmployeeActivityScheduler);
    // EmployeeActivities
    // Vehicles
    // Users
    // Roles
    if (this.employeeActivitiesLoaded && this.usersLoaded && this.vehiclesLoaded && this.rolesLoaded && this.statusesLoaded) {
      this.on.load.next(true);
    }
  }

  /**
   * Clears subscriptions disposing the held resources
   */
  private clearSubscriptions() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
