var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { deserialize, JsonProperty } from "json-typescript-mapper";
export var initialState = {
    id: undefined,
    startDate: undefined,
    endDate: undefined,
    vehicleId: undefined,
    vehicle: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    statusId: undefined,
    color: undefined,
    comment: undefined
};
var VehicleActivity = /** @class */ (function () {
    function VehicleActivity(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    VehicleActivity.parse = function (data) {
        return deserialize(VehicleActivity, data);
    };
    VehicleActivity.parseSocket = function (data) {
        return deserialize(VehicleActivity, data.vehicle_activity);
    };
    VehicleActivity.parseMany = function (data) {
        return data.vehicle_activities.map(function (vehicleActivity) {
            return deserialize(VehicleActivity, vehicleActivity);
        });
    };
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], VehicleActivity.prototype, "id", void 0);
    __decorate([
        JsonProperty("start_date"),
        __metadata("design:type", String)
    ], VehicleActivity.prototype, "startDate", void 0);
    __decorate([
        JsonProperty("end_date"),
        __metadata("design:type", String)
    ], VehicleActivity.prototype, "endDate", void 0);
    __decorate([
        JsonProperty("vehicle_id"),
        __metadata("design:type", Number)
    ], VehicleActivity.prototype, "vehicleId", void 0);
    __decorate([
        JsonProperty("created_at"),
        __metadata("design:type", String)
    ], VehicleActivity.prototype, "createdAt", void 0);
    __decorate([
        JsonProperty("updated_at"),
        __metadata("design:type", String)
    ], VehicleActivity.prototype, "updatedAt", void 0);
    __decorate([
        JsonProperty("status_id"),
        __metadata("design:type", Number)
    ], VehicleActivity.prototype, "statusId", void 0);
    __decorate([
        JsonProperty("comment"),
        __metadata("design:type", String)
    ], VehicleActivity.prototype, "comment", void 0);
    return VehicleActivity;
}());
export { VehicleActivity };
