import * as StatusSelectors from "../../states/status/status.selectors";

import { Injectable, OnInit } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AppState } from "@app/states/app/app.state";
import { HttpErrorResponse } from "@angular/common/http";
import { Status as IStatus } from "@app/models/interfaces/status.interface";
import { NotificationActions } from "@app/states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { Status } from "@app/models/status";
import { StatusActions } from "@app/states/status/status.actions";
import { StatusCreateRequest } from "@app/requests/status-create.request";
import { StatusService } from "@app/services/status/status.service";
import { StatusUpdateRequest } from "@app/requests/status-update.request";
import { Store } from "@ngrx/store";

@Injectable()
export class StatusRepository extends Repository {
  public statuses$ = this.store.select(StatusSelectors.selectAll);
  private readonly CREATE_SUCCESS: string = "Status aangemaakt!";
  private readonly EDIT_SUCCESS: string = "Status aangepast!";

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private statusService: StatusService
  ) {
    super(store, notificationActions);
  }

  public index(): Observable<{} | IStatus[]> {
    return this.statusService.index().pipe(map(response => {
        const statuses = Status.parseMany(response);
        this.store.dispatch(StatusActions.addStatuses(statuses));
        return statuses;
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public create(request: StatusCreateRequest): Observable<{} | IStatus> {
    return this.statusService.create(request).pipe(map(response => {
        const status = Status.parse(response);
        this.store.dispatch(StatusActions.addStatus(status));
        this.setInfo(this.CREATE_SUCCESS);
        return status;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public update(id: number, request: StatusUpdateRequest): Observable<{} | IStatus> {
    return this.statusService.update(id, request).pipe(map(response => {
        const status = Status.parse(response);
        this.store.dispatch(StatusActions.editUser(status));
        this.setInfo(this.EDIT_SUCCESS);
        return status;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public delete(id: number) {
    return this.statusService.delete(id).pipe(map(response => {
        this.store.dispatch(StatusActions.removeStatus(id));
        return response;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }
}
