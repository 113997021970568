var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as EmployeeActivitySelectors from "../../states/employee-activity/employee-activity.selectors";
import * as moment_ from "moment";
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EmployeeActivity } from "@models/employee-activity";
import { EmployeeActivityActions } from "@states/employee-activity/employee-activity.actions";
import { EmployeeActivityService } from "@services/employee-activity/employee-activity.service";
import { NotificationActions } from "@states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { Sortable } from "@enums/sortable.enum";
import { Store } from "@ngrx/store";
var moment = moment_;
var EmployeeActivityRepository = /** @class */ (function (_super) {
    __extends(EmployeeActivityRepository, _super);
    function EmployeeActivityRepository(store, notificationActions, employeeActivityService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.employeeActivityService = employeeActivityService;
        // Observables
        _this.employeeActivities$ = _this.store.select(EmployeeActivitySelectors.selectEntities);
        _this.resources$ = function () {
            return _this.store.select(EmployeeActivitySelectors.getResources());
        };
        _this.events$ = function () {
            return _this.store.select(EmployeeActivitySelectors.getEvents());
        };
        _this.lastUpdate$ = _this.store.select(function (s) { return s.employeeActivities.lastUpdate; });
        // Activities mapped to flat activities, undone of the key-value pair construction
        _this.employeeActivitiesFlat$ = function () {
            return _this.store.select(EmployeeActivitySelectors.selectAllMapped());
        };
        // Inputs & Outputs
        _this.employeeActivityNotifications$ = function () {
            return _this.store.select(EmployeeActivitySelectors.selectNotificationEmployeeActivites());
        };
        // Readonly fields
        _this.CREATE_SUCCESS = "Activiteit aangemaakt!";
        _this.EDIT_SUCCESS = "Activiteit aangepast!";
        _this.DELETE_SUCCESS = "Activiteit verwijderd!";
        _this.ACCEPT_SUCCESS = "Activiteit geaccepteerd!";
        _this.DECLINE_SUCCESS = "Activiteit afgewezen!";
        _this.SET_LIVE = "Activiteiten definitief gemaakt!";
        // Public properties
        // Private fields
        _this.schedulerStartDate = moment().startOf("isoWeek");
        return _this;
    }
    Object.defineProperty(EmployeeActivityRepository.prototype, "schedulerStart", {
        get: function () {
            return this.schedulerStartDate;
        },
        set: function (date) {
            this.schedulerStartDate = moment(date);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeActivityRepository.prototype.index = function (date, weeks) {
        var _this = this;
        return this.employeeActivityService.index(date, weeks).pipe(map(function (response) {
            var employeeActivities = EmployeeActivity.parseMany(response);
            var lastUpdate = response.lastUpdate;
            _this.store.dispatch(EmployeeActivityActions.addEmployeeActivities({
                employeeActivities: employeeActivities,
                lastUpdate: lastUpdate,
            }));
            return employeeActivities;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.list = function (startDate, endDate) {
        var _this = this;
        return this.employeeActivityService.list(startDate, endDate).pipe(map(function (response) {
            var employeeActivities = EmployeeActivity.parseMany(response);
            var lastUpdate = response.lastUpdate;
            _this.store.dispatch(EmployeeActivityActions.addEmployeeActivities({
                employeeActivities: employeeActivities,
                lastUpdate: lastUpdate,
            }));
            return employeeActivities;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.report = function (startDate, endDate) {
        var _this = this;
        return this.employeeActivityService.report(startDate, endDate).pipe(map(function (response) {
            return response.data;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.create = function (request) {
        var _this = this;
        return this.employeeActivityService.create(request).pipe(map(function (response) {
            var employeeActivity = EmployeeActivity.parse(response);
            _this.store.dispatch(EmployeeActivityActions.addEmployeeActivity(employeeActivity));
            _this.setInfo(_this.CREATE_SUCCESS);
            return employeeActivity;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.createFromSocket = function (response) {
        var employeeActivity = EmployeeActivity.parseSocket(response);
        this.store.dispatch(EmployeeActivityActions.addEmployeeActivity(employeeActivity));
    };
    EmployeeActivityRepository.prototype.update = function (id, request) {
        var _this = this;
        return this.employeeActivityService.update(id, request).pipe(map(function (response) {
            var employeeActivity = EmployeeActivity.parse(response);
            _this.store.dispatch(EmployeeActivityActions.editEmployeeActivity(employeeActivity));
            _this.setInfo(_this.EDIT_SUCCESS);
            return employeeActivity;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.updateFromSocket = function (response) {
        var employeeActivity = EmployeeActivity.parseSocket(response);
        this.store.dispatch(EmployeeActivityActions.editEmployeeActivity(employeeActivity));
    };
    EmployeeActivityRepository.prototype.delete = function (id) {
        var _this = this;
        return this.employeeActivityService.delete(id).pipe(map(function (response) {
            _this.store.dispatch(EmployeeActivityActions.deleteEmployeeActivity(id));
            _this.setInfo(_this.DELETE_SUCCESS);
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.deleteFromSocket = function (response) {
        var employeeActivity = EmployeeActivity.parseSocket(response);
        this.store.dispatch(EmployeeActivityActions.deleteEmployeeActivity(employeeActivity.id));
    };
    /**
     * Handles the business logic of accepting an EmployeeActivity
     * @param id
     */
    EmployeeActivityRepository.prototype.accept = function (id) {
        var _this = this;
        return this.employeeActivityService.accept(id).pipe(map(function (response) {
            var employeeActivity = EmployeeActivity.parse(response);
            _this.store.dispatch(EmployeeActivityActions.editEmployeeActivity(employeeActivity));
            _this.setInfo(_this.ACCEPT_SUCCESS);
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    /**
     * Handles the business logic of declining an EmployeeActivity
     * @param id
     */
    EmployeeActivityRepository.prototype.decline = function (id) {
        var _this = this;
        return this.employeeActivityService.decline(id).pipe(map(function (response) {
            _this.store.dispatch(EmployeeActivityActions.deleteEmployeeActivity(id));
            _this.setInfo(_this.DECLINE_SUCCESS);
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.setLive = function (req) {
        var _this = this;
        return this.employeeActivityService.setLive(req).pipe(map(function (response) {
            var employeeActivities = EmployeeActivity.parseMany(response);
            for (var a in employeeActivities) {
                _this.store.dispatch(EmployeeActivityActions.editEmployeeActivity(employeeActivities[a]));
                _this.setInfo(_this.SET_LIVE);
            }
            return employeeActivities;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.exportExcel = function (req) {
        var _this = this;
        return this.employeeActivityService.excelExport(req).pipe(map(function (response) {
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    EmployeeActivityRepository.prototype.toggleSort = function () {
        this.store.dispatch(EmployeeActivityActions.toggleSort(Sortable.user));
    };
    EmployeeActivityRepository.prototype.toggleSortVehicleLabel = function () {
        this.store.dispatch(EmployeeActivityActions.toggleSort(Sortable.vehicle_label));
    };
    return EmployeeActivityRepository;
}(Repository));
export { EmployeeActivityRepository };
