import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Repository } from "../repository.abstract";
import { AppState } from "@states/app/app.state";
import { NotificationActions } from "@states/notification/notification.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { VehicleActivityService } from "@services/vehicle-activity/vehicle-activity.service";
import { VehicleActivityCreateRequest } from "@requests/vehicle-activity-create.request";
import { VehicleActivityActions } from "@states/vehicle-activity/vehicle-activity.actions";
import { VehicleActivitySocketResponse } from "@responses/vehicle-activity-socket.response";
import { VehicleActivityUpdateRequest } from "@requests/vehicle-activity-update.request";
import { VehicleActivity } from "@models/vehicle-activity";
import { VehicleActivities as IVehicleActivities } from "@models/interfaces/vehicle-activities.interface";
import { VehicleActivity as IVehicleActivity } from "@models/interfaces/vehicle-activity.interface";
import * as moment_ from "moment";
import * as VehicleActivitySelectors from "../../states/vehicle-activity/vehicle-activity.selectors";
const moment = moment_;

@Injectable()
export class VehicleActivityRepository extends Repository {
  // Readonly fields
  private readonly CREATE_SUCCESS: string = "Activiteit aangemaakt!";
  private readonly EDIT_SUCCESS: string = "Activiteit aangepast!";
  private readonly DELETE_SUCCESS: string = "Activiteit verwijderd!";
  // Inputs & Outputs
  // Observables
  public vehicleActivities$: Observable<IVehicleActivities> = this.store.select(VehicleActivitySelectors.selectEntities);
  public resources$ = (): Observable<any> => this.store.select(VehicleActivitySelectors.getResources());
  public events$ = (): Observable<any> => this.store.select(VehicleActivitySelectors.getEvents());
  public lastUpdate$: Observable<string> = this.store.select(s => s.vehicleActivities.lastUpdate);
  // Activities mapped to flat activities, undone of the key-value pair construction
  public vehicleActivitiesFlat$ = this.store.select(VehicleActivitySelectors.selectAll);
  // Public properties
  // Private fields
  private schedulerStartDate: moment_.Moment = moment().startOf("isoWeek");

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private vehicleActivityService: VehicleActivityService
  ) {
    super(store, notificationActions);
  }

  public get schedulerStart() {
    return this.schedulerStartDate;
  }

  public set schedulerStart(date: moment_.Moment) {
    this.schedulerStartDate = moment(date);
  }

  public index(): Observable<{} | VehicleActivity[]> {
    return this.vehicleActivityService.index().pipe(map(response => {
      const vehicleActivities = VehicleActivity.parseMany(response);
      const lastUpdate = response.lastUpdate;
      this.store.dispatch(VehicleActivityActions.addVehicleActivities({ vehicleActivities, lastUpdate }));
      return vehicleActivities;
    }), catchError(response => {
      this.setError(JSON.stringify(response.error));
      return observableOf(undefined);
    })
    );
  }

  public create(request: VehicleActivityCreateRequest): Observable<{} | IVehicleActivity> {
    return this.vehicleActivityService.create(request).pipe(map(response => {
        const vehicleActivity = VehicleActivity.parse(response);
        this.store.dispatch(VehicleActivityActions.addVehicleActivity(vehicleActivity));
        this.setInfo(this.CREATE_SUCCESS);
        return vehicleActivity;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public createFromSocket(response: VehicleActivitySocketResponse): void {
    const vehicleActivity = VehicleActivity.parseSocket(response);
    this.store.dispatch(VehicleActivityActions.addVehicleActivity(vehicleActivity));
  }

  public update(id: number, request: VehicleActivityUpdateRequest): Observable<{} | IVehicleActivity> {
    return this.vehicleActivityService.update(id, request).pipe(map(response => {
        const vehicleActivity = VehicleActivity.parse(response);
        this.store.dispatch(VehicleActivityActions.editVehicleActivity(vehicleActivity));
        this.setInfo(this.EDIT_SUCCESS);
        return vehicleActivity;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public updateFromSocket(response: VehicleActivitySocketResponse): void {
    const vehicleActivity = VehicleActivity.parseSocket(response);
    this.store.dispatch(VehicleActivityActions.editVehicleActivity(vehicleActivity));
  }

  public delete(id: number) {
    return this.vehicleActivityService.delete(id).pipe(map(response => {
        this.store.dispatch(VehicleActivityActions.deleteVehicleActivity(id));
        this.setInfo(this.DELETE_SUCCESS);
        return response;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public deleteFromSocket(response: VehicleActivitySocketResponse): void {
    const vehicleActivity = VehicleActivity.parseSocket(response);
    this.store.dispatch(VehicleActivityActions.deleteVehicleActivity(vehicleActivity.id));
  }

  public toggleSort() {
    this.store.dispatch(VehicleActivityActions.toggleSort());
  }
}
