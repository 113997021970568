import { DashboardState } from "./dashboard.state";
import { DashboardActions } from "./dashboard.actions";

export const initialState: DashboardState = {
  totalRides: {
    totalActivities: undefined,
    totalActivitiesDay: undefined,
    totalActivitiesNight: undefined,
    totalActivitiesBoth: undefined,
  },
  employees: [
    {
      name: "",
      totalActivities: undefined,
      totalActivitiesDay: undefined,
      totalActivitiesNight: undefined,
      totalActivitiesBoth: undefined,
    }
  ]
};

export function DashboardReducer(state: DashboardState = initialState, action): DashboardState {
  switch (action.type) {
    case DashboardActions.ADD_DASHBOARD:
      return {
        ...state,
        totalRides: action.payload.totalRides,
        employees: action.payload.employees
      };
    default:
      return state;
  }
}
