var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { MessageActions } from "../../states/message/message.actions";
import { MessageService } from "../../services/message/message.service";
import { Message } from "../../models/message";
import * as MessageSelectors from "../../states/message/message.selectors";
import { AuthRepository } from "../auth/auth.repository";
import { MessageUpdateRequest } from "../../requests/message-update.request";
var MessageRepository = /** @class */ (function (_super) {
    __extends(MessageRepository, _super);
    function MessageRepository(store, notificationActions, messageService, authRepository) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.messageService = messageService;
        _this.authRepository = authRepository;
        _this.messages$ = _this.store.select(MessageSelectors.selectAll);
        return _this;
    }
    MessageRepository.prototype.index = function () {
        var _this = this;
        return this.messageService.index().pipe(map(function (response) {
            var messages = Message.parseMany(response);
            _this.store.dispatch(MessageActions.addMessages(messages));
            return messages;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    MessageRepository.prototype.createFromSocket = function (response) {
        var _this = this;
        this.authRepository.auth$.subscribe(function (auth) {
            var message = Message.parseSocket(response);
            if (message && auth.user.id === message.userId) {
                _this.store.dispatch(MessageActions.addMessage(message));
            }
        });
    };
    MessageRepository.prototype.confirm = function (id) {
        var _this = this;
        var request = new MessageUpdateRequest({
            id: id,
            confirmed: true
        });
        return this.messageService.update(request).pipe(map(function (response) {
            var message = Message.parse(response);
            _this.store.dispatch(MessageActions.deleteMessage(message.id));
            return message;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    return MessageRepository;
}(Repository));
export { MessageRepository };
