export class Variables {
    // Rules
    public static readonly PLANNABLE_RULE: string = "scheduler.plannable";
    // Other
    public static readonly DATE_FORMAT: string = "YYYY-MM-DD";
    public static readonly ACTIVITY_DATE_FORMAT_SHORT_TEXT = "DD MMM";
    public static readonly ACTIVITY_DATE_FORMAT = "DD-MM-YYYY";
    public static readonly ACTIVITY_WEEK_FORMAT = "WW";
    static readonly ACTIVITY_MONTH_FORMAT = "MMMM yyyy";
}
