import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppState } from "./app/app.state";
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { AuthReducer } from "./auth/auth.reducer";
import { AuthActions } from "./auth/auth.actions";
import { NotificationReducer } from "./notification/notification.reducer";
import { NotificationActions } from "./notification/notification.actions";
import { localStorageSync } from "ngrx-store-localstorage";
import { UserReducer } from "./user/user.reducer";
import { UserActions } from "./user/user.actions";
import { EmployeeActivityActions } from "./employee-activity/employee-activity.actions";
import { EmployeeActivityReducer } from "./employee-activity/employee-activity.reducer";
import { VehicleReducer } from "./vehicle/vehicle.reducer";
import { VehicleActions } from "./vehicle/vehicle.actions";
import { RoleActions } from "./role/role.actions";
import { RoleReducer } from "./role/role.reducer";
import { VehicleActivityReducer } from "./vehicle-activity/vehicle-activity.reducer";
import { VehicleActivityActions } from "./vehicle-activity/vehicle-activity.actions";
import { MessageReducer } from "./message/message.reducer";
import { MessageActions } from "./message/message.actions";
import { SchemeReducer } from "./scheme/scheme.reducer";
import { SchemeActions } from "./scheme/scheme.actions";
import { DashboardActions } from "./dashboard/dashboard.actions";
import { DashboardReducer } from "./dashboard/dashboard.reducer";
import { StatusActions } from "./status/status.actions";
import { StatusReducer } from "./status/status.reducer";

export const reducers: ActionReducerMap<AppState> = {
  employeeActivities: EmployeeActivityReducer,
  auth: AuthReducer,
  notification: NotificationReducer,
  users: UserReducer,
  vehicles: VehicleReducer,
  roles: RoleReducer,
  vehicleActivities: VehicleActivityReducer,
  messages: MessageReducer,
  schemes: SchemeReducer,
  dashboard: DashboardReducer,
  statuses: StatusReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ["auth"], rehydrate: true })(reducer);
}

const metaReducers: MetaReducer<any, any>[] = [localStorageSyncReducer];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers }),
    CommonModule
  ],
  declarations: [],
  providers: [
    EmployeeActivityActions,
    VehicleActivityActions,
    AuthActions,
    NotificationActions,
    UserActions,
    VehicleActions,
    RoleActions,
    MessageActions,
    SchemeActions,
    DashboardActions,
    StatusActions,
  ]
})
export class StatesModule { }
