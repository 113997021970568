import { RoleState } from "./role.state";
import { RoleActions } from "./role.actions";

export const initialState = {
  roles: []
};

export function RoleReducer(state: RoleState = initialState, action) {
  switch (action.type) {
    case RoleActions.ADD_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    default:
      return state;
  }
}
