import { Component, OnInit } from "@angular/core";
import { defineLocale } from "ngx-bootstrap/chronos";
import { FileService } from "@services/file/file.service";
import { BsDatepickerConfig } from "ngx-bootstrap";
import { BsModalRef } from "ngx-bootstrap/modal";
import { VehicleService } from "@services/vehicle/vehicle.service";
import { nlLocale } from "ngx-bootstrap/locale";

defineLocale("nl", nlLocale);

@Component({
  selector: "app-file-upload-modal",
  templateUrl: "./file-upload-modal.component.html",
  styleUrls: ["./file-upload-modal.component.scss"]
})
export class FileUploadModalComponent implements OnInit {
  public selectedFile: File = undefined;
  public date: string;
  public vehicle: string;
  public shift: string;
  public fd: FormData;
  public vehicles: object[];
  public dpConfig: Partial<BsDatepickerConfig>;
  public validFile: boolean;
  constructor(
    private fileService: FileService,
    private bsModalRef: BsModalRef,
    private vehicleService: VehicleService,
  ) {
    this.dpConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: "DD/MM/YYYY",
      defaultViewDate: "today",
      locale: "nl",
      firstDayOfWeek: 1,
    });
  }
  ngOnInit() {
    this.getVehicles();
  }

    public getVehicles(): void {
    this.vehicleService.index()
      .subscribe(res => {
        this.vehicles = res.vehicles;
      });
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public onDateSelect(event): void {
    this.date =  event.getFullYear() + "-" + (event.getMonth() + 1) + "-" + event.getDate();
    this.completeFormData();
  }

  public onVehicleSelect(event): void {
    this.vehicle = event.target.value;
    this.completeFormData();
  }

  public onShiftSelected(event): void {
    this.shift = event.target.value;
    this.completeFormData();
  }

  public onFileSelected(event): void {
    this.selectedFile = <File>event.target.files[0];
      this.validFile = this.validateFileType(event.target.files[0]);
      this.completeFormData();
  }

  public validateFileType(file) {
    const ext = file.name.split(".").pop();
    return ext === "xlsx" || ext === "pdf" || ext === "docx";
  }

  private completeFormData(): void {
    if (!this.formValidation()) {
      return;
    }
    if (!this.validateFileType(this.selectedFile)) {
      return;
    }

    this.fd = new FormData();
    if (!this.date) {
      this.date = undefined;
    } else {
      this.fd.append("date", this.date);
    }
    this.fd.append("vehicle", this.vehicle);
    this.fd.append("shift", this.shift);
    this.fd.append("document", this.selectedFile, this.selectedFile.name);
  }

  public formValidation(): any {
    return this.vehicle || this.shift || this.selectedFile;
  }

  onUpload(): void {
    this.fileService.upload(this.fd)
      .subscribe(res => {
        this.close();
      });
  }
}
