import { Vehicle as IVehicle } from "./interfaces/vehicle.interface";
import { JsonProperty, deserialize } from "json-typescript-mapper";

import { File as FileInterface } from "./interfaces/file.interface";
import { VehicleResponse } from "@responses/vehicle.response";
import { VehicleSocketResponse } from "@responses/vehicle-socket.response";
import { VehiclesResponse } from "@responses/vehicles.response";
import { User } from "@interfaces/user.interface";

export const initialState: IVehicle = {
  id: undefined,
  vehicleNumber: undefined,
  description: undefined,
  label: undefined,
  deleted: undefined,
  drivers: undefined,
  files: undefined,
};

export class Vehicle implements IVehicle {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("vehicle_number")
  public vehicleNumber: string;
  @JsonProperty("description")
  public description: string;
  @JsonProperty("label")
  public label: string;
  @JsonProperty("deleted")
  public deleted: boolean;
  public drivers: User[];
  public files: FileInterface[];

  constructor(init?: Partial<IVehicle>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parse(data: VehicleResponse): Vehicle {
    return deserialize(Vehicle, data);
  }

  public static parseSocket(data: VehicleSocketResponse): Vehicle {
    return deserialize(Vehicle, data.vehicle);
  }

  public static parseMany(data: VehiclesResponse): Vehicle[] {
    return data.vehicles.map((vehicle) => deserialize(Vehicle, vehicle));
  }
}
