import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-multiple-choice-dialog",
  templateUrl: "./multiple-choice-dialog.component.html",
  styleUrls: ["./multiple-choice-dialog.component.scss"]
})
export class MultipleChoiceDialogComponent {

  @Input() title = "Weet u het zeker?";
  @Input() firstChoiceButtonText = "Bevestigen";
  @Input() firstChoiceButtonClass = "btn-primary";
  @Input() secondChoiceButtonText = "Bevestigen";
  @Input() secondChoiceButtonClass = "btn-primary";
  @Input() cancelButtonText = "Annuleren";
  @Input() cancelButtonClass = "btn-secondary";
  @Input() hideCancelButton = false;
  @Input() message: string = undefined;

  public firstChoice = false;
  public secondChoice = false;

  constructor(
    public bsModalRef: BsModalRef
  ) {
  }

  firstChoiceConfirm(): void {
    this.firstChoice = true;
    this.bsModalRef.hide();
  }

  secondChoiceConfirm(): void {
    this.secondChoice = true;
    this.bsModalRef.hide();
  }

  close(): void {
    this.firstChoice = false;
    this.secondChoice = false;
    this.bsModalRef.hide();
  }
}
