import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { WeekStateService } from "src/app/services/week-state/week-state.service";
import { map } from "rxjs/operators";
import { EmployeeActivity } from "@models/employee-activity";
import { Message } from "@models/message";
import { AuthRepository } from "@repositories/auth/auth.repository";
import { EmployeeActivityRepository } from "@repositories/employee-activity/employee-activity.repository";
import { MessageRepository } from "@repositories/message/message.repository";

@Component({
  selector: "app-top-bar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"]
})
export class TopBarComponent implements OnChanges {
  // Readonly fields
  // Inputs & Outputs
  @Input("activities") pendingActivities: EmployeeActivity[] | Message[] = [];
  @Input("sidebarToggle") sidebarToggle: boolean;
  @Output("sidebarToggleChange") sidebarToggleChange: EventEmitter<boolean> = new EventEmitter();
  // Observables
  public user$ = this.authRepository.auth$.pipe(map(auth => auth.user));
  // Public properties
  public notificationsCount = 0;
  public showNotifications = false;

  // Private fields

  constructor(
    // TODO: Components should not be allowed repository access. It should be in module components, in this case app.component
    private authRepository: AuthRepository,
    private employeeActivityRepository: EmployeeActivityRepository,
    private messageRepository: MessageRepository,
    private router: Router,
    private weekStateService: WeekStateService,
  ) {
  }

  /**
   * Event handler for changes to input properties
   * @param simpleChanges
   */
  ngOnChanges(simpleChanges: SimpleChanges) {
    for (const property in simpleChanges) {
      if (property.toUpperCase() === "PENDINGACTIVITIES") {
        this.calculatePendingActivities();
      }
    }
  }

  public toggleNotifications(): void {
    this.showNotifications = !this.showNotifications;
  }

  public logout(): void {
    this.authRepository.logout().subscribe(() => {
      // AWA: Instead of navigate because somehow the redux store is broken after logout+navigate
      window.location.replace("");
    });
    this.weekStateService.reset();
  }

  public onAcceptActivity(activity: EmployeeActivity): void {
    this.employeeActivityRepository.accept(activity.id).subscribe();
  }

  public onDeclineActivity(activity: EmployeeActivity): void {
    this.employeeActivityRepository.decline(activity.id).subscribe();
  }

  public onMarkCompleted(message: Message): void {
    this.messageRepository.confirm(message.id).subscribe();
  }

  public sidebarClick() {
    this.sidebarToggleChange.emit(!this.sidebarToggle);
  }

  /**
   *
   */
  private calculatePendingActivities(): void {
    this.notificationsCount = this.pendingActivities.length;
  }
}
