var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Sorting } from "../../models/enums/sorting.enum";
import { VehicleActivityActions } from "./vehicle-activity.actions";
import { vehicleActivityAdapter } from "./vehicle-activity.adapter";
var initialState = vehicleActivityAdapter.getInitialState({
    sort: Sorting.asc,
    lastUpdate: undefined
});
export function VehicleActivityReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case VehicleActivityActions.ADD_VEHICLE_ACTIVITIES:
            return __assign({}, vehicleActivityAdapter.addMany(action.payload.vehicleActivities, vehicleActivityAdapter.removeAll(state)), { lastUpdate: action.payload.lastUpdate });
        case VehicleActivityActions.ADD_VEHICLE_ACTIVITY:
            return vehicleActivityAdapter.addOne(action.payload, state);
        case VehicleActivityActions.EDIT_VEHICLE_ACTIVITY:
            return vehicleActivityAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload,
            }, state);
        case VehicleActivityActions.DELETE_VEHICLE_ACTIVITY:
            return vehicleActivityAdapter.removeOne(action.payload, state);
        case VehicleActivityActions.TOGGLE_VEHICLE_SORT:
            return __assign({}, state, { sort: state.sort === Sorting.asc ? Sorting.desc : Sorting.asc });
        default:
            return state;
    }
}
