var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { RoleActions } from "../../states/role/role.actions";
import { RoleService } from "../../services/role/role.service";
import { Role } from "../../models/role";
var RoleRepository = /** @class */ (function (_super) {
    __extends(RoleRepository, _super);
    function RoleRepository(store, notificationActions, roleService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.roleService = roleService;
        _this.roles$ = _this.store.select(function (s) { return s.roles.roles; });
        return _this;
    }
    RoleRepository.prototype.index = function () {
        var _this = this;
        return this.roleService.index().pipe(map(function (response) {
            var roles = Role.parseMany(response);
            _this.store.dispatch(RoleActions.addRoles(roles));
            return roles;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    return RoleRepository;
}(Repository));
export { RoleRepository };
