import { MessageState } from "./message.state";
import { MessageActions } from "./message.actions";
import { messageAdapter } from "./message.adapter";

const initialState: MessageState = messageAdapter.getInitialState();

export function MessageReducer(state: MessageState = initialState, action): MessageState {
  switch (action.type) {
    case MessageActions.ADD_MESSAGES:
      return messageAdapter.addMany(
        action.payload,
        messageAdapter.removeAll(state)
      );
    case MessageActions.ADD_MESSAGE:
      return messageAdapter.addOne(action.payload, state);
    case MessageActions.DELETE_MESSAGE:
      return messageAdapter.removeOne(action.payload, state);
    default:
      return state;
  }
}
