var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RoleActions } from "./role.actions";
export var initialState = {
    roles: []
};
export function RoleReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RoleActions.ADD_ROLES:
            return __assign({}, state, { roles: action.payload });
        default:
            return state;
    }
}
