<div class="modal-header">
  <h4 class="modal-title">Bestand uploaden</h4>
  <h3>Vul de onderstaande gegevens in</h3>
</div>

<div class="modal-body ">
  <div class="row my-2">
    <div class="col">
      <input (bsValueChange)="onDateSelect($event)" type="text" placeholder="Selecteer datum" class="form-control"
             bsDatepicker [bsConfig]="dpConfig">
    </div>
  </div>

  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <label for="vehiclePicker">Kies een voertuig</label>
        <select (change)="onVehicleSelect($event)" id="vehiclePicker" class="form-control input-lg">
          <option value="" disabled>Selecteer een voertuig</option>
          <option *ngFor="let vehicle of vehicles" [value]="vehicle.id">{{ vehicle.description }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <label for="shiftPicker">Kies een dienst</label>
        <select (change)="onShiftSelected($event)" id="shiftPicker" class="form-control input-lg">
          <option disabled>Selecteer een dienst</option>
          <option value="1">Dag</option>
          <option value="2">Nacht</option>
          <option value="0">Beide</option>

        </select>
      </div>
    </div>
  </div>

  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <label for="filePicker">Kies een bestand</label>
        <input id="filePicker" type="file" (change)="onFileSelected($event)">
      </div>
    </div>
  </div>
  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <ng-container *ngIf="selectedFile">
          <div class="alert-info rounded p-2 text-sm-left" role="alert" *ngIf="!validFile">Alleen Word, Excel of PDF bestanden zijn mogelijk.</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary float-left" (click)="onUpload()" [disabled]="!formValidation()">Uploaden</button>
  <a class="close-modal float-left" (click)="close()">Sluiten</a>
</div>



