import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders } from "@helpers/http";
import { RolesResponse } from "@responses/roles.response";
import { environment } from "../../../environments/environment";

@Injectable()
export class RoleService {

    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Execute a RoleIndexRequest
     */
    public index(): Observable<RolesResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<RolesResponse>(`${environment.api}/roles`, {headers});
    }
}
