<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body" *ngIf="message">
  <div class="row">
    <div class="col-12" [innerHTML]="message">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn {{ confirmButtonClass }} float-right" (click)="confirm()">{{ confirmButtonText }}</button>
  <button type="button" class="btn {{ cancelButtonClass }} float-left" (click)="close()" *ngIf="!hideCancelButton">{{ cancelButtonText }}</button>
</div>
