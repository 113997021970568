/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./notification-item.component";
var styles_NotificationItemComponent = [i0.styles];
var RenderType_NotificationItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationItemComponent, data: {} });
export { RenderType_NotificationItemComponent as RenderType_NotificationItemComponent };
export function View_NotificationItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "notification-item-wrapper border-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row m-0 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col text-left text-truncate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ", ", " test"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "m-0 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "m-0 font-weight-bold date"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " - ", " (", ")"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-auto text-right buttons p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "ion-md-checkmark text-primary border-right px-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "ion-md-close text-danger px-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.activity.user == null) ? null : _co.activity.user.lastName); var currVal_1 = ((_co.activity.user == null) ? null : _co.activity.user.firstName); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (_co.activity.activityTypeDetailsDisplay ? _co.activity.activityTypeDetailsDisplay : _co.activity.activityTypeDisplay); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.moment(_co.activity.startDate).format(_co.dateFormat); var currVal_4 = _co.moment(_co.activity.endDate).format(_co.dateFormat); var currVal_5 = _co.activity.activityDaypartDisplayShort; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_NotificationItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-item", [], null, null, null, View_NotificationItemComponent_0, RenderType_NotificationItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotificationItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationItemComponentNgFactory = i1.ɵccf("app-notification-item", i2.NotificationItemComponent, View_NotificationItemComponent_Host_0, { activity: "activity" }, { accepted: "accept", declined: "decline" }, []);
export { NotificationItemComponentNgFactory as NotificationItemComponentNgFactory };
