import { Daypart } from "../enums/daypart.enum";

export class Resource<T> {
  private resourceId: number;
  private resourceDaypart: Daypart;

  constructor(data?: T | string, idKey = "id") {
    switch (typeof data) {
      case "string": // Data is supplied as complete resource string
        const dataString = <string>data;
        this.resource = dataString;
        break;
      case "object": // Data is supplied as an model
        const dataObject = data;
        this.resourceId = dataObject[idKey];
        this.resourceDaypart = dataObject["activityDaypart"];
        break;
      default:
        break;
    }
  }

  public get resource(): string {
    return this.resourceId + "." + this.resourceDaypart;
  }
  public set resource(resource: string) {
    const resources = resource.split(".");
    this.resourceId = +resources[0];
    this.resourceDaypart = +resources[1];
  }

  public get id(): number {
    return this.resourceId;
  }
  public set id(id: number) {
    this.resourceId = id;
  }

  public get daypart(): Daypart {
    return this.resourceDaypart;
  }
  public set daypart(daypart: Daypart) {
    this.resourceDaypart = daypart;
  }
}
