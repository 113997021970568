import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as _moment from "moment";
import { Message } from "@interfaces/message.interface";
import { Variables } from "@app/shared.variables";

@Component({
  selector: "app-message-item",
  templateUrl: "./message-item.component.html",
  styleUrls: ["./message-item.component.scss"]
})
export class MessageItemComponent implements OnInit {
  // Readonly fields
  // Inputs & Outputs
  @Input() message: Message;
  @Output("completed") completed = new EventEmitter<Message>();
  // Observables
  // Public properties
  public moment = _moment;
  public dateFormat = Variables.ACTIVITY_DATE_FORMAT;
  // Private fields

  constructor() { }

  ngOnInit() {
  }

  public markCompleted() {
    this.completed.emit(this.message);
  }

}
