<div class="picker-container {{ config.cssClass }}">
    <div class="month" *ngFor="let currentMonth of months; index as i">
        <div class="header">
            <div class="header-col button" (click)="previousMonth()">
                <ng-container *ngIf="i === 0"><</ng-container>
            </div>
            <div class="header-col">{{ currentMonth.format("MMMM") }}</div>
            <div class="header-col">{{ currentMonth.format("YYYY") }}</div>
            <div class="header-col button" (click)="nextMonth(endMonth)">
                <ng-container *ngIf="i === months.length - 1">></ng-container>
            </div>
        </div>
        <div class="content">
            <div class="calender-container week-days">
                <div class="calender-col"></div>
                <div class="calender-col"><span>Ma</span></div>
                <div class="calender-col"><span>Di</span></div>
                <div class="calender-col"><span>Wo</span></div>
                <div class="calender-col"><span>Do</span></div>
                <div class="calender-col"><span>Vr</span></div>
                <div class="calender-col"><span>Za</span></div>
                <div class="calender-col"><span>Zo</span></div>
            </div>
            <ng-container *ngFor="let month of getMonth(currentMonth)">
                <div
                    class="calender-container"
                    *ngFor="let week of getObjectKeys(month)"
                >
                    <div class="calender-col week-number">
                        <span>{{ week }}</span>
                    </div>
                    <div
                        *ngFor="let day of month[week]"
                        class="calender-col week-day"
                        [ngClass]="dayClass(day.date, currentMonth)"
                    >
                        <span (click)="clickDay(day.date)">{{ day.day }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
