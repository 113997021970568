<div class="sidebar-sticky">
  <a class="navbar-brand">
    <img src="assets/img/logo.png" />
  </a>
  <nav class="nav flex-column">
    <span class="nav-header" *ngIf="navScheduler.children.length > 0">Planning</span>
    <div #navScheduler>
      <a class="nav-link" routerLink="/scheduler/employee-listview" routerLinkActive="active" can="activity.index.current_user">
        <i class="ion-ios-stats rotate-90deg"></i>
        Mijn planning
      </a>
      <a class="nav-link" routerLink="/scheduler/employees" routerLinkActive="active" can="activity.index"><i class="ion-ios-stats rotate-90deg"></i>Werknemers</a>
      <a class="nav-link" routerLink="/scheduler/vehicles" routerLinkActive="active" can="activity.index"><i class="ion-ios-car"></i>Voertuigen</a>
    </div>

    <span class="nav-header" *ngIf="navFiles.children.length > 0">Bestanden</span>
    <div #navFiles>
      <a class="nav-link" routerLink="/tripfiles" routerLinkActive="active" can="file.index"><i class="ion-ios-paper"></i>Ritplanning</a>
      <a class="nav-link" routerLink="/generalfiles" routerLinkActive="active" can="file.index"><i class="ion-ios-folder"></i>Algemeen</a>
    </div>

    <span class="nav-header" *ngIf="navOverview.children.length > 0">Overzicht</span>
    <div #navOverview>
      <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" can="dashboard.index"><i class="ion-ios-pie"></i>Dashboard</a>
      <a class="nav-link" routerLink="/reports/hours" routerLinkActive="active" can="reports.index"><i class="ion-md-apps"></i>Uren rapportage</a>
    </div>

    <div can="menu.management">
      <span class="nav-header" *ngIf="navSettings.children.length > 0">Beheer</span>
      <div #navSettings >
        <a class="nav-link" routerLink="/statuses" routerLinkActive="active"><i class="ion-ios-stats"></i>Statussen</a>
        <a class="nav-link" routerLink="/vehicles" routerLinkActive="active" can="vehicle.index"><i class="ion-ios-car"></i>Voertuigen</a>
        <a class="nav-link" routerLink="/users" routerLinkActive="active" can="user.index"><i class="ion-ios-person"></i>Gebruikers</a>
        <a class="nav-link" routerLink="/workschemes" routerLinkActive="active" can="workscheme.index"><i class="ion-md-apps"></i>Werkschema's</a>
      </div>
    </div>
  </nav>
  <div class="version">
    Huidige versie: {{ version }}
  </div>
</div>
