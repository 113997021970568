/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notifications/notifications.component.ngfactory";
import * as i3 from "../notifications/notifications.component";
import * as i4 from "@angular/common";
import * as i5 from "./topbar.component";
import * as i6 from "../../repositories/auth/auth.repository";
import * as i7 from "../../repositories/employee-activity/employee-activity.repository";
import * as i8 from "../../repositories/message/message.repository";
import * as i9 from "@angular/router";
import * as i10 from "../../services/week-state/week-state.service";
var styles_TopBarComponent = [i0.styles];
var RenderType_TopBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopBarComponent, data: {} });
export { RenderType_TopBarComponent as RenderType_TopBarComponent };
function View_TopBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "ion-ios-arrow-back"]], null, null, null, null, null))], null, null); }
function View_TopBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "ion-ios-arrow-forward"]], null, null, null, null, null))], null, null); }
function View_TopBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-pill badge-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_TopBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [["class", "notification-dropdown"]], null, [[null, "acceptActivity"], [null, "declineActivity"], [null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptActivity" === en)) {
        var pd_0 = (_co.onAcceptActivity($event) !== false);
        ad = (pd_0 && ad);
    } if (("declineActivity" === en)) {
        var pd_1 = (_co.onDeclineActivity($event) !== false);
        ad = (pd_1 && ad);
    } if (("completed" === en)) {
        var pd_2 = (_co.onMarkCompleted($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NotificationsComponent_0, i2.RenderType_NotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationsComponent, [], { pendingActivities: [0, "pendingActivities"] }, { accepted: "acceptActivity", declined: "declineActivity", completed: "completed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingActivities; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TopBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-auto icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNotifications() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "ion-md-notifications"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_5)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notificationsCount > 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.showNotifications; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_TopBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "topbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "btn btn-link text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sidebarClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TopBarComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "col-auto profile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "d-none d-sm-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " | "])), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "d-none d-sm-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log uit "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "ion-md-log-out"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.sidebarToggle; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.sidebarToggle; _ck(_v, 7, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = ((((tmp_2_0 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.user$))) == null) ? null : tmp_2_0.role.name) !== "Werknemer"); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.user$)).fullName; _ck(_v, 13, 0, currVal_3); }); }
export function View_TopBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-bar", [], null, null, null, View_TopBarComponent_0, RenderType_TopBarComponent)), i1.ɵdid(1, 573440, null, 0, i5.TopBarComponent, [i6.AuthRepository, i7.EmployeeActivityRepository, i8.MessageRepository, i9.Router, i10.WeekStateService], null, null)], null, null); }
var TopBarComponentNgFactory = i1.ɵccf("app-top-bar", i5.TopBarComponent, View_TopBarComponent_Host_0, { pendingActivities: "activities", sidebarToggle: "sidebarToggle" }, { sidebarToggleChange: "sidebarToggleChange" }, []);
export { TopBarComponentNgFactory as TopBarComponentNgFactory };
