import { createFeatureSelector, createSelector } from "@ngrx/store";
import { VehicleState } from "./vehicle.state";
import { vehicleAdapter } from "./vehicle.adapter";
import { AppState } from "../app/app.state";
import { Vehicle } from "@models/vehicle";

export const getVehicleState = createFeatureSelector<VehicleState>("vehicles");
export const getAppState = (state: AppState) => state;  // TODO: move to own file

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = vehicleAdapter.getSelectors(getVehicleState);

export const selectById = (id: number) => createSelector(getVehicleState, (state: VehicleState) => state.entities[id]);
export const selectAllWithoutDeleted = () => createSelector(
  getVehicleState,
  (state: VehicleState) => {
    return (<number[]>state.ids).reduce((result: Vehicle[], id: number) => {
      const vehicle = new Vehicle(state.entities[id]);
      if (!vehicle.deleted) {
        result.push(vehicle);
      }
      return result;
    }, []);
  }
);


