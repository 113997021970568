var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import * as moment from "moment";
import { EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AuthRepository } from "@repositories/auth/auth.repository";
import { EmployeeActivityRepository } from "@repositories/employee-activity/employee-activity.repository";
import { MessageRepository } from "@repositories/message/message.repository";
import { NotificationRepository } from "@repositories/notification/notification.repository";
import { RoleRepository } from "@repositories/role/role.repository";
import { ToastrService } from "ngx-toastr";
import { UserRepository } from "@repositories/user/user.repository";
import { VehicleActivityRepository } from "@repositories/vehicle-activity/vehicle-activity.repository";
import { VehicleRepository } from "@repositories/vehicle/vehicle.repository";
import { WebsocketService } from "@services/websocket/websocket.service";
import { WeekStateService } from "@services/week-state/week-state.service";
import { filter } from "rxjs/operators";
import { StatusRepository } from "./repositories/status/status.repository";
var AppComponent = /** @class */ (function () {
    function AppComponent(authRepository, notificationRepository, toastr, employeeActivityRepository, vehicleActivityRepository, userRepository, vehicleRepository, roleRepository, messageRepository, websocketService, statusRepository, router, weekStateService) {
        this.authRepository = authRepository;
        this.notificationRepository = notificationRepository;
        this.toastr = toastr;
        this.employeeActivityRepository = employeeActivityRepository;
        this.vehicleActivityRepository = vehicleActivityRepository;
        this.userRepository = userRepository;
        this.vehicleRepository = vehicleRepository;
        this.roleRepository = roleRepository;
        this.messageRepository = messageRepository;
        this.websocketService = websocketService;
        this.statusRepository = statusRepository;
        this.router = router;
        this.weekStateService = weekStateService;
        this.isAuthenticated$ = this.authRepository.auth$.pipe(map(function (x) { return x.isAuthenticated; }));
        this.pendingActivities$ = combineLatest(this.employeeActivityRepository.employeeActivityNotifications$(), this.messageRepository.messages$).pipe(map(function (data) {
            return __spread(data[0], data[1]);
        }));
        this.on = {
            load: new EventEmitter(),
        };
        this.loaded$ = this.on.load.pipe(startWith(false));
        this.employeeActivitiesLoaded = false;
        this.vehicleActivitiesLoaded = false;
        this.rolesLoaded = false;
        this.usersLoaded = false;
        this.statusesLoaded = false;
        this.vehiclesLoaded = false;
        this.subscriptions = [];
        this.toastrSubscriptions = [];
    }
    AppComponent.prototype.ngOnInit = function () {
        this.websocketService.connect();
        this.setSubscriptions();
        this.onResize();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.websocketService.disconnect();
        this.clearSubscriptions();
    };
    AppComponent.prototype.onResize = function (event) {
        this.sidebarIsClosed = window.innerWidth < 768;
    };
    /**
     * Sets subscriptions
     */
    AppComponent.prototype.setSubscriptions = function () {
        var _this = this;
        this.toastrSubscriptions.push(this.notificationRepository.error$.subscribe(function (x) {
            if (x) {
                _this.toastr.error(x, "Oops!");
            }
        }));
        this.toastrSubscriptions.push(this.notificationRepository.info$.subscribe(function (x) {
            if (x) {
                _this.toastr.info(x);
            }
        }));
        this.toastrSubscriptions.push(this.notificationRepository.success$.subscribe(function (x) {
            if (x) {
                _this.toastr.success(x, "Success!");
            }
        }));
        this.toastrSubscriptions.push(this.notificationRepository.warning$.subscribe(function (x) {
            if (x) {
                _this.toastr.warning(x, "Alert!");
            }
        }));
        this.subscriptions.push(this.router.events.pipe(filter(function (event) { return event instanceof NavigationStart; }))
            .subscribe(function (event) {
            if (window.innerWidth < 768) {
                _this.sidebarIsClosed = true;
            }
        }));
        // Fetch initial data
        this.isAuthenticated$.subscribe(function (authenticated) {
            if (authenticated) {
                _this.weekStateService.init();
                _this.subscriptions.push(_this.employeeActivityRepository.index(moment()
                    .year(_this.weekStateService.getYear())
                    .isoWeek(_this.weekStateService.getWeek())
                    .startOf("isoWeek")
                    .format("DD[-]MM[-]YYYY"), 1)
                    .subscribe(function (data) {
                    _this.employeeActivitiesLoaded = true;
                    _this.handleRequiredSubscriptions();
                }));
                _this.subscriptions.push(_this.vehicleActivityRepository.index().subscribe(function (data) {
                    _this.vehicleActivitiesLoaded = true;
                    _this.handleRequiredSubscriptions();
                }));
                _this.subscriptions.push(_this.userRepository.index().subscribe(function (data) {
                    _this.usersLoaded = true;
                    _this.handleRequiredSubscriptions();
                }));
                _this.subscriptions.push(_this.statusRepository.index().subscribe(function (data) {
                    _this.statusesLoaded = true;
                    _this.handleRequiredSubscriptions();
                }));
                _this.subscriptions.push(_this.vehicleRepository.index().subscribe(function (data) {
                    _this.vehiclesLoaded = true;
                    _this.handleRequiredSubscriptions();
                }));
                _this.subscriptions.push(_this.roleRepository.index().subscribe(function (data) {
                    _this.rolesLoaded = true;
                    _this.handleRequiredSubscriptions();
                }));
                _this.subscriptions.push(_this.messageRepository.index().subscribe());
            }
            else {
                _this.clearSubscriptions();
            }
        });
    };
    /**
     * Checks all required entities for being loaded and fires the loaded event
     */
    AppComponent.prototype.handleRequiredSubscriptions = function () {
        // At this moment the following entities are required for a fully functional homepage (EmployeeActivityScheduler);
        // EmployeeActivities
        // Vehicles
        // Users
        // Roles
        if (this.employeeActivitiesLoaded && this.usersLoaded && this.vehiclesLoaded && this.rolesLoaded && this.statusesLoaded) {
            this.on.load.next(true);
        }
    };
    /**
     * Clears subscriptions disposing the held resources
     */
    AppComponent.prototype.clearSubscriptions = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.subscriptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var subscription = _c.value;
                subscription.unsubscribe();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return AppComponent;
}());
export { AppComponent };
