import { JsonProperty, deserialize } from "json-typescript-mapper";

import { User as IUser } from "./interfaces/user.interface";
import { Role } from "./role";
import { UserResponse } from "../responses/user.response";
import { UserSocketResponse } from "../responses/user-socket.response";
import { UsersResponse } from "../responses/users.response";
import { Variables } from "../shared.variables";
import { Vehicle } from "./vehicle";

export const initialState: IUser = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  fullName: undefined,
  email: undefined,
  role: undefined,
  vehicleId: undefined,
  vehicle: undefined,
  isActive: undefined,
};

export class User implements IUser {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("first_name")
  public firstName: string;
  @JsonProperty("last_name")
  public lastName: string;
  @JsonProperty("full_name")
  public fullName: string;
  @JsonProperty("email")
  public email: string;
  @JsonProperty({ clazz: Role, name: "role" })
  public role: Role;
  @JsonProperty("vehicle_id")
  public vehicleId: number;
  public vehicle: Vehicle;
  @JsonProperty("is_active")
  public isActive: boolean;

  constructor(init?: Partial<IUser>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parse(data: UserResponse): User {
    return deserialize(User, data);
  }

  public static parseSocket(data: UserSocketResponse): User {
    return deserialize(User, data.user);
  }

  public static parseMany(data: UsersResponse): User[] {
    return data.users.map(user => deserialize(User, user));
  }

  /**
   * Returns if a user is plannable
   */
  public get isPlannable(): boolean {
    let result = false;

    // TODO: Maybe find some fancy way to load the resources if they are unavailable
    // AWA: Should we make console logs or something to see if relations are missing. Maybe dev only?
    if (this.role && this.role.rules && this.role.rules.find(rule => rule.name === Variables.PLANNABLE_RULE)) {
      result = true;
    }

    return result;
  }
}
