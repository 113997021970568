export enum EmployeeActivityType {
  Work = 0,
  Unavailable = 1,
  Available = 2
}

// tslint:disable-next-line:variable-name
export const EmployeeActivityTypeTranslated = new Map<number, string>([
  [EmployeeActivityType.Work, "Werk"],
  [EmployeeActivityType.Unavailable, "Onbeschikbaar"],
  [EmployeeActivityType.Available, "Beschikbaar"]
]);
