import { AuthGuard } from "src/app/guards/auth/auth.guard";
import { CommonModule } from "@angular/common";
import { IsAuthenticatedGuard } from "src/app/guards/auth/is-authenticated.guard";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AuthGuard,
    IsAuthenticatedGuard
  ]
})
export class GuardsModule { }
