import { JsonProperty } from "json-typescript-mapper";

export class MessageUpdateRequest {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("confirmed")
  public confirmed: boolean;

  public constructor(init?: Partial<MessageUpdateRequest>) {
    Object.assign(this, init);
  }
}
