import { ForgotPassword as IForgotPassword } from "./interfaces/forgot-password.interface";
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { ForgotPasswordResponse } from "../responses/forgot-password.response";

/**
 * Default state for an empty IForgotPassword object
 */
export const initialState: IForgotPassword = {
  success: undefined,
  message: undefined,
};

export class ForgotPassword implements IForgotPassword {
  @JsonProperty("success")
  public success: boolean;
  @JsonProperty("message")
  public message: string;

  constructor(init?: Partial<IForgotPassword>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parse(data: ForgotPasswordResponse): ForgotPassword {
    return deserialize(ForgotPassword, data);
  }
}
