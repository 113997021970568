import { createFeatureSelector, createSelector } from "@ngrx/store";
import { StatusState } from "./status.state";
import { statusAdapter } from "./status.adapter";

export const getStatusState = createFeatureSelector<StatusState>("statuses");
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = statusAdapter.getSelectors(getStatusState);

// export const selectById = (id: number) => createSelector(getStatusState, (state: StatusState) => state.entities[id]);


