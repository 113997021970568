/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multiple-choice-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./multiple-choice-dialog.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_MultipleChoiceDialogComponent = [i0.styles];
var RenderType_MultipleChoiceDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultipleChoiceDialogComponent, data: {} });
export { RenderType_MultipleChoiceDialogComponent as RenderType_MultipleChoiceDialogComponent };
function View_MultipleChoiceDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-12"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
function View_MultipleChoiceDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "btn ", _co.cancelButtonClass, " float-left"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.cancelButtonText; _ck(_v, 1, 0, currVal_1); }); }
export function View_MultipleChoiceDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleChoiceDialogComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.firstChoiceConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.secondChoiceConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleChoiceDialogComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); var currVal_6 = !_co.hideCancelButton; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "btn ", _co.firstChoiceButtonClass, " float-right"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.firstChoiceButtonText; _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "btn ", _co.secondChoiceButtonClass, " float-right"); _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.secondChoiceButtonText; _ck(_v, 9, 0, currVal_5); }); }
export function View_MultipleChoiceDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multiple-choice-dialog", [], null, null, null, View_MultipleChoiceDialogComponent_0, RenderType_MultipleChoiceDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.MultipleChoiceDialogComponent, [i4.BsModalRef], null, null)], null, null); }
var MultipleChoiceDialogComponentNgFactory = i1.ɵccf("app-multiple-choice-dialog", i3.MultipleChoiceDialogComponent, View_MultipleChoiceDialogComponent_Host_0, { title: "title", firstChoiceButtonText: "firstChoiceButtonText", firstChoiceButtonClass: "firstChoiceButtonClass", secondChoiceButtonText: "secondChoiceButtonText", secondChoiceButtonClass: "secondChoiceButtonClass", cancelButtonText: "cancelButtonText", cancelButtonClass: "cancelButtonClass", hideCancelButton: "hideCancelButton", message: "message" }, {}, []);
export { MultipleChoiceDialogComponentNgFactory as MultipleChoiceDialogComponentNgFactory };
