import { type } from "../utils/utils";
import { User as IUser } from "../../models/interfaces/user.interface";

export class UserActions {
  static ADD_USERS = type("ADD_USERS");
  static ADD_USER = type("ADD_USER");
  static EDIT_USER = type("EDIT_USER");
  static DELETE_USER = type("DELETE_USER");

  static addUsers(payload: IUser[]) {
    return {
      type: UserActions.ADD_USERS,
      payload
    };
  }

  static addUser(payload: IUser) {
    return {
      type: UserActions.ADD_USER,
      payload
    };
  }

  static editUser(payload: IUser) {
    return {
      type: UserActions.EDIT_USER,
      payload
    };
  }

  static deleteUser(id: number) {
    return {
      type: UserActions.DELETE_USER,
      payload: id
    };
  }

}
