import { HttpHeaders, HttpParams } from "@angular/common/http";
import { serialize } from "json-typescript-mapper";

/* TODO: Implement HTTPInterceptor for auth headers and serialized body */

/**
 * TODO: Add comments
 * @param data
 */
export function prepareHeaders(data: any): HttpHeaders {
  // TODO: Add data to headers (possibly in style of [{key, value},{key, value}]. Create a model of this named header.ts)
  return new HttpHeaders();
}

/**
 * TODO: Add comments
 * @param data
 */
export function prepareAuthHeaders(data: any = undefined): HttpHeaders {
  let headers = prepareHeaders(data);
  const auth = JSON.parse(localStorage.getItem("auth"));

  // Add auth headers to basic headers
  headers = headers.append(
    "Authorization",
    "Bearer " + auth.apiToken
  );

  // Add socket token if exists
  if (auth.socketToken) {
    headers = headers.append(
      "X-Socket-Id",
      auth.socketToken
    );
  }

  return headers;
}

/**
 * TODO: Add comments
 * @param data
 */
export function prepareParams(data: any): HttpParams {
  let params = new HttpParams();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      params = params.set(key, data[key]);
    }
  }

  return params;
}

/**
 * TODO: Add comments
 * @param data
 */
export function prepareBody(data: any): HttpHeaders {
  return serialize(data);
}
