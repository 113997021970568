import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders, prepareBody } from "@helpers/http";
import { VehicleActivityUpdateRequest } from "@requests/vehicle-activity-update.request";
import { VehicleActivityCreateRequest } from "@requests/vehicle-activity-create.request";
import { VehicleActivityResponse } from "@responses/vehicle-activity.response";
import { VehicleActivitiesResponse } from "@responses/vehicle-activities.response";
import { environment } from "../../../environments/environment";

@Injectable()
export class VehicleActivityService {

    constructor(
        private http: HttpClient
    ) {
    }

    public index(): Observable<VehicleActivitiesResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<VehicleActivitiesResponse>(`${environment.api}/vehicles/activities`, {headers});
    }

    public create(request: VehicleActivityCreateRequest): Observable<VehicleActivityResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.post<VehicleActivityResponse>(`${environment.api}/vehicles/activity`, body, {headers});
    }

    public update(id: number, request: VehicleActivityUpdateRequest): Observable<VehicleActivityResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.put<VehicleActivityResponse>(`${environment.api}/vehicles/activity/${id}`, body, {headers});
    }

    public delete(id: number): Observable<VehicleActivityResponse> {
        const headers = prepareAuthHeaders();
        return this.http.delete<VehicleActivityResponse>(`${environment.api}/vehicles/activity/${id}`, {headers});
    }
}
