<div class="modal-header">
  <h4 class="modal-title">Bestand uploaden</h4>
  <h3>Vul de onderstaande gegevens in</h3>
</div>

<div class="modal-body">
  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <label for="categoryPicker">Kies een categorie</label>
        <select (change)="onCategorySelected($event)" id="categoryPicker" class="form-control input-lg">
          <option value="" disabled>Selecteer een categorie</option>
          <option *ngFor="let category of categories" [value]="category.id">{{category.category_name}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <label for="filePicker">Kies een bestand</label>
        <input id="filePicker" type="file" (change)="onFileSelected($event)">
      </div>
    </div>
  </div>
  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <ng-container *ngIf="selectedFile">
          <div class="alert-info rounded p-2 text-sm-left" role="alert" *ngIf="!validFile">Alleen Word, Excel of PDF bestanden zijn mogelijk.</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary float-left" (click)="onUpload()" [disabled]="!formValidation()">Uploaden</button>
  <a class="close-modal float-left" (click)="close()">Sluiten</a>
</div>

