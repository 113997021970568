var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { VehicleActions } from "../../states/vehicle/vehicle.actions";
import { VehicleService } from "../../services/vehicle/vehicle.service";
import { Vehicle } from "../../models/vehicle";
import * as VehicleSelectors from "../../states/vehicle/vehicle.selectors";
var VehicleRepository = /** @class */ (function (_super) {
    __extends(VehicleRepository, _super);
    function VehicleRepository(store, notificationActions, vehicleService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.vehicleService = vehicleService;
        _this.vehicles$ = _this.store.select(VehicleSelectors.selectAllWithoutDeleted());
        _this.CREATE_SUCCESS = "Voertuig aangemaakt!";
        _this.EDIT_SUCCESS = "Voertuig aangepast!";
        return _this;
    }
    VehicleRepository.prototype.index = function () {
        var _this = this;
        return this.vehicleService.index().pipe(map(function (response) {
            var vehicles = Vehicle.parseMany(response);
            _this.store.dispatch(VehicleActions.addVehicles(vehicles));
            return vehicles;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleRepository.prototype.create = function (request) {
        var _this = this;
        return this.vehicleService.create(request).pipe(map(function (response) {
            var vehicle = Vehicle.parse(response);
            _this.store.dispatch(VehicleActions.addVehicle(vehicle));
            _this.setInfo(_this.CREATE_SUCCESS);
            return vehicle;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleRepository.prototype.createFromSocket = function (response) {
        var vehicle = Vehicle.parseSocket(response);
        this.store.dispatch(VehicleActions.addVehicle(vehicle));
    };
    VehicleRepository.prototype.update = function (id, request) {
        var _this = this;
        return this.vehicleService.update(id, request).pipe(map(function (response) {
            var vehicle = Vehicle.parse(response);
            _this.store.dispatch(VehicleActions.editVehicle(vehicle));
            _this.setInfo(_this.EDIT_SUCCESS);
            return vehicle;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleRepository.prototype.updateFromSocket = function (response) {
        var vehicle = Vehicle.parseSocket(response);
        this.store.dispatch(VehicleActions.editVehicle(vehicle));
    };
    VehicleRepository.prototype.delete = function (id) {
        var _this = this;
        return this.vehicleService.delete(id).pipe(map(function (response) {
            _this.store.dispatch(VehicleActions.deleteVehicle(id));
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    VehicleRepository.prototype.deleteFromSocket = function (response) {
        var vehicle = Vehicle.parseSocket(response);
        this.store.dispatch(VehicleActions.deleteVehicle(vehicle.id));
    };
    return VehicleRepository;
}(Repository));
export { VehicleRepository };
