import { VehicleState } from "./vehicle.state";
import { VehicleActions } from "./vehicle.actions";
import { vehicleAdapter } from "./vehicle.adapter";

const initialState: VehicleState = vehicleAdapter.getInitialState();

export function VehicleReducer(state: VehicleState = initialState, action): VehicleState {
  switch (action.type) {
    case VehicleActions.ADD_VEHICLES:
      return vehicleAdapter.addMany(
        action.payload,
        vehicleAdapter.removeAll(state)
      );
    case VehicleActions.ADD_VEHICLE:
      return vehicleAdapter.addOne(action.payload, state);
    case VehicleActions.EDIT_VEHICLE:
      return vehicleAdapter.updateOne({
          id: action.payload.id,
          changes: action.payload,
        },
        state
      );
    case VehicleActions.DELETE_VEHICLE:
      return vehicleAdapter.removeOne(action.payload, state);
    default:
      return state;
  }
}
