import { EmployeeActivity as IEmployeeActivity } from "./interfaces/employee-activity.interface";
import { EmployeeActivities as IEmployeeActivities } from "./interfaces/employee-activities.interface";
import { deserialize, JsonProperty } from "json-typescript-mapper";
import { EmployeeActivityResponse } from "@responses/employee-activity.response";
import { EmployeeActivitiesResponse } from "@responses/employee-activities.response";
import { EmployeeActivitySocketResponse } from "@responses/employee-activity-socket.response";
import { User } from "./user";
import { Vehicle } from "./vehicle";
import { Daypart } from "@enums/daypart.enum";
import { EmployeeActivityType, EmployeeActivityTypeTranslated } from "@enums/employee-activity-type.enum";
import {
    EmployeeActivityTypeDetails,
    EmployeeActivityTypeDetailsTranslated
} from "@enums/employee-activity-type-details.enum";
import { EmployeeActivityStatus } from "@enums/employee-activity-status.enum";

export const initialState: IEmployeeActivity = {
  id: undefined,
  draft: true,
  startDate: undefined,
  endDate: undefined,
  activityType: undefined,
  activityTypeDetails: undefined,
  userId: undefined,
  user: undefined,
  vehicleId: undefined,
  vehicle: undefined,
  status: undefined,
  handlingUserId: undefined,
  handlingUser: undefined,
  comment: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  activityDaypart: Daypart.Day
};

export class EmployeeActivity implements IEmployeeActivity {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("draft")
  public draft: boolean;
  @JsonProperty("start_date")
  public startDate: string;
  @JsonProperty("end_date")
  public endDate: string;
  @JsonProperty("activity_type")
  public activityType: EmployeeActivityType;
  @JsonProperty("activity_type_details")
  public activityTypeDetails: EmployeeActivityTypeDetails;
  @JsonProperty("user_id")
  public userId: number;
  public user: User;
  @JsonProperty("vehicle_id")
  public vehicleId: number;
  public vehicle: Vehicle;
  @JsonProperty("handling_user_id")
  public handlingUserId: number;
  public handlingUser: User;
  @JsonProperty("comment")
  public comment: string;
  @JsonProperty("status")
  public status: EmployeeActivityStatus;
  @JsonProperty("created_at")
  public createdAt: string;
  @JsonProperty("updated_at")
  public updatedAt: string;
  @JsonProperty("activity_daypart")
  public activityDaypart: Daypart;

  constructor(init?: Partial<IEmployeeActivity>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parse(data: EmployeeActivityResponse): EmployeeActivity {
    return deserialize(EmployeeActivity, data);
  }

  public static parseSocket(data: EmployeeActivitySocketResponse): EmployeeActivity {
    return deserialize(EmployeeActivity, data.activity);
  }

  public static parseMany(data: EmployeeActivitiesResponse): IEmployeeActivities {
    return data.activities.map(employeeActivity => deserialize(EmployeeActivity, employeeActivity));
  }

  /**
   * Get a human readable version for type
   */
  public get activityTypeDisplay(): string {
    return EmployeeActivityTypeTranslated[this.activityType];
  }

  /**
   * Get a human readable version for typeDetails
   */
  public get activityTypeDetailsDisplay(): string {
    return EmployeeActivityTypeDetailsTranslated.get(this.activityTypeDetails);
  }

  /**
   * Get a short human readable version for activityDaypart
   */
  public get activityDaypartDisplayShort(): string {
    switch (this.activityDaypart) {
      case Daypart.Day:
        return "D";
      case Daypart.Night:
        return "N";
      case Daypart.Both:
        return "B";
    }
  }

  /**
   * Get a long human readable version for activityDaypart
   */
  public get activityDaypartDisplayLong(): string {
    switch (this.activityDaypart) {
      case Daypart.Day:
        return "Dagdienst";
      case Daypart.Night:
        return "Nachtdienst";
      case Daypart.Both:
        return "Beide";
    }
  }
}
