import { Sorting } from "../../models/enums/sorting.enum";
import { EmployeeActivityActions } from "./employee-activity.actions";
import { EmployeeActivityState } from "./employee-activity.state";
import { employeeActivityAdapter } from "./employee-activity.adapter";
import { Sortable } from "@enums/sortable.enum";
// import {Sortable} from "@web/models/enums/sortable.enum";
// import { Sortable } from "../../../../shared/src/models/enums/sortable.enum";

const initialState: EmployeeActivityState = employeeActivityAdapter.getInitialState({
  sort: Sorting.asc,
  sortType: Sortable.user,
  lastUpdate: undefined
});

export function EmployeeActivityReducer(state: EmployeeActivityState = initialState, action): EmployeeActivityState {
  switch (action.type) {
    case EmployeeActivityActions.ADD_EMPLOYEE_ACTIVITIES:
      return {
        ...employeeActivityAdapter.addMany(
          action.payload.employeeActivities,
          employeeActivityAdapter.removeAll(state)
        ),
        lastUpdate: action.payload.lastUpdate
      };
    case EmployeeActivityActions.ADD_EMPLOYEE_ACTIVITY:
      return employeeActivityAdapter.addOne(action.payload, state);
    case EmployeeActivityActions.EDIT_EMPLOYEE_ACTIVITY:
      return employeeActivityAdapter.updateOne({
          id: action.payload.id,
          changes: action.payload,
        },
        state
      );
    case EmployeeActivityActions.DELETE_EMPLOYEE_ACTIVITY:
      return employeeActivityAdapter.removeOne(action.payload, state);
    case EmployeeActivityActions.TOGGLE_EMPLOYEE_ACTIVITY_SORT:
      return {
        ...state,
        sort: state.sort === Sorting.asc ? Sorting.desc : Sorting.asc,
        sortType: action.payload
      };
    default:
      return state;
  }
}
