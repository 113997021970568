import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SchemeState } from "./scheme.state";
import { schemeAdapter } from "./scheme.adapter";
import { AppState } from "../app/app.state";

export const getSchemeState = createFeatureSelector<SchemeState>("schemes");
export const getAppState = (state: AppState) => state;  // TODO: move to own file

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = schemeAdapter.getSelectors(getSchemeState);

export const selectById = (id: number) => createSelector(getSchemeState, (state: SchemeState) => state.entities[id]);


