/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message-item.component";
var styles_MessageItemComponent = [i0.styles];
var RenderType_MessageItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageItemComponent, data: {} });
export { RenderType_MessageItemComponent as RenderType_MessageItemComponent };
export function View_MessageItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "notification-item-wrapper border-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row m-0 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "m-0 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "m-0 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-1 text-right buttons mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "ion-md-checkmark text-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markCompleted() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.message.content; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.moment(_co.message.sendAt).format(_co.dateFormat); _ck(_v, 8, 0, currVal_2); }); }
export function View_MessageItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-item", [], null, null, null, View_MessageItemComponent_0, RenderType_MessageItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.MessageItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageItemComponentNgFactory = i1.ɵccf("app-message-item", i2.MessageItemComponent, View_MessageItemComponent_Host_0, { message: "message" }, { completed: "completed" }, []);
export { MessageItemComponentNgFactory as MessageItemComponentNgFactory };
