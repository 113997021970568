import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders } from "@helpers/http";
import { SchemesResponse } from "@responses/schemes.response";
import { environment } from "../../../environments/environment";
import { SchemeRequest } from "@requests/scheme.request";

@Injectable()
export class SchemeService {

  constructor(
    private http: HttpClient
  ) {
  }

  public index(): Observable<SchemesResponse> {
    const headers = prepareAuthHeaders();
    return this.http.get<SchemesResponse>(`${environment.api}/schemes`, { headers });
  }

  public update(id: number, request: SchemeRequest): Observable<[]> {
    const headers = prepareAuthHeaders();
    return this.http.put<[]>(`${environment.api}/schemes/${id}`, request, { headers });
  }

  public add(request: SchemeRequest): Observable<[]> {
    const headers = prepareAuthHeaders();
    return this.http.post<[]>(`${environment.api}/schemes`, request, { headers });
  }

  public delete(id: number): Observable<boolean> {
    const headers = prepareAuthHeaders();
    return this.http.delete<boolean>(`${environment.api}/schemes/${id}`, {headers});
  }
}
