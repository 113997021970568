import { JsonProperty, deserialize } from "json-typescript-mapper";
import { Status as IStatus } from "./interfaces/status.interface";
import { StatusResponse } from "@app/responses/status.response";

export const initialState: IStatus = {
    id: undefined,
    title: undefined,
    color: undefined
};

export class Status implements IStatus {
    @JsonProperty("id") public id: number;
    @JsonProperty("title") public title: string;
    @JsonProperty("color") public color: string;

    constructor(init?: Partial<IStatus>) {
        Object.assign(this, initialState, init);
    }

    public static parseMany(data: StatusResponse): Status[] {
        return data.statuses.map(status => deserialize(Status, status));
    }

    public static parse(data: StatusResponse): Status {
        return deserialize(Status, data);
    }
}
