var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { UserActions } from "../../states/user/user.actions";
import { UserService } from "../../services/user/user.service";
import { User } from "../../models/user";
import * as UserSelectors from "../../states/user/user.selectors";
var UserRepository = /** @class */ (function (_super) {
    __extends(UserRepository, _super);
    function UserRepository(store, notificationActions, userService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.userService = userService;
        _this.users$ = _this.store.select(UserSelectors.selectAll);
        _this.CREATE_SUCCESS = "Gebruiker aangemaakt!";
        _this.EDIT_SUCCESS = "Gebruiker aangepast!";
        _this.REINVITE_SUCCESS = "Uitnodiging opnieuw verstuurd!";
        return _this;
    }
    UserRepository.prototype.index = function () {
        var _this = this;
        return this.userService.index().pipe(map(function (response) {
            var users = User.parseMany(response);
            _this.store.dispatch(UserActions.addUsers(users));
            return users;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    UserRepository.prototype.create = function (request) {
        var _this = this;
        return this.userService.create(request).pipe(map(function (response) {
            var user = User.parse(response);
            _this.store.dispatch(UserActions.addUser(user));
            _this.setInfo(_this.CREATE_SUCCESS);
            return user;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    UserRepository.prototype.createFromSocket = function (response) {
        var user = User.parseSocket(response);
        this.store.dispatch(UserActions.addUser(user));
    };
    UserRepository.prototype.update = function (id, request) {
        var _this = this;
        return this.userService.update(id, request).pipe(map(function (response) {
            var user = User.parse(response);
            _this.store.dispatch(UserActions.editUser(user));
            _this.setInfo(_this.EDIT_SUCCESS);
            return user;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    UserRepository.prototype.updateFromSocket = function (response) {
        var user = User.parseSocket(response);
        this.store.dispatch(UserActions.editUser(user));
    };
    UserRepository.prototype.delete = function (id) {
        var _this = this;
        return this.userService.delete(id).pipe(map(function (response) {
            _this.store.dispatch(UserActions.deleteUser(id));
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    UserRepository.prototype.deleteFromSocket = function (response) {
        var user = User.parseSocket(response);
        this.store.dispatch(UserActions.deleteUser(user.id));
    };
    UserRepository.prototype.resendInvite = function (id) {
        var _this = this;
        return this.userService.resendInvite(id).pipe(map(function (response) {
            _this.setInfo(_this.REINVITE_SUCCESS);
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    return UserRepository;
}(Repository));
export { UserRepository };
