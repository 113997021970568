import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CategoryService } from "@services/category/category.service";

@Component({
  selector: "app-delete-category-modal",
  templateUrl: "./delete-category-modal.component.html",
  styleUrls: ["./delete-category-modal.component.scss"]
})
export class DeleteCategoryModalComponent {
  @Input() category;

  constructor(
    private bsModalRef: BsModalRef,
    private categoryService: CategoryService
  ) { }

  public delete(): void {
    this.categoryService.delete(this.category.id)
      .subscribe(res => {
        this.bsModalRef.hide();
      });
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}
