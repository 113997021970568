/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-category-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./delete-category-modal.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../services/category/category.service";
var styles_DeleteCategoryModalComponent = [i0.styles];
var RenderType_DeleteCategoryModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteCategoryModalComponent, data: {} });
export { RenderType_DeleteCategoryModalComponent as RenderType_DeleteCategoryModalComponent };
export function View_DeleteCategoryModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Weet u zeker dat u deze categorie wilt verwijderen?"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-body "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "row my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-danger float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Verwijderen"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "close-modal float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sluiten"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.category.category_name; _ck(_v, 2, 0, currVal_0); }); }
export function View_DeleteCategoryModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-category-modal", [], null, null, null, View_DeleteCategoryModalComponent_0, RenderType_DeleteCategoryModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.DeleteCategoryModalComponent, [i3.BsModalRef, i4.CategoryService], null, null)], null, null); }
var DeleteCategoryModalComponentNgFactory = i1.ɵccf("app-delete-category-modal", i2.DeleteCategoryModalComponent, View_DeleteCategoryModalComponent_Host_0, { category: "category" }, {}, []);
export { DeleteCategoryModalComponentNgFactory as DeleteCategoryModalComponentNgFactory };
