import { type } from "../utils/utils";
import { Auth as IAuth } from "../../models/interfaces/auth.interface";

export class AuthActions {
    static SET_AUTHENTICATION = type("SET_AUTHENTICATION");
    static CLEAR_AUTHENTICATION = type("CLEAR_AUTHENTICATION");
    static SET_SOCKET_TOKEN = type("SET_SOCKET_TOKEN");

    static setAuthentication(payload: IAuth) {
        return {
            type: AuthActions.SET_AUTHENTICATION,
            payload
        };
    }

    static clearAuthentication() {
        return {
            type: AuthActions.CLEAR_AUTHENTICATION
        };
    }

    static setSocketToken(payload: string) {
        return {
            type: AuthActions.SET_SOCKET_TOKEN,
            payload
        };
    }
}
