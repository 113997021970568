import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders, prepareBody } from "@helpers/http";
import { UserUpdateRequest } from "@requests/user-update.request";
import { UsersResponse } from "@responses/users.response";
import { UserCreateRequest } from "@requests/user-create.request";
import { UserResponse } from "@responses/user.response";
import { environment } from "../../../environments/environment";

@Injectable()
export class UserService {

    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Execute a UserIndexRequest
     */
    public index(): Observable<UsersResponse> {
        const headers = prepareAuthHeaders();
        const res = this.http.get<UsersResponse>(`${environment.api}/users`, {headers});
        return res;
    }

    public create(request: UserCreateRequest): Observable<UserResponse> {
        const body = prepareBody(request);
        const headers = prepareAuthHeaders();
        return this.http.post<UserResponse>(`${environment.api}/user`, body, {headers});
    }

    public update(id: number, request: UserUpdateRequest): Observable<UserResponse> {
        const body = prepareBody(request);
        const headers = prepareAuthHeaders();
        return this.http.put<UserResponse>(`${environment.api}/user/${id}`, body, {headers});
    }

    public delete(id: number): Observable<boolean> {
        const headers = prepareAuthHeaders();
        return this.http.delete<boolean>(`${environment.api}/user/${id}`, {headers});
    }

    public resendInvite(id): Observable<boolean> {
        const headers = prepareAuthHeaders();
        return this.http.get<boolean>(`${environment.api}/user/resend_invite/${id}`, {headers});
    }
}
