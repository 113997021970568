import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders } from "@helpers/http";
import { environment } from "../../../environments/environment";
import { StatusCreateRequest } from "@app/requests/status-create.request";
import { StatusResponse } from "@app/responses/status.response";
import { StatusUpdateRequest } from "@app/requests/status-update.request";
import { prepareBody } from "@helpers/http";

@Injectable()
export class StatusService {
    constructor(private http: HttpClient) {}

    public index(): Observable<StatusResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<StatusResponse>(`${environment.api}/statuses`, {
            headers,
        });
    }

    public create(request: StatusCreateRequest): Observable<StatusResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.post<StatusResponse>(
            `${environment.api}/statuses`,
            body,
            { headers }
        );
    }

    public delete(id: number): Observable<boolean> {
        const headers = prepareAuthHeaders();
        return this.http.delete<boolean>(`${environment.api}/statuses/${id}`, {
            headers,
        });
    }

    public update(
        id: number,
        request: StatusUpdateRequest
    ): Observable<StatusResponse> {
        const headers = prepareAuthHeaders();
        const body = prepareBody(request);
        return this.http.put<StatusResponse>(
            `${environment.api}/statuses/${id}`,
            body,
            { headers }
        );
    }
}
