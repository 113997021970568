<div class="notifications-wrapper">
  <div class="inner-header">
    <h4>Notificaties</h4>
  </div>

  <div class="notitication-items-container">
    <div *ngFor="let notification of pendingActivities; let i = index">
      <app-notification-item *ngIf="isEmployeeActivity(notification) && shouldShow(i) && canAcceptAndDecline(notification)"
        [activity]="notification" (accept)="onAccept($event)" (decline)="onDecline($event)"></app-notification-item>
      <app-message-item *ngIf="isMessage(notification) && shouldShow(i)" [message]="notification" (completed)="markCompleted($event)"></app-message-item>
    </div>
    <div *ngIf="!pendingActivities || pendingActivities.length < 1" class="text-center">
      <i class="ion-md-done-all"></i>
      <span>Je bent helemaal up-to-date!</span>
    </div>
    <div class="pagination" *ngIf="shouldPaginate()">
      <div class="previous" *ngIf="currentPage > 1" (click)="prevPage()">Vorige</div>
      <div class="next" *ngIf="currentPage <= (pendingActivities.length / maxPageSize)" (click)="nextPage()">Volgende</div>
    </div>
  </div>
</div>