var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
export var initialState = {
    id: undefined,
    title: undefined,
    content: undefined,
    sendAt: undefined,
    userId: undefined,
    confirmed: undefined
};
var Message = /** @class */ (function () {
    function Message(init) {
        Object.assign(this, initialState, init);
    }
    Message.parse = function (data) {
        return deserialize(Message, data);
    };
    Message.parseSocket = function (data) {
        return deserialize(Message, data.message);
    };
    Message.parseMany = function (data) {
        return data.messages.map(function (message) { return deserialize(Message, message); });
    };
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], Message.prototype, "id", void 0);
    __decorate([
        JsonProperty("title"),
        __metadata("design:type", String)
    ], Message.prototype, "title", void 0);
    __decorate([
        JsonProperty("content"),
        __metadata("design:type", String)
    ], Message.prototype, "content", void 0);
    __decorate([
        JsonProperty("send_at"),
        __metadata("design:type", String)
    ], Message.prototype, "sendAt", void 0);
    __decorate([
        JsonProperty("user_id"),
        __metadata("design:type", Number)
    ], Message.prototype, "userId", void 0);
    __decorate([
        JsonProperty("confirmed"),
        __metadata("design:type", Boolean)
    ], Message.prototype, "confirmed", void 0);
    return Message;
}());
export { Message };
