import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { AuthActions } from "../../states/auth/auth.actions";
import { AuthService } from "../../services/auth/auth.service";
import { LoginRequest } from "../../requests/login.request";
import { ForgotPasswordRequest } from "../../requests/forgot-password.request";
import { Repository } from "../repository.abstract";
import { NotificationActions } from "../../states/notification/notification.actions";
import { ResetPasswordRequest } from "../../requests/reset-password.request";
import { Auth } from "../../models/auth";
import { ForgotPassword } from "../../models/forgot-password";
import { ForgotPassword as IForgotPassword } from "../../models/interfaces/forgot-password.interface";
import { ResetPassword } from "../../models/reset-password";
import { ResetPassword as IResetPassword } from "../../models/interfaces/reset-password.interface";
import { Auth as IAuth } from "../../models/interfaces/auth.interface";
import { AuthState } from "../../states/auth/auth.state";

@Injectable()
export class AuthRepository extends Repository {
  public auth$: Observable<AuthState> = this.store.select(s => s.auth);
  // TODO: Create this as key,value and translator options
  private readonly FORGOT_PASSWORD_SUCCESS: string = "Een e-mail met verdere instructies is verstuurd!";
  private readonly LOGOUT_SUCCESS: string = "U bent succesvol uitgelogd!";
  private readonly RESET_PASSWORD_SUCCESS: string = "Wachtwoord gewijzigd!";
  private readonly ACTIVATE_ACCOUNT_SUCCESS: string = "Account geactiveerd!";

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private authActions: AuthActions,
    private authService: AuthService
  ) {
    super(store, notificationActions);
  }

  public validateLogin(): Observable<boolean> {
    return this.authService.me().pipe(map(() => {
        return true;
      }), catchError(() => {
        return observableOf(false);
      })
    );
  }

  public login(request: LoginRequest): Observable<{} | IAuth> {
    return this.authService.login(request).pipe(map(response => {
        const auth = Auth.parse(response);
        this.store.dispatch(AuthActions.setAuthentication(auth));
        return auth;
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public setSocketToken(token: string): void {
    this.store.dispatch(AuthActions.setSocketToken(token));
  }

  public logout(): Observable<{} | any> {
    return this.authService.logout().pipe(map(response => {
        this.store.dispatch(AuthActions.clearAuthentication());
        this.setInfo(this.LOGOUT_SUCCESS);
        return response;
      }), catchError(response => {
        this.store.dispatch(AuthActions.clearAuthentication());
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public forceLogout(silent: boolean): Observable<{} | any> {
    this.store.dispatch(AuthActions.clearAuthentication());
    return this.authService.logout().pipe(map(response => {
        if (!silent) {
          this.setInfo(this.LOGOUT_SUCCESS);
        }

        return response;
      }), catchError(response => {
        if (!silent) {
          this.setError(JSON.stringify(response.error));
        }

        return observableOf(undefined);
      })
    );
  }

  public forgotPassword(request: ForgotPasswordRequest): Observable<{} | IForgotPassword> {
    return this.authService.forgotPassword(request).pipe(map(response => {
        const forgotPassword = ForgotPassword.parse(response);
        this.setInfo(this.FORGOT_PASSWORD_SUCCESS);
        return forgotPassword;
      }), catchError(response => {
        const forgotPassword = ForgotPassword.parse(response.error);
        this.setError(forgotPassword.message);
        return forgotPassword.success ? observableOf(forgotPassword) : observableOf(undefined);
      })
    );
  }

  public resetPassword(request: ResetPasswordRequest): Observable<{} | IResetPassword> {
    return this.authService.resetPassword(request).pipe(map(response => {
        const resetPassword = ResetPassword.parse(response);
        this.setInfo(this.RESET_PASSWORD_SUCCESS);
        this.store.dispatch(AuthActions.setAuthentication(resetPassword));
        return resetPassword;
      }), catchError(response => {
        const resetPassword = ResetPassword.parse(response);
        this.setError(resetPassword.message);
        return resetPassword.success ? observableOf(resetPassword) : observableOf(undefined);
      })
    );
  }

  public activateAccount(request: ResetPasswordRequest): Observable<{} | IResetPassword> {
    return this.authService.activateAccount(request).pipe(map(response => {
        const resetPassword = ResetPassword.parse(response);
        this.setInfo(this.ACTIVATE_ACCOUNT_SUCCESS);
        this.store.dispatch(AuthActions.setAuthentication(resetPassword));
        return resetPassword;
      }), catchError(response => {
        const resetPassword = ResetPassword.parse(response);
        this.setError(resetPassword.message);
        return resetPassword.success ? observableOf(resetPassword) : observableOf(undefined);
      })
    );
  }
}
