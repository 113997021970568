var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DashboardActions } from "./dashboard.actions";
export var initialState = {
    totalRides: {
        totalActivities: undefined,
        totalActivitiesDay: undefined,
        totalActivitiesNight: undefined,
        totalActivitiesBoth: undefined,
    },
    employees: [
        {
            name: "",
            totalActivities: undefined,
            totalActivitiesDay: undefined,
            totalActivitiesNight: undefined,
            totalActivitiesBoth: undefined,
        }
    ]
};
export function DashboardReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DashboardActions.ADD_DASHBOARD:
            return __assign({}, state, { totalRides: action.payload.totalRides, employees: action.payload.employees });
        default:
            return state;
    }
}
