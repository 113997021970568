<div class="topbar">
  <div class="row">
    <div class="col">
      <button class="btn btn-link text-white" (click)="sidebarClick()">
        <i *ngIf="!sidebarToggle" class="ion-ios-arrow-back"></i>
        <i *ngIf="sidebarToggle" class="ion-ios-arrow-forward"></i>
      </button>
    </div>
    <div class="col-auto icons" *ngIf="(user$ | async)?.role.name !== 'Werknemer'">
      <span (click)="toggleNotifications()">
        <i class="ion-md-notifications"></i>
        <span class="badge badge-pill badge-orange" *ngIf="notificationsCount > 0">{{ notificationsCount }}</span>
      </span>
      <app-notifications *ngIf="showNotifications" class="notification-dropdown" [activities]="pendingActivities"
        (acceptActivity)="onAcceptActivity($event)" (declineActivity)="onDeclineActivity($event)" (completed)="onMarkCompleted($event)"></app-notifications>
    </div>
    <div class="col-auto profile">
      <span class="d-none d-sm-inline">{{ (user$ | async).fullName }} | </span>
      <a (click)="logout()"><span class="d-none d-sm-inline">Log uit </span><i class="ion-md-log-out"></i></a>
    </div>
  </div>
</div>