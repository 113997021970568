/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./general-file-upload-modal-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./general-file-upload-modal-component.component";
import * as i5 from "../../services/file/file.service";
import * as i6 from "../../services/category/category.service";
import * as i7 from "ngx-bootstrap/modal";
var styles_GeneralFileUploadModalComponentComponent = [i0.styles];
var RenderType_GeneralFileUploadModalComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeneralFileUploadModalComponentComponent, data: {} });
export { RenderType_GeneralFileUploadModalComponentComponent as RenderType_GeneralFileUploadModalComponentComponent };
function View_GeneralFileUploadModalComponentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.category_name; _ck(_v, 3, 0, currVal_2); }); }
function View_GeneralFileUploadModalComponentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert-info rounded p-2 text-sm-left"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alleen Word, Excel of PDF bestanden zijn mogelijk."]))], null, null); }
function View_GeneralFileUploadModalComponentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeneralFileUploadModalComponentComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.validFile; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GeneralFileUploadModalComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bestand uploaden"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vul de onderstaande gegevens in"])), (_l()(), i1.ɵeld(5, 0, null, null, 23, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "row my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["for", "categoryPicker"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kies een categorie"])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "select", [["class", "form-control input-lg"], ["id", "categoryPicker"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onCategorySelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "option", [["disabled", ""], ["value", ""]], null, null, null, null, null)), i1.ɵdid(13, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(14, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["Selecteer een categorie"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeneralFileUploadModalComponentComponent_1)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "row my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "label", [["for", "filePicker"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kies een bestand"])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "input", [["id", "filePicker"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "row my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeneralFileUploadModalComponentComponent_2)), i1.ɵdid(28, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "btn btn-primary float-left"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Uploaden"])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "a", [["class", "close-modal float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sluiten"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 13, 0, currVal_0); var currVal_1 = ""; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.categories; _ck(_v, 17, 0, currVal_2); var currVal_3 = _co.selectedFile; _ck(_v, 28, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.formValidation(); _ck(_v, 30, 0, currVal_4); }); }
export function View_GeneralFileUploadModalComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-general-file-upload-modal-component", [], null, null, null, View_GeneralFileUploadModalComponentComponent_0, RenderType_GeneralFileUploadModalComponentComponent)), i1.ɵdid(1, 114688, null, 0, i4.GeneralFileUploadModalComponentComponent, [i5.FileService, i6.CategoryService, i7.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeneralFileUploadModalComponentComponentNgFactory = i1.ɵccf("app-general-file-upload-modal-component", i4.GeneralFileUploadModalComponentComponent, View_GeneralFileUploadModalComponentComponent_Host_0, {}, {}, []);
export { GeneralFileUploadModalComponentComponentNgFactory as GeneralFileUploadModalComponentComponentNgFactory };
