import { type } from "../utils/utils";

export class NotificationActions {
  static SET_ERROR = type("SET_ERROR");
  static CLEAR_ERROR = type("CLEAR_ERROR");
  static SET_INFO = type("SET_INFO");
  static CLEAR_INFO = type("CLEAR_INFO");
  static SET_SUCCESS = type("SET_SUCCESS");
  static CLEAR_SUCCESS = type("CLEAR_SUCCESS");
  static SET_WARNING = type("SET_WARNING");
  static CLEAR_WARNING = type("CLEAR_WARNING");

  static setError(payload) {
    return {
      type: NotificationActions.SET_ERROR,
      payload
    };
  }

  static clearError() {
    return {
      type: NotificationActions.CLEAR_ERROR
    };
  }

  static setInfo(payload) {
    return {
      type: NotificationActions.SET_INFO,
      payload
    };
  }

  static clearInfo() {
    return {
      type: NotificationActions.CLEAR_INFO
    };
  }

  static setSuccess(payload) {
    return {
      type: NotificationActions.SET_SUCCESS,
      payload
    };
  }

  static clearSuccess() {
    return {
      type: NotificationActions.CLEAR_SUCCESS
    };
  }

  static setWarning(payload) {
    return {
      type: NotificationActions.SET_WARNING,
      payload
    };
  }

  static clearWarning() {
    return {
      type: NotificationActions.CLEAR_WARNING
    };
  }
}
