import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { UserActions } from "../../states/user/user.actions";
import { UserService } from "../../services/user/user.service";
import { UserCreateRequest } from "../../requests/user-create.request";
import { UserUpdateRequest } from "../../requests/user-update.request";
import { UserSocketResponse } from "../../responses/user-socket.response";
import { User as IUser } from "../../models/interfaces/user.interface";
import { User } from "../../models/user";
import * as UserSelectors from "../../states/user/user.selectors";

@Injectable()
export class UserRepository extends Repository {
  public users$ = this.store.select(UserSelectors.selectAll);
  private readonly CREATE_SUCCESS: string = "Gebruiker aangemaakt!";
  private readonly EDIT_SUCCESS: string = "Gebruiker aangepast!";
  private readonly REINVITE_SUCCESS: string = "Uitnodiging opnieuw verstuurd!";

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private userService: UserService
  ) {
    super(store, notificationActions);
  }

  public index(): Observable<{} | IUser[]> {
    return this.userService.index().pipe(map(response => {
        const users = User.parseMany(response);
        this.store.dispatch(UserActions.addUsers(users));
        return users;
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public create(request: UserCreateRequest): Observable<{} | IUser> {
    return this.userService.create(request).pipe(map(response => {
        const user = User.parse(response);
        this.store.dispatch(UserActions.addUser(user));
        this.setInfo(this.CREATE_SUCCESS);
        return user;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public createFromSocket(response: UserSocketResponse): void {
    const user = User.parseSocket(response);
    this.store.dispatch(UserActions.addUser(user));
  }

  public update(id: number, request: UserUpdateRequest): Observable<{} | IUser> {
    return this.userService.update(id, request).pipe(map(response => {
        const user = User.parse(response);
        this.store.dispatch(UserActions.editUser(user));
        this.setInfo(this.EDIT_SUCCESS);
        return user;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public updateFromSocket(response: UserSocketResponse): void {
    const user = User.parseSocket(response);
    this.store.dispatch(UserActions.editUser(user));
  }

  public delete(id: number) {
    return this.userService.delete(id).pipe(map(response => {
        this.store.dispatch(UserActions.deleteUser(id));
        return response;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public deleteFromSocket(response: UserSocketResponse): void {
    const user = User.parseSocket(response);
    this.store.dispatch(UserActions.deleteUser(user.id));
  }

  public resendInvite(id: number) {
    return this.userService.resendInvite(id).pipe(map(response => {
        this.setInfo(this.REINVITE_SUCCESS);
        return response;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }
}
