import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { prepareAuthHeaders } from "@helpers/http";
import { CategoriesResponse } from "@responses/categories.response";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { CategoryResponse } from "@responses/category.response";

@Injectable()
export class CategoryService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public index(): Observable<CategoriesResponse> {
    const headers = prepareAuthHeaders();
    return this.http.get<CategoriesResponse>(`${environment.api}/categories`, {headers});
  }

  public upload(fd: FormData): Observable<CategoryResponse> {
    const headers = prepareAuthHeaders();
    return this.http.post<CategoryResponse>(`${environment.api}/categories`, fd, {headers});
  }

  public delete(id: number): Observable<boolean> {
    const headers = prepareAuthHeaders();
    return this.http.delete<boolean>(`${environment.api}/categories/${id}`, {headers});
  }
}
