import { JsonProperty, deserialize } from "json-typescript-mapper";
import { User } from "@models/user";
import { Scheme as IScheme } from "@models/interfaces/scheme.interface";
import { SchemeStep as ISchemeStep } from "@models/interfaces/scheme-step.interface";
import { SchemeResponse } from "@responses/scheme-response";
import { SchemeSocketResponse } from "@responses/scheme-socket.response";
import { SchemesResponse } from "@responses/schemes.response";

export const initialState: IScheme = {
  id: undefined,
  name: undefined,
  users: [],
  steps: []
};

export class Scheme implements IScheme {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("name")
  public name: string;
  @JsonProperty({ clazz: User, name: "users" })
  public users: User[];
  @JsonProperty("steps")
  public steps: ISchemeStep[];

  constructor(init?: Partial<IScheme>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, this.initialState(), init);
  }

  public static parse(data: SchemeResponse): Scheme {
    return deserialize(Scheme, data);
  }

  public static parseSocket(data: SchemeSocketResponse): Scheme {
    return deserialize(Scheme, data.workscheme);
  }

  public static parseMany(data: SchemesResponse): Scheme[] {
    return data.workschemes.map(workscheme => deserialize(Scheme, workscheme));
  }

  public initialState(): IScheme {
    return {...initialState, users: [], steps: []};
  }
}
