<div class="modal-header">
  <h4 class="modal-title">Nieuwe categorie</h4>
  <h3>Vul de onderstaande gegevens in</h3>
</div>

<div class="modal-body ">
  <div class="row my-2">
    <div class="col">
      <div class="form-group">
        <label for="categoryName">Naam categorie:</label>
        <input (keyup)="onCategoryName($event)" type="text" id="categoryName" placeholder="Naam categorie" class="form-control input-lg">
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary float-left" (click)="onUpload()" [disabled]="formInvalid()">Maak categorie</button>
  <a class="close-modal float-left" (click)="close()">Sluiten</a>
</div>
