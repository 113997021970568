import { type } from "../utils/utils";
import { Role as IRole } from "../../models/interfaces/role.interface";

export class RoleActions {
  static ADD_ROLES = type("ADD_ROLES");

  static addRoles(payload: IRole[]) {
    return {
      type: RoleActions.ADD_ROLES,
      payload
    };
  }
}
