import { Observable, of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { SchemeActions } from "../../states/scheme/scheme.actions";
import { SchemeService } from "../../services/scheme/scheme.service";
import { Scheme } from "../../models/scheme";
import { Scheme as IWorkscheme } from "../../models/interfaces/scheme.interface";
import * as SchemeSelectors from "../../states/scheme/scheme.selectors";
import { SchemeRequest } from "@requests/scheme.request";
import { HttpErrorResponse } from "@angular/common/http";
// import { SchemeRequest } from "../../../../shared/src/requests/scheme.request";
// import { HttpErrorResponse } from "@frontend/node_modules/@angular/common/http";
// import {SchemeRequest} from "@web/requests/scheme.request";

@Injectable()
export class SchemeRepository extends Repository {
  public schemes$: Observable<IWorkscheme[]> = this.store.select(SchemeSelectors.selectAll);
  public scheme$ = (id) => this.store.select(SchemeSelectors.selectById(id));

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private workschemeService: SchemeService
  ) {
    super(store, notificationActions);
  }

  public index(): Observable<{} | IWorkscheme[]> {
    return this.workschemeService.index().pipe(map(response => {
        const workschemes = Scheme.parseMany(response);
        this.store.dispatch(SchemeActions.addWorkschemes(workschemes));
        return workschemes;
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public update(id: number, request: SchemeRequest): Observable<{} | IWorkscheme[]> {
    return this.workschemeService.update(id, request).pipe(
      switchMap(() => {
        this.setInfo("Schema wordt op de achtergrond bijgewerkt");
        return this.index();
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public add(request: SchemeRequest): Observable<{} | IWorkscheme[]> {
    return this.workschemeService.add(request).pipe(
      switchMap(() => {
        this.setInfo("Schema wordt op de achtergrond aangemaakt en geplant");
        return this.index();
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public delete(id: number) {
    return this.workschemeService.delete(id).pipe(
      switchMap(() => {
        this.setInfo("Schema wordt op de achtergrond verwijderd.");
        return this.index();
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }
}
