import { type } from "../utils/utils";
import { Scheme } from "@models/scheme";
// import {Scheme} from "@web/models/interfaces/scheme.interface";
// import {IScheme} from "@web/models/interfaces";
// import { Scheme as IScheme } from "../../../../shared/src/models/interfaces/scheme.interface";

export class SchemeActions {
    static ADD_SCHEMES = type("ADD_SCHEMES");
    static ADD_SCHEME = type("ADD_SCHEME");

    static addWorkschemes(payload: Scheme[]) {
        return {
            type: SchemeActions.ADD_SCHEMES,
            payload
        };
    }

    static addWorkscheme(payload: Scheme) {
        return {
            type: SchemeActions.ADD_SCHEME,
            payload
        };
    }
}
