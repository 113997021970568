var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { deserialize, JsonProperty } from "json-typescript-mapper";
import { Daypart } from "@enums/daypart.enum";
import { EmployeeActivityType, EmployeeActivityTypeTranslated } from "@enums/employee-activity-type.enum";
import { EmployeeActivityTypeDetails, EmployeeActivityTypeDetailsTranslated } from "@enums/employee-activity-type-details.enum";
import { EmployeeActivityStatus } from "@enums/employee-activity-status.enum";
export var initialState = {
    id: undefined,
    draft: true,
    startDate: undefined,
    endDate: undefined,
    activityType: undefined,
    activityTypeDetails: undefined,
    userId: undefined,
    user: undefined,
    vehicleId: undefined,
    vehicle: undefined,
    status: undefined,
    handlingUserId: undefined,
    handlingUser: undefined,
    comment: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    activityDaypart: Daypart.Day
};
var EmployeeActivity = /** @class */ (function () {
    function EmployeeActivity(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    EmployeeActivity.parse = function (data) {
        return deserialize(EmployeeActivity, data);
    };
    EmployeeActivity.parseSocket = function (data) {
        return deserialize(EmployeeActivity, data.activity);
    };
    EmployeeActivity.parseMany = function (data) {
        return data.activities.map(function (employeeActivity) { return deserialize(EmployeeActivity, employeeActivity); });
    };
    Object.defineProperty(EmployeeActivity.prototype, "activityTypeDisplay", {
        /**
         * Get a human readable version for type
         */
        get: function () {
            return EmployeeActivityTypeTranslated[this.activityType];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeActivity.prototype, "activityTypeDetailsDisplay", {
        /**
         * Get a human readable version for typeDetails
         */
        get: function () {
            return EmployeeActivityTypeDetailsTranslated.get(this.activityTypeDetails);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeActivity.prototype, "activityDaypartDisplayShort", {
        /**
         * Get a short human readable version for activityDaypart
         */
        get: function () {
            switch (this.activityDaypart) {
                case Daypart.Day:
                    return "D";
                case Daypart.Night:
                    return "N";
                case Daypart.Both:
                    return "B";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeActivity.prototype, "activityDaypartDisplayLong", {
        /**
         * Get a long human readable version for activityDaypart
         */
        get: function () {
            switch (this.activityDaypart) {
                case Daypart.Day:
                    return "Dagdienst";
                case Daypart.Night:
                    return "Nachtdienst";
                case Daypart.Both:
                    return "Beide";
            }
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "id", void 0);
    __decorate([
        JsonProperty("draft"),
        __metadata("design:type", Boolean)
    ], EmployeeActivity.prototype, "draft", void 0);
    __decorate([
        JsonProperty("start_date"),
        __metadata("design:type", String)
    ], EmployeeActivity.prototype, "startDate", void 0);
    __decorate([
        JsonProperty("end_date"),
        __metadata("design:type", String)
    ], EmployeeActivity.prototype, "endDate", void 0);
    __decorate([
        JsonProperty("activity_type"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "activityType", void 0);
    __decorate([
        JsonProperty("activity_type_details"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "activityTypeDetails", void 0);
    __decorate([
        JsonProperty("user_id"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "userId", void 0);
    __decorate([
        JsonProperty("vehicle_id"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "vehicleId", void 0);
    __decorate([
        JsonProperty("handling_user_id"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "handlingUserId", void 0);
    __decorate([
        JsonProperty("comment"),
        __metadata("design:type", String)
    ], EmployeeActivity.prototype, "comment", void 0);
    __decorate([
        JsonProperty("status"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "status", void 0);
    __decorate([
        JsonProperty("created_at"),
        __metadata("design:type", String)
    ], EmployeeActivity.prototype, "createdAt", void 0);
    __decorate([
        JsonProperty("updated_at"),
        __metadata("design:type", String)
    ], EmployeeActivity.prototype, "updatedAt", void 0);
    __decorate([
        JsonProperty("activity_daypart"),
        __metadata("design:type", Number)
    ], EmployeeActivity.prototype, "activityDaypart", void 0);
    return EmployeeActivity;
}());
export { EmployeeActivity };
