var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthActions } from "./auth.actions";
export var initialState = {
    apiToken: undefined,
    socketToken: undefined,
    isAuthenticated: false,
    user: undefined
};
export function AuthReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActions.SET_AUTHENTICATION:
            return {
                apiToken: action.payload.apiToken,
                isAuthenticated: true,
                user: action.payload.user
            };
        case AuthActions.CLEAR_AUTHENTICATION:
            return initialState;
        case AuthActions.SET_SOCKET_TOKEN:
            return __assign({}, state, { socketToken: action.payload });
        default:
            return state;
    }
}
