var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { SchemeActions } from "../../states/scheme/scheme.actions";
import { SchemeService } from "../../services/scheme/scheme.service";
import { Scheme } from "../../models/scheme";
import * as SchemeSelectors from "../../states/scheme/scheme.selectors";
// import { SchemeRequest } from "../../../../shared/src/requests/scheme.request";
// import { HttpErrorResponse } from "@frontend/node_modules/@angular/common/http";
// import {SchemeRequest} from "@web/requests/scheme.request";
var SchemeRepository = /** @class */ (function (_super) {
    __extends(SchemeRepository, _super);
    function SchemeRepository(store, notificationActions, workschemeService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.workschemeService = workschemeService;
        _this.schemes$ = _this.store.select(SchemeSelectors.selectAll);
        _this.scheme$ = function (id) { return _this.store.select(SchemeSelectors.selectById(id)); };
        return _this;
    }
    SchemeRepository.prototype.index = function () {
        var _this = this;
        return this.workschemeService.index().pipe(map(function (response) {
            var workschemes = Scheme.parseMany(response);
            _this.store.dispatch(SchemeActions.addWorkschemes(workschemes));
            return workschemes;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    SchemeRepository.prototype.update = function (id, request) {
        var _this = this;
        return this.workschemeService.update(id, request).pipe(switchMap(function () {
            _this.setInfo("Schema wordt op de achtergrond bijgewerkt");
            return _this.index();
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    SchemeRepository.prototype.add = function (request) {
        var _this = this;
        return this.workschemeService.add(request).pipe(switchMap(function () {
            _this.setInfo("Schema wordt op de achtergrond aangemaakt en geplant");
            return _this.index();
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    SchemeRepository.prototype.delete = function (id) {
        var _this = this;
        return this.workschemeService.delete(id).pipe(switchMap(function () {
            _this.setInfo("Schema wordt op de achtergrond verwijderd.");
            return _this.index();
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    return SchemeRepository;
}(Repository));
export { SchemeRepository };
