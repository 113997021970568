import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { ModalModule } from "ngx-bootstrap/modal";
import { AppComponent } from "./app.component";
import { AppShared } from "./app.shared";
import { ComponentsModule } from "src/app/components/components.module";
import { GuardsModule } from "src/app/guards/guards.module";
import { AppRoutingModule } from "./app-routing.module";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { MultipleChoiceDialogComponent } from "src/app/components/multiple-choice-dialog/multiple-choice-dialog.component";
import { ServicesModule, ServicesModule as WebServicesModule } from "@services/services.module";
import { StatesModule } from "@states/states.module";
import { RepositoriesModule } from "@repositories/repositories.module";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    MultipleChoiceDialogComponent,
  ],
  imports: [
      // IS ER UITGEHAALD, MOET DIT ERIN?
    // SharedModule.forRoot((<any>window).__env),
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production
    }),
    ComponentsModule,
    GuardsModule,
    StatesModule,
    ServicesModule,
    RepositoriesModule,
    RouterModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    WebServicesModule,
  ],
  providers: [
    AppShared,

  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    MultipleChoiceDialogComponent,
  ]
})
export class AppModule {
}
