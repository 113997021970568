import { Message as IMessage } from "./interfaces/message.interface";
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { MessageResponse } from "@responses/message.response";
import { MessagesResponse } from "@responses/messages.response";
import { MessageSocketResponse } from "@responses/message-socket.response";

export const initialState: IMessage = {
  id: undefined,
  title: undefined,
  content: undefined,
  sendAt: undefined,
  userId: undefined,
  confirmed: undefined
};

export class Message implements IMessage {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("title")
  public title: string;
  @JsonProperty("content")
  public content: string;
  @JsonProperty("send_at")
  public sendAt: string;
  @JsonProperty("user_id")
  public userId: number;
  @JsonProperty("confirmed")
  public confirmed: boolean;

  constructor(init?: Partial<IMessage>) {
    Object.assign(this, initialState, init);
  }

  public static parse(data: MessageResponse): Message {
    return deserialize(Message, data);
  }

  public static parseSocket(data: MessageSocketResponse): Message {
    return deserialize(Message, data.message);
  }

  public static parseMany(data: MessagesResponse): Message[] {
    return data.messages.map(message => deserialize(Message, message));
  }
}
