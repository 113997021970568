var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AuthActions } from "../../states/auth/auth.actions";
import { AuthService } from "../../services/auth/auth.service";
import { Repository } from "../repository.abstract";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Auth } from "../../models/auth";
import { ForgotPassword } from "../../models/forgot-password";
import { ResetPassword } from "../../models/reset-password";
var AuthRepository = /** @class */ (function (_super) {
    __extends(AuthRepository, _super);
    function AuthRepository(store, notificationActions, authActions, authService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.authActions = authActions;
        _this.authService = authService;
        _this.auth$ = _this.store.select(function (s) { return s.auth; });
        // TODO: Create this as key,value and translator options
        _this.FORGOT_PASSWORD_SUCCESS = "Een e-mail met verdere instructies is verstuurd!";
        _this.LOGOUT_SUCCESS = "U bent succesvol uitgelogd!";
        _this.RESET_PASSWORD_SUCCESS = "Wachtwoord gewijzigd!";
        _this.ACTIVATE_ACCOUNT_SUCCESS = "Account geactiveerd!";
        return _this;
    }
    AuthRepository.prototype.validateLogin = function () {
        return this.authService.me().pipe(map(function () {
            return true;
        }), catchError(function () {
            return observableOf(false);
        }));
    };
    AuthRepository.prototype.login = function (request) {
        var _this = this;
        return this.authService.login(request).pipe(map(function (response) {
            var auth = Auth.parse(response);
            _this.store.dispatch(AuthActions.setAuthentication(auth));
            return auth;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    AuthRepository.prototype.setSocketToken = function (token) {
        this.store.dispatch(AuthActions.setSocketToken(token));
    };
    AuthRepository.prototype.logout = function () {
        var _this = this;
        return this.authService.logout().pipe(map(function (response) {
            _this.store.dispatch(AuthActions.clearAuthentication());
            _this.setInfo(_this.LOGOUT_SUCCESS);
            return response;
        }), catchError(function (response) {
            _this.store.dispatch(AuthActions.clearAuthentication());
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    AuthRepository.prototype.forceLogout = function (silent) {
        var _this = this;
        this.store.dispatch(AuthActions.clearAuthentication());
        return this.authService.logout().pipe(map(function (response) {
            if (!silent) {
                _this.setInfo(_this.LOGOUT_SUCCESS);
            }
            return response;
        }), catchError(function (response) {
            if (!silent) {
                _this.setError(JSON.stringify(response.error));
            }
            return observableOf(undefined);
        }));
    };
    AuthRepository.prototype.forgotPassword = function (request) {
        var _this = this;
        return this.authService.forgotPassword(request).pipe(map(function (response) {
            var forgotPassword = ForgotPassword.parse(response);
            _this.setInfo(_this.FORGOT_PASSWORD_SUCCESS);
            return forgotPassword;
        }), catchError(function (response) {
            var forgotPassword = ForgotPassword.parse(response.error);
            _this.setError(forgotPassword.message);
            return forgotPassword.success ? observableOf(forgotPassword) : observableOf(undefined);
        }));
    };
    AuthRepository.prototype.resetPassword = function (request) {
        var _this = this;
        return this.authService.resetPassword(request).pipe(map(function (response) {
            var resetPassword = ResetPassword.parse(response);
            _this.setInfo(_this.RESET_PASSWORD_SUCCESS);
            _this.store.dispatch(AuthActions.setAuthentication(resetPassword));
            return resetPassword;
        }), catchError(function (response) {
            var resetPassword = ResetPassword.parse(response);
            _this.setError(resetPassword.message);
            return resetPassword.success ? observableOf(resetPassword) : observableOf(undefined);
        }));
    };
    AuthRepository.prototype.activateAccount = function (request) {
        var _this = this;
        return this.authService.activateAccount(request).pipe(map(function (response) {
            var resetPassword = ResetPassword.parse(response);
            _this.setInfo(_this.ACTIVATE_ACCOUNT_SUCCESS);
            _this.store.dispatch(AuthActions.setAuthentication(resetPassword));
            return resetPassword;
        }), catchError(function (response) {
            var resetPassword = ResetPassword.parse(response);
            _this.setError(resetPassword.message);
            return resetPassword.success ? observableOf(resetPassword) : observableOf(undefined);
        }));
    };
    return AuthRepository;
}(Repository));
export { AuthRepository };
