var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
export var initialState = {
    id: undefined,
    vehicleNumber: undefined,
    description: undefined,
    label: undefined,
    deleted: undefined,
    drivers: undefined,
    files: undefined,
};
var Vehicle = /** @class */ (function () {
    function Vehicle(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    Vehicle.parse = function (data) {
        return deserialize(Vehicle, data);
    };
    Vehicle.parseSocket = function (data) {
        return deserialize(Vehicle, data.vehicle);
    };
    Vehicle.parseMany = function (data) {
        return data.vehicles.map(function (vehicle) { return deserialize(Vehicle, vehicle); });
    };
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], Vehicle.prototype, "id", void 0);
    __decorate([
        JsonProperty("vehicle_number"),
        __metadata("design:type", String)
    ], Vehicle.prototype, "vehicleNumber", void 0);
    __decorate([
        JsonProperty("description"),
        __metadata("design:type", String)
    ], Vehicle.prototype, "description", void 0);
    __decorate([
        JsonProperty("label"),
        __metadata("design:type", String)
    ], Vehicle.prototype, "label", void 0);
    __decorate([
        JsonProperty("deleted"),
        __metadata("design:type", Boolean)
    ], Vehicle.prototype, "deleted", void 0);
    return Vehicle;
}());
export { Vehicle };
