import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {
  }

  /**
   *
   * @param request
   * @param next
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map(response => {
        return response;
      }), catchError(response => {
        if (response instanceof HttpErrorResponse) {
          // TODO: Somehow our unauthorized errors are status 0
          if (response.status === 401) {
            this.router.navigate(["/login"]);
          }
        }

        return observableThrowError(response);
      })
    );
  }
}
