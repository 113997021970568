/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-table-viewer-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./file-table-viewer-modal.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "../../services/file/file.service";
var styles_FileTableViewerModalComponent = [i0.styles];
var RenderType_FileTableViewerModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileTableViewerModalComponent, data: {} });
export { RenderType_FileTableViewerModalComponent as RenderType_FileTableViewerModalComponent };
function View_FileTableViewerModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "td", [["class", "icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "ion-ios-download downloadIcon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "ion-ios-trash icons deleteIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_FileTableViewerModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bestanden "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Overzicht van de bestanden"])), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "table", [["class", "table table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Naam bestand"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileTableViewerModalComponent_1)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "close-modal float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sluiten"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 15, 0, currVal_0); }, null); }
export function View_FileTableViewerModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-table-viewer-modal", [], null, null, null, View_FileTableViewerModalComponent_0, RenderType_FileTableViewerModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.FileTableViewerModalComponent, [i4.BsModalRef, i5.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileTableViewerModalComponentNgFactory = i1.ɵccf("app-file-table-viewer-modal", i3.FileTableViewerModalComponent, View_FileTableViewerModalComponent_Host_0, {}, {}, []);
export { FileTableViewerModalComponentNgFactory as FileTableViewerModalComponentNgFactory };
