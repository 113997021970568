import { JsonProperty } from "json-typescript-mapper";
import { Rule as IRule } from "./interfaces/rule.interface";

export const initialState: IRule = {
  id: undefined,
  name: undefined,
};

export class Rule implements IRule {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("name")
  public name: string;

  constructor(init?: Partial<IRule>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }
}
