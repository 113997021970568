import "moment/locale/nl";

import * as moment from "moment";

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, Renderer2, ViewContainerRef } from "@angular/core";

import { RangePickerComponentConfig } from "./range-picker-component-config.interface";

moment.locale("nl");

@Component({
    selector: "app-rangepicker",
    templateUrl: "./rangepicker.component.html",
    styleUrls: ["./rangepicker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RangePickerComponent implements AfterViewInit {

    public startDate = undefined;
    public endDate = undefined;

    public config: RangePickerComponentConfig = {
        parentOffsetLeft: 0,
        parentOffsetTop: 0,
        parentWidth: 0,
        parentHeight: 0,
        cssClass: "",
    };

    public months: moment.Moment[] = [moment(this.startDate)];

    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    public getObjectKeys = (data: object) => Object.keys(data);
    public isSameMonth = (first: moment.Moment, second: moment.Moment) => first.month() === second.month();
    private clicked = false;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private viewContainerRef: ViewContainerRef,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngAfterViewInit() {
        this.setLocation();
        this.executeConfig();
    }

    public setLocation() {
        // Set position
        const componentWidth = this.el.nativeElement.clientWidth;
        const componentOffsetLeft = (this.config.parentOffsetLeft + (this.config.parentWidth / 2)) - (componentWidth / 2);
        const componentOffsetTop = (this.config.parentOffsetTop + this.config.parentHeight + this.config.userConfig.OFFSET_TOP);

        this.renderer.setStyle(this.el.nativeElement, "left", componentOffsetLeft + "px");
        this.renderer.setStyle(this.el.nativeElement, "top", componentOffsetTop + "px");
    }

    public executeConfig() {
        // Set months
        this.months = Array(this.config.userConfig.NUMBER_OF_MONTHS).fill(undefined).map((month: moment.Moment, index: number) => {
            return moment(this.startDate).add(index, "month");
        });

        this.changeDetectorRef.detectChanges();
    }

    isDisabled(date: moment.Moment): { class: string } | undefined {
        return (this.config.disabledDates && this.config.disabledDates[date.format("YYYY-MM-DD")]) ? this.config.disabledDates[date.format("YYYY-MM-DD")] : undefined;
    }


    public dayClass(date: moment.Moment, month: moment.Moment) {
        const cssClasses = {
            "different-month": !this.isSameMonth(month, date),
            "is-selected": (this.startDate && date.isSame(this.startDate, "day")) || (this.endDate && date.isSame(this.endDate, "day")),
            "is-range": (this.startDate && date.isSameOrAfter(this.startDate)) && (this.endDate && date.isSameOrBefore(this.endDate))
        };
        // Check if date is disabled
        const isCurrentDisabled = this.isDisabled(date);

        if (isCurrentDisabled) {
            cssClasses["is-disabled"] = true;
            if (isCurrentDisabled.class) {
                cssClasses[isCurrentDisabled.class] = true;
            }
        }

        return cssClasses;
    }

    public previousMonth(): void {
        this.months.forEach((month) => month.subtract(1, "month"));
    }

    public nextMonth(): void {
        this.months.forEach((month) => month.add(1, "month"));
    }

    /**
     * Calculate weekdays for given start date and week offset
     */
    public getWeek(startDate: moment.Moment, weekOffset = 0): { [key: number]: { date: moment.Moment, day: string }[] } {
        const date = startDate.clone().add(weekOffset, "week").startOf("isoWeek");

        const currentWeekNumber = date.startOf("isoWeek").isoWeek();
        const data: { [key: number]: { date: moment.Moment, day: string }[] } = {};
        data[currentWeekNumber] = [];

        // Loop for 7 days
        for (let i = 0; i < 7; i++) {
            // console.log(i - 1);
            const currentDay = date.clone().add(i, "day");
            data[currentWeekNumber].push({ date: currentDay, day: currentDay.format("DD") });
        }

        return data;
    }

    public getMonth(date: moment.Moment) {
        const startDay = date.clone().startOf("month").startOf("week");
        const calendar = [];



        for (let i = 0; i < 6; i++) {
            calendar.push(this.getWeek(startDay, i));
        }

        console.log(calendar);

        return calendar;
    }

    public clickDay(day: moment.Moment) {
        const clickedDay = day.clone();

        // First click
        if (!this.clicked) {
            this.startDate = clickedDay;

            if (clickedDay.isAfter(this.endDate)) {
                this.endDate = clickedDay;
            }
        } else {
            this.endDate = clickedDay.isBefore(this.startDate) ? this.startDate : clickedDay;
            this.hide();
        }

        this.clicked = !this.clicked;
        this.onChange.emit([this.startDate, this.endDate]);
    }

    public hide() {
        this.renderer.setStyle(this.el.nativeElement, "display", "none");
    }

}
