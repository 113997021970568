import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthRepository } from "@repositories/auth/auth.repository";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authRepository: AuthRepository
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authRepository.auth$.pipe(map(auth => {
      if (auth.isAuthenticated) {
        // Logged in
        return true;
      }

      // TODO: Implement returnUrl
      this.router.navigate([""]);
      return false;
    }));
  }
}
