import { type } from "../utils/utils";
import { Dashboard } from "../../models/interfaces/dashboard.interface";

export class DashboardActions {
  static ADD_DASHBOARD = type("ADD_DASHBOARD");

  static addDashboard(payload: Dashboard) {
    return {
      type: DashboardActions.ADD_DASHBOARD,
      payload
    };
  }
}
