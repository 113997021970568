var _a;
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { userAdapter } from "./user.adapter";
import { getVehicleState } from "../vehicle/vehicle.selectors";
import { User } from "@models/user";
import { Vehicle } from "@models/vehicle";
export var getUserState = createFeatureSelector("users");
export var selectIds = (_a = userAdapter.getSelectors(getUserState), _a.selectIds), selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var selectById = function (id) { return createSelector(getUserState, function (state) { return state.entities[id]; }); };
export var planneableUsers = function () { return createSelector(getUserState, getVehicleState, function (state, vehicles) {
    return state.ids.reduce(function (result, id) {
        var user = new User(state.entities[id]);
        if (user.isPlannable) {
            if (user.vehicleId && vehicles.entities[user.vehicleId]) {
                user.vehicle = new Vehicle(vehicles.entities[user.vehicleId]);
            }
            result.push(user);
        }
        return result;
    }, []);
}); };
