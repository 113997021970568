import { BsDatepickerModule, BsModalRef } from "ngx-bootstrap";
import { CategoryCreateModalComponent } from "src/app/components/category-create-modal/category-create-modal.component";
import { CommonModule } from "@angular/common";
import { DeleteCategoryModalComponent } from "./delete-category-modal/delete-category-modal.component";
import { DirectivesModule } from "src/app/directives/directives.module";
import { FileTableComponent } from "src/app/components/file-table/file-table.component";
import { FileTableViewerModalComponent } from "./file-table-viewer-modal/file-table-viewer-modal.component";
import { FileUploadModalComponent } from "./file-upload-modal/file-upload-modal.component";
import { FilesCounterPipe } from "src/app/files-counter.pipe";
import { FooterComponent } from "src/app/components/footer/footer.component";
import { GeneralFileUploadModalComponentComponent } from "src/app/components/general-file-upload-modal-component/general-file-upload-modal-component.component";
import { LoaderComponent } from "src/app/components/loader/loader.component";
import { MessageItemComponent } from "src/app/components/notifications/message-item/message-item.component";
import { NavBarComponent } from "src/app/components/navbar/navbar.component";
import { NgModule } from "@angular/core";
import { NotificationItemComponent } from "src/app/components/notifications/notification-item/notification-item.component";
import { NotificationsComponent } from "src/app/components/notifications/notifications.component";
import { RouterModule } from "@angular/router";
import { TopBarComponent } from "src/app/components/topbar/topbar.component";
import { WeekScrollerComponent } from "./week-scoller/week-scroller.component";
import { defineLocale } from "ngx-bootstrap/chronos";
import { nlLocale } from "ngx-bootstrap/locale";
import { ServicesModule } from "@services/services.module";
import { ReactiveFormsModule } from "@angular/forms";

defineLocale("nl", nlLocale);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DirectivesModule,
    ServicesModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    NavBarComponent,
    TopBarComponent,
    FooterComponent,
    NotificationsComponent,
    NotificationItemComponent,
    MessageItemComponent,
    LoaderComponent,
    FileTableComponent,
    FileUploadModalComponent,
    FileTableViewerModalComponent,
    FilesCounterPipe,
    GeneralFileUploadModalComponentComponent,
    CategoryCreateModalComponent,
    DeleteCategoryModalComponent,
    WeekScrollerComponent,
  ],
  exports: [
    NavBarComponent,
    TopBarComponent,
    FooterComponent,
    NotificationsComponent,
    NotificationItemComponent,
    MessageItemComponent,
    LoaderComponent,
    FileTableComponent,
    FileUploadModalComponent,
    WeekScrollerComponent,
  ],
  entryComponents: [
    FileUploadModalComponent,
    FileTableViewerModalComponent,
    GeneralFileUploadModalComponentComponent,
    CategoryCreateModalComponent,
    DeleteCategoryModalComponent
  ],
  providers: [
    BsModalRef,
  ]
})
export class ComponentsModule { }
