import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EmployeeActivity } from "@models/employee-activity";
import { Message } from "@models/message";


@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit {
  // Readonly fields
  public readonly maxPageSize = 5;
  // Inputs & Outputs
  @Input("activities") pendingActivities: EmployeeActivity[] | Message[] = [];
  @Output("acceptActivity") accepted = new EventEmitter<EmployeeActivity>();
  @Output("declineActivity") declined = new EventEmitter<EmployeeActivity>();
  @Output("completed") completed = new EventEmitter<Message>();
  // Observables
  // Public properties
  public currentPage = 1;
  // Private fields

  constructor() { }

  ngOnInit() { }

  /**
   * Event handler for accept. Fires the accepted eventEmitter
   * @param activity
   */
  public onAccept(activity: EmployeeActivity): void {
    this.accepted.emit(activity);
  }

  /**
   * Event handler for decline. Fires the declined eventEmitter
   * @param activity
   */
  public onDecline(activity: EmployeeActivity): void {
    this.declined.emit(activity);
  }

  /**
   * Event handler for marking message as completed.
   * @param message
   */
  public markCompleted(message: Message): void {
    this.completed.emit(message);
  }

  public nextPage() {
    this.currentPage++;
  }

  public prevPage() {
    this.currentPage--;
  }

  public shouldShow(i) {
    const current = i;
    const startRange = (this.currentPage * this.maxPageSize) - this.maxPageSize;
    const endRange = (this.currentPage * this.maxPageSize) - 1;
    return current >= startRange && current <= endRange;
  }

  public shouldPaginate() {
    return this.pendingActivities.length > this.maxPageSize;
  }

  public isEmployeeActivity(activity: EmployeeActivity | Message): boolean {
    return activity instanceof EmployeeActivity;
  }

  public isMessage(activity: EmployeeActivity | Message): boolean {
    return activity instanceof Message;
  }

  public canAcceptAndDecline(activity: EmployeeActivity | Message) {
    return true;
  }
}
