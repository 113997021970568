/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/navbar/navbar.component.ngfactory";
import * as i3 from "./components/navbar/navbar.component";
import * as i4 from "./components/topbar/topbar.component.ngfactory";
import * as i5 from "./components/topbar/topbar.component";
import * as i6 from "./repositories/auth/auth.repository";
import * as i7 from "./repositories/employee-activity/employee-activity.repository";
import * as i8 from "./repositories/message/message.repository";
import * as i9 from "@angular/router";
import * as i10 from "./services/week-state/week-state.service";
import * as i11 from "@angular/common";
import * as i12 from "./components/loader/loader.component.ngfactory";
import * as i13 from "./components/loader/loader.component";
import * as i14 from "./components/footer/footer.component.ngfactory";
import * as i15 from "./components/footer/footer.component";
import * as i16 from "./app.component";
import * as i17 from "./repositories/notification/notification.repository";
import * as i18 from "ngx-toastr";
import * as i19 from "./repositories/vehicle-activity/vehicle-activity.repository";
import * as i20 from "./repositories/user/user.repository";
import * as i21 from "./repositories/vehicle/vehicle.repository";
import * as i22 from "./repositories/role/role.repository";
import * as i23 from "./services/websocket/websocket.service";
import * as i24 from "./repositories/status/status.repository";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-nav-bar", [], null, null, null, i2.View_NavBarComponent_0, i2.RenderType_NavBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.NavBarComponent, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sidebarIsClosed; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-top-bar", [], null, [[null, "sidebarToggleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sidebarToggleChange" === en)) {
        var pd_0 = ((_co.sidebarIsClosed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TopBarComponent_0, i4.RenderType_TopBarComponent)), i1.ɵdid(1, 573440, null, 0, i5.TopBarComponent, [i6.AuthRepository, i7.EmployeeActivityRepository, i8.MessageRepository, i9.Router, i10.WeekStateService], { pendingActivities: [0, "pendingActivities"], sidebarToggle: [1, "sidebarToggle"] }, { sidebarToggleChange: "sidebarToggleChange" }), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.pendingActivities$)); var currVal_1 = _co.sidebarIsClosed; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row sidebar-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i11.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "router-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(12, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isAuthenticated$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.isAuthenticated$)) ? "sidebar-main" : "col"); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.isAuthenticated$)); _ck(_v, 8, 0, currVal_2); _ck(_v, 12, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i12.View_LoaderComponent_0, i12.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i13.LoaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(4, 49152, null, 0, i15.FooterComponent, [], null, null)], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_AppComponent_4)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.loaded$)) || !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(_co.isAuthenticated$))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.loaded$)) && i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(_co.isAuthenticated$))); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i16.AppComponent, [i6.AuthRepository, i17.NotificationRepository, i18.ToastrService, i7.EmployeeActivityRepository, i19.VehicleActivityRepository, i20.UserRepository, i21.VehicleRepository, i22.RoleRepository, i8.MessageRepository, i23.WebsocketService, i24.StatusRepository, i9.Router, i10.WeekStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
