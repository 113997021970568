import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DashboardResponse } from "@responses/dashboard.response";
import { environment } from "../../../environments/environment";
import { prepareAuthHeaders } from "@helpers/http";
import * as moment from "moment";

@Injectable()
export class DashboardService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getTotals(startDate, endDate): Observable<DashboardResponse> {
    const headers = prepareAuthHeaders();
    startDate = startDate.format("YYYY-MM-DD HH:mm:ss");
    endDate = endDate.format("YYYY-MM-DD HH:mm:ss");
    return this.http.get<DashboardResponse>(`${environment.api}/dashboard/${startDate}/${endDate}`, {headers});
  }
}
