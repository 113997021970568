<div class="notification-item-wrapper border-top">
  <div class="row m-0 py-2">
    <div class="col text-left">
      <p class="m-0">{{message.title}}</p>
      <p class="m-0 font-weight-bold">{{message.content}}</p>
      <p class="m-0 font-weight-bold">{{ moment(message.sendAt).format(dateFormat) }}</p>
    </div>
    <div class="col-1 text-right buttons mr-2">
      <i class="ion-md-checkmark text-primary" (click)="markCompleted()"></i>
    </div>
  </div>
</div>
