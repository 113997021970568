import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CategoryService } from "@services/category/category.service";

@Component({
  selector: "app-category-create-modal",
  templateUrl: "./category-create-modal.component.html",
  styleUrls: ["./category-create-modal.component.scss"]
})
export class CategoryCreateModalComponent {
  public fd: FormData;
  public categoryName: string;

  constructor(
    private bsModalRef: BsModalRef,
    private categoryService: CategoryService,
  ) { }

  public close(): void {
    this.bsModalRef.hide();
  }

  public formInvalid(): any {
    return !this.categoryName;
  }

  public onCategoryName(event): void {
    this.categoryName = event.target.value;
    this.completeFormData();
  }

  private completeFormData(): void {
    this.fd = new FormData();
    this.fd.append("category_name", this.categoryName);
  }

  public onUpload(): void {
    this.categoryService.upload(this.fd)
      .subscribe(res => {
        this.close();
      });
  }
}
