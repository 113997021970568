var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import * as UserSelectors from "../user/user.selectors";
import * as VehicleSelectors from "../vehicle/vehicle.selectors";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Daypart } from "@enums/daypart.enum";
import { EmployeeActivity } from "@models/employee-activity";
import { EmployeeActivityStatus } from "@enums/employee-activity-status.enum";
import { EmployeeActivityType } from "@enums/employee-activity-type.enum";
import { Resource } from "@dtos/resource.dto";
import { Sortable } from "@enums/sortable.enum";
import { Sorting } from "@enums/sorting.enum";
import { User } from "@models/user";
import { Vehicle } from "@models/vehicle";
import { employeeActivityAdapter } from "./employee-activity.adapter";
export var getEmployeeActivityState = createFeatureSelector("employeeActivities");
export var getEmployeeActivitySortState = createSelector(getEmployeeActivityState, function (state) {
    return { sort: state.sort, type: state.sortType };
});
export var selectIds = (_a = employeeActivityAdapter.getSelectors(getEmployeeActivityState), _a.selectIds), selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var selectAllMapped = function () {
    return createSelector(selectAll, UserSelectors.getUserState, VehicleSelectors.getVehicleState, function (employeeActivities, userState, vehicleState) {
        return employeeActivities.map(function (activity) {
            var employeeActivity = new EmployeeActivity(activity);
            employeeActivity.user = new User(userState.entities[employeeActivity.userId]);
            employeeActivity.vehicle = employeeActivity.vehicleId
                ? new Vehicle(vehicleState.entities[employeeActivity.vehicleId])
                : undefined;
            return employeeActivity;
        });
    });
};
export var selectNotificationEmployeeActivites = function () {
    return createSelector(selectAllMapped(), function (employeeActivities) {
        var notifications = [];
        var length = employeeActivities.length;
        for (var i = 0; i < length; i++) {
            var employeeActivity = new EmployeeActivity(employeeActivities[i]);
            if (employeeActivity.activityType ===
                EmployeeActivityType.Unavailable &&
                employeeActivity.status === EmployeeActivityStatus.Pending) {
                notifications.push(employeeActivity);
            }
        }
        return notifications;
    });
};
export var getResources = function () {
    return createSelector(UserSelectors.planneableUsers(), getEmployeeActivityState, getEmployeeActivitySortState, function (users, employeeActivityState, sorting) {
        var resources = [];
        users.sort(function (a, b) {
            var aSort;
            var bSort;
            if (sorting.type === Sortable.user) {
                aSort = a.lastName.toUpperCase();
                bSort = b.lastName.toUpperCase();
            }
            if (sorting.type === Sortable.vehicle_label) {
                aSort = a.vehicle ? a.vehicle.label.toUpperCase() : "";
                bSort = b.vehicle ? b.vehicle.label.toUpperCase() : "";
            }
            var multiplier = sorting.sort === Sorting.asc ? 1 : -1;
            if (aSort < bSort) {
                return -1 * multiplier;
            }
            if (aSort > bSort) {
                return 1 * multiplier;
            }
            return 0;
        });
        var length = users.length;
        var _loop_1 = function (i) {
            var employeeActivityResourceDay = new Resource();
            var user = users[i];
            if (user.isActive) {
                employeeActivityResourceDay.id = user.id;
                employeeActivityResourceDay.daypart = Daypart.Day;
                resources.push({
                    id: employeeActivityResourceDay.resource,
                    name: user.fullName + " " + (user.vehicle ? user.vehicle.label : "") + " ",
                    columns: [
                        { html: user.fullName, },
                        { html: "D" },
                        { html: user.vehicle ? user.vehicle.label : "" },
                    ],
                });
                var activities = employeeActivityState.ids.map(function (x) { return employeeActivityState.entities[x]; });
                var employeeActivityResourceNight = new Resource();
                employeeActivityResourceNight.id = user.id;
                employeeActivityResourceNight.daypart = Daypart.Night;
                resources.push({
                    id: employeeActivityResourceNight.resource,
                    name: user.fullName + " " + (user.vehicle ? user.vehicle.label : "") + " ",
                    columns: [{ html: "N" }, { html: "", },],
                    events: activities.filter(function (x) { return x.userId === user.id; }),
                });
            }
        };
        for (var i = 0; i < length; i++) {
            _loop_1(i);
        }
        return resources;
    });
};
export var getEvents = function () {
    return createSelector(VehicleSelectors.getVehicleState, UserSelectors.getUserState, getEmployeeActivityState, function (vehicleState, userState, employeeActivityState) {
        var events = [];
        var employeeActivityKeys = Object.keys(employeeActivityState.entities);
        var employeeActivityKeysLength = employeeActivityKeys.length;
        for (var i = 0; i < employeeActivityKeysLength; i++) {
            var employeeActivity = employeeActivityState.entities[employeeActivityKeys[i]];
            if (employeeActivity &&
                userState.entities[employeeActivity.userId].isActive === true) {
                employeeActivity.vehicle =
                    vehicleState.entities[employeeActivity.vehicleId];
                employeeActivity.user = new User(userState.entities[employeeActivity.userId]);
                if (employeeActivity.activityDaypart === Daypart.Both) {
                    events.push(createEmployeeEvent(__assign({}, employeeActivity, { activityDaypart: Daypart.Day }), Daypart.Both));
                    events.push(createEmployeeEvent(__assign({}, employeeActivity, { activityDaypart: Daypart.Night }), Daypart.Both));
                }
                else {
                    events.push(createEmployeeEvent(employeeActivity));
                }
            }
        }
        return events;
    });
};
function getActivityResourceText(activity) {
    switch (activity.activityType) {
        case +EmployeeActivityType.Work:
            return activity.vehicle.description;
        case +EmployeeActivityType.Unavailable:
            return activity.activityTypeDetails !== undefined
                ? activity.activityTypeDetailsDisplay
                : activity.activityTypeDisplay;
        case +EmployeeActivityType.Available:
            return "Beschikbaar";
        default:
            return "Onbekend";
    }
}
export var createEmployeeEvent = function (activity, overwriteResourceDaypart) {
    if (overwriteResourceDaypart === void 0) { overwriteResourceDaypart = undefined; }
    var employeeActivity = new EmployeeActivity(activity);
    var html = '<span class="description">';
    html += getActivityResourceText(employeeActivity);
    html += "</span>";
    var activityResource = new Resource(employeeActivity, "userId");
    return {
        id: employeeActivity.id + "-" + activity.activityDaypart,
        bubbleHtml: activity.comment,
        resource: activityResource.resource,
        vehicle: employeeActivity.vehicle,
        start: employeeActivity.startDate,
        end: employeeActivity.endDate,
        html: html,
        employeeActivity: overwriteResourceDaypart !== undefined
            ? __assign({}, employeeActivity, { activityDaypart: Daypart.Both }) : employeeActivity,
        height: 20,
        moveVDisabled: employeeActivity.activityType !== EmployeeActivityType.Work,
        moveHDisabled: false,
        resizeDisabled: false,
        type: "employee",
    };
};
