import * as UserSelectors from "../user/user.selectors";

import { createFeatureSelector, createSelector } from "@ngrx/store";

import { Daypart } from "@models/enums/daypart.enum";
import { EmployeeActivityState } from "../employee-activity/employee-activity.state";
import { EmployeeActivity as IEmployeeActivity } from "@interfaces/employee-activity.interface";
import { VehicleActivity as IVehicleActivity } from "@interfaces/vehicle-activity.interface";
import { Resource } from "@dtos/resource.dto";
import { Sorting } from "@enums/sorting.enum";
import { StatusState } from "../status/status.state";
import { User } from "@models/user";
import { UserState } from "../user/user.state";
import { Variables } from "@app/shared.variables";
import { Vehicle } from "@models/vehicle";
import { VehicleActivityState } from "./vehicle-activity.state";
import { VehicleState } from "../vehicle/vehicle.state";
import { getEmployeeActivityState } from "../employee-activity/employee-activity.selectors";
import { getStatusState } from "../status/status.selectors";
import { getVehicleState } from "../vehicle/vehicle.selectors";
import { vehicleActivityAdapter } from "./vehicle-activity.adapter";

export const getVehicleActivityState = createFeatureSelector<
    VehicleActivityState
>("vehicleActivities");
export const getVehicleActivitySortState = createSelector(
    getVehicleActivityState,
    (state: VehicleActivityState) => state.sort
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = vehicleActivityAdapter.getSelectors(getVehicleActivityState);

export const getResources = () =>
    createSelector(
        getVehicleState,
        getVehicleActivitySortState,
        (vehicleState: VehicleState, sorting: Sorting) => {
            const resources: {}[] = [];
            const keys = Object.keys(vehicleState.entities).sort((a, b) => {
                const aName = vehicleState.entities[
                    a
                ].vehicleNumber.toUpperCase();
                const bName = vehicleState.entities[
                    b
                ].vehicleNumber.toUpperCase();
                const multiplier = sorting === Sorting.asc ? 1 : -1;
                if (aName < bName) return -1 * multiplier;
                if (aName > bName) return 1 * multiplier;
                return 0;
            });
            const keysLength = keys.length;
            for (let i = 0; i < keysLength; i++) {
                const vehicle: Vehicle = vehicleState.entities[keys[i]];

                const vehicleResourceDay = new Resource<Vehicle>();
                vehicleResourceDay.id = vehicle.id;
                vehicleResourceDay.daypart = Daypart.Day;
                resources.push({
                    id: vehicleResourceDay.resource,

                    columns: [{ html: vehicle.vehicleNumber }, { html: "D" }]
                });

                const vehicleResourceNight = new Resource<Vehicle>();
                vehicleResourceNight.id = vehicle.id;
                vehicleResourceNight.daypart = Daypart.Night;
                resources.push({
                    id: vehicleResourceNight.resource,
                    columns: [{ html: "" }, { html: "N" }]
                });
            }

            return resources;
        }
    );

export const getEvents = () =>
    createSelector(
        getVehicleState,
        UserSelectors.getUserState,
        getEmployeeActivityState,
        getVehicleActivityState,
        getStatusState,
        (
            vehicleState: VehicleState,
            userState: UserState,
            employeeActivityState: EmployeeActivityState,
            vehicleActivityState: VehicleActivityState,
            statusState: StatusState
        ) => {
            const events = [];

            const employeeActivityKeys = Object.keys(
                employeeActivityState.entities
            );
            const employeeActivityKeysLength = employeeActivityKeys.length;
            for (let i = 0; i < employeeActivityKeysLength; i++) {
                const employeeActivity: IEmployeeActivity =
                    employeeActivityState.entities[employeeActivityKeys[i]];
                if (employeeActivity) {
                    employeeActivity.vehicle =
                        vehicleState.entities[employeeActivity.vehicleId];
                    employeeActivity.user = new User(
                        userState.entities[employeeActivity.userId]
                    );
                    if (employeeActivity.user && employeeActivity.vehicle) {
                        const isPlannable = employeeActivity.user.role.rules.filter(
                            rule => {
                                return rule.name === Variables.PLANNABLE_RULE;
                            }
                        );
                        if (isPlannable.length > 0) {
                            if (
                                employeeActivity.activityDaypart ===
                                Daypart.Both
                            ) {
                                events.push(
                                    createEmployeeEvent(
                                        {
                                            ...employeeActivity,
                                            activityDaypart: Daypart.Day
                                        },
                                        Daypart.Both
                                    )
                                );
                                events.push(
                                    createEmployeeEvent(
                                        {
                                            ...employeeActivity,
                                            activityDaypart: Daypart.Night
                                        },
                                        Daypart.Both
                                    )
                                );
                            } else {
                                events.push(
                                    createEmployeeEvent(employeeActivity)
                                );
                            }
                        }
                    }
                }
            }

            const vehicleActivityKeys = Object.keys(
                vehicleActivityState.entities
            );
            const vehicleActivityKeysLength = vehicleActivityKeys.length;
            for (let i = 0; i < vehicleActivityKeysLength; i++) {
                const vehicleActivity: IVehicleActivity =
                    vehicleActivityState.entities[vehicleActivityKeys[i]];
                if (vehicleActivity) {
                    vehicleActivity.vehicle =
                        vehicleState.entities[vehicleActivity.vehicleId];
                    vehicleActivity.color =
                        statusState.entities[vehicleActivity.statusId].color;
                    if (vehicleActivity.vehicle) {
                        events.push(
                            createVehicleEvent(
                                vehicleActivity,
                                Daypart.Day,
                                statusState.entities[vehicleActivity.statusId]
                                    .title
                            )
                        );
                        events.push(
                            createVehicleEvent(
                                vehicleActivity,
                                Daypart.Night,
                                statusState.entities[vehicleActivity.statusId]
                                    .title
                            )
                        );
                    }
                }
            }
            return events;
        }
    );

export const createEmployeeEvent = (
    activity: IEmployeeActivity,
    overwriteResourceDaypart: Daypart = undefined
): any => {
    let html = '<span class="description">';
    html += activity.user.lastName;
    html += "</span>";
    const activityResource = new Resource<IEmployeeActivity>(
        activity,
        "vehicleId"
    );
    return {
        id: activity.id,
        resource: activityResource.resource,
        bubbleHtml: activity.comment,
        start: activity.startDate,
        end: activity.endDate,
        html,
        activity:
            overwriteResourceDaypart !== undefined
                ? { ...activity, activityDaypart: Daypart.Both }
                : activity,
        height: 20,
        moveVDisabled: true,
        moveHDisabled: true,
        resizeDisabled: true,
        type: "employee"
    };
};

export const createVehicleEvent = (
    vehicleActivity: IVehicleActivity,
    daypart: Daypart,
    title: string
): any => {
    let html = '<span class="description">';
    html += title;
    html += "</span>";
    const vehicleActivityResource = new Resource<IVehicleActivity>(
        vehicleActivity,
        "vehicleId"
    );
    vehicleActivityResource.daypart = daypart;

    return {
        id: `${vehicleActivity.id}_${daypart}`,
        bubbleHtml: vehicleActivity.comment,
        resource: vehicleActivityResource.resource,
        start: vehicleActivity.startDate,
        end: vehicleActivity.endDate,
        html,
        vehicleActivity,
        height: 20,
        moveVDisabled: false,
        moveHDisabled: false,
        resizeDisabled: false,
        type: "vehicle"
    };
};
