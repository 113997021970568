import { StatusActions } from "./status.actions";
import { StatusState } from "./status.state";
import { statusAdapter } from "./status.adapter";

const initialState: StatusState = statusAdapter.getInitialState();

export function StatusReducer(state: StatusState = initialState, action) {
  switch (action.type) {
    case StatusActions.ADD_STATUSES:
        return statusAdapter.addMany(
          action.payload,
          statusAdapter.removeAll(state)
        );
    case StatusActions.EDIT_STATUS:
        return statusAdapter.updateOne({
            id: action.payload.id,
            changes: action.payload,
            },
            state
        );
    case StatusActions.ADD_STATUS:
        return statusAdapter.addOne(action.payload, state);
    case StatusActions.REMOVE_STATUS:
        return statusAdapter.removeOne(action.payload, state);
    default:
      return state;
  }
}
