import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthRepository } from "./auth/auth.repository";
import { NotificationRepository } from "./notification/notification.repository";
import { UserRepository } from "./user/user.repository";
import { EmployeeActivityRepository } from "./employee-activity/employee-activity.repository";
import { VehicleRepository } from "./vehicle/vehicle.repository";
import { RoleRepository } from "./role/role.repository";
import { VehicleActivityRepository } from "./vehicle-activity/vehicle-activity.repository";
import { MessageRepository } from "./message/message.repository";
import { SchemeRepository } from "./scheme/scheme.repository";
import { DashboardRepository } from "./dashboard/dashboard.repository";
import { StatusRepository } from "./status/status.repository";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    EmployeeActivityRepository,
    VehicleActivityRepository,
    StatusRepository,
    AuthRepository,
    NotificationRepository,
    UserRepository,
    VehicleRepository,
    RoleRepository,
    MessageRepository,
    SchemeRepository,
    DashboardRepository
  ]
})
export class RepositoriesModule { }
