var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { User } from "./user";
export var initialState = {
    apiToken: undefined,
    user: new User,
};
var Auth = /** @class */ (function () {
    function Auth(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    Auth.parse = function (data) {
        return deserialize(Auth, data);
    };
    __decorate([
        JsonProperty("api_token"),
        __metadata("design:type", String)
    ], Auth.prototype, "apiToken", void 0);
    __decorate([
        JsonProperty({ clazz: User, name: "user" }),
        __metadata("design:type", User)
    ], Auth.prototype, "user", void 0);
    return Auth;
}());
export { Auth };
