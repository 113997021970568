var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as StatusSelectors from "../../states/status/status.selectors";
import { of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { NotificationActions } from "@app/states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { Status } from "@app/models/status";
import { StatusActions } from "@app/states/status/status.actions";
import { StatusService } from "@app/services/status/status.service";
import { Store } from "@ngrx/store";
var StatusRepository = /** @class */ (function (_super) {
    __extends(StatusRepository, _super);
    function StatusRepository(store, notificationActions, statusService) {
        var _this = _super.call(this, store, notificationActions) || this;
        _this.store = store;
        _this.notificationActions = notificationActions;
        _this.statusService = statusService;
        _this.statuses$ = _this.store.select(StatusSelectors.selectAll);
        _this.CREATE_SUCCESS = "Status aangemaakt!";
        _this.EDIT_SUCCESS = "Status aangepast!";
        return _this;
    }
    StatusRepository.prototype.index = function () {
        var _this = this;
        return this.statusService.index().pipe(map(function (response) {
            var statuses = Status.parseMany(response);
            _this.store.dispatch(StatusActions.addStatuses(statuses));
            return statuses;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    StatusRepository.prototype.create = function (request) {
        var _this = this;
        return this.statusService.create(request).pipe(map(function (response) {
            var status = Status.parse(response);
            _this.store.dispatch(StatusActions.addStatus(status));
            _this.setInfo(_this.CREATE_SUCCESS);
            return status;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    StatusRepository.prototype.update = function (id, request) {
        var _this = this;
        return this.statusService.update(id, request).pipe(map(function (response) {
            var status = Status.parse(response);
            _this.store.dispatch(StatusActions.editUser(status));
            _this.setInfo(_this.EDIT_SUCCESS);
            return status;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    StatusRepository.prototype.delete = function (id) {
        var _this = this;
        return this.statusService.delete(id).pipe(map(function (response) {
            _this.store.dispatch(StatusActions.removeStatus(id));
            return response;
        }), catchError(function (response) {
            _this.setError(JSON.stringify(response.error));
            return observableOf(undefined);
        }));
    };
    return StatusRepository;
}(Repository));
export { StatusRepository };
