import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MessageState } from "./message.state";
import { messageAdapter } from "./message.adapter";
import { Message } from "@models/message";

export const getMessageState = createFeatureSelector<MessageState>("messages");

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = messageAdapter.getSelectors(getMessageState);

export const selectUnreadMessage = createSelector(
  getMessageState,
  (messageState: MessageState) => {
    return (<number[]>messageState.ids).reduce((result: Message[], id: number) => {
      const message = new Message(messageState.entities[id]);
      if (!message.confirmed) {
        result.push(message);
      }
      return result;
    }, []);
  }
);
