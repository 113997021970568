export enum EmployeeActivityTypeDetails {
  SpecialLeave = 0,
  DayOff = 1,
  Sick = 2,
  Unavailable = 3,
}

// tslint:disable-next-line:variable-name
export const EmployeeActivityTypeDetailsTranslated = new Map<number, string>([
  [EmployeeActivityTypeDetails.SpecialLeave, "Bijzonder verlof"],
  [EmployeeActivityTypeDetails.DayOff, "Snipper"],
  [EmployeeActivityTypeDetails.Sick, "Ziek"],
  [EmployeeActivityTypeDetails.Unavailable, "Onbeschikbaar"],
]);
