var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import * as io_ from "socket.io-client";
var io = io_;
import { AuthRepository } from "@repositories/auth/auth.repository";
import { EmployeeActivityRepository } from "@repositories/employee-activity/employee-activity.repository";
import { UserRepository } from "@repositories/user/user.repository";
import { VehicleActivityRepository } from "@repositories/vehicle-activity/vehicle-activity.repository";
import { VehicleRepository } from "@repositories/vehicle/vehicle.repository";
import { MessageRepository } from "@repositories/message/message.repository";
import { environment } from "../../../environments/environment";
var WebsocketService = /** @class */ (function () {
    function WebsocketService(authRepository, activityRepository, vehicleActivityRepository, userRepository, messageRepository, vehicleRepository) {
        this.authRepository = authRepository;
        this.activityRepository = activityRepository;
        this.vehicleActivityRepository = vehicleActivityRepository;
        this.userRepository = userRepository;
        this.messageRepository = messageRepository;
        this.vehicleRepository = vehicleRepository;
        this.subscriptions = [];
    }
    /**
     * Disconnect the socket server and destroy subscriptions
     */
    WebsocketService.prototype.disconnect = function () {
        var e_1, _a;
        if (this.socket) {
            this.socket.disconnect();
        }
        try {
            for (var _b = __values(this.subscriptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var subscription = _c.value;
                subscription.unsubscribe();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * On authenticated; connect to the socket server and handle events
     */
    WebsocketService.prototype.connect = function () {
        var _this = this;
        this.subscriptions.push(this.authRepository.auth$.subscribe(function (auth) {
            if (auth.isAuthenticated && (!_this.socket || !_this.socket.connected)) {
                // Set up connection details
                var socketOptions = {
                    broadcaster: "socket.io",
                    host: "" + environment.socket,
                    auth: {
                        headers: {
                            Authorization: "Bearer " + auth.apiToken
                        }
                    },
                    authEndpoint: environment.api + "/broadcasting/auth",
                    namespace: undefined,
                    key: undefined,
                    path: "/socket.io"
                };
                // Subscribe to channel
                _this.socket = io(socketOptions.host, socketOptions);
                _this.socket.emit("subscribe", {
                    channel: "private-REDUX",
                    auth: socketOptions.auth
                });
                // Subscribe to socket events
                _this.userSocketEvents();
                _this.activitySocketEvents();
                _this.vehicleActivitySocketEvents();
                _this.vehicleSocketEvents();
                _this.messageSocketEvents();
                // On connect
                _this.socket.on("connect", function () {
                    _this.authRepository.setSocketToken(_this.socket.id);
                });
            }
        }));
    };
    /**
     * EmployeeActivity socket events
     */
    WebsocketService.prototype.activitySocketEvents = function () {
        var _this = this;
        this.socket.on("ACTIVITY_STORED", function (channel, payload) {
            _this.activityRepository.createFromSocket(payload);
        });
        this.socket.on("ACTIVITY_UPDATED", function (channel, payload) {
            _this.activityRepository.updateFromSocket(payload);
        });
        this.socket.on("ACTIVITY_DELETED", function (channel, payload) {
            _this.activityRepository.deleteFromSocket(payload);
        });
    };
    /**
     * Vehicle-activity socket events
     */
    WebsocketService.prototype.vehicleActivitySocketEvents = function () {
        var _this = this;
        this.socket.on("VEHICLE_ACTIVITY_STORED", function (channel, payload) {
            _this.vehicleActivityRepository.createFromSocket(payload);
        });
        this.socket.on("VEHICLE_ACTIVITY_UPDATED", function (channel, payload) {
            _this.vehicleActivityRepository.updateFromSocket(payload);
        });
        this.socket.on("VEHICLE_ACTIVITY_DELETED", function (channel, payload) {
            _this.vehicleActivityRepository.deleteFromSocket(payload);
        });
    };
    /**
     * User socket events
     */
    WebsocketService.prototype.userSocketEvents = function () {
        var _this = this;
        this.socket.on("USER_STORED", function (channel, payload) {
            _this.userRepository.createFromSocket(payload);
        });
        this.socket.on("USER_UPDATED", function (channel, payload) {
            _this.userRepository.updateFromSocket(payload);
        });
        this.socket.on("USER_DELETED", function (channel, payload) {
            _this.userRepository.deleteFromSocket(payload);
        });
    };
    /**
     * Vehicle socket events
     */
    WebsocketService.prototype.vehicleSocketEvents = function () {
        var _this = this;
        this.socket.on("VEHICLE_STORED", function (channel, payload) {
            _this.vehicleRepository.createFromSocket(payload);
        });
        this.socket.on("VEHICLE_UPDATED", function (channel, payload) {
            _this.vehicleRepository.updateFromSocket(payload);
        });
        this.socket.on("VEHICLE_DELETED", function (channel, payload) {
            _this.vehicleRepository.deleteFromSocket(payload);
        });
    };
    /**
     * Message socket events
     */
    WebsocketService.prototype.messageSocketEvents = function () {
        var _this = this;
        this.socket.on("MESSAGE_CREATED", function (channel, payload) {
            _this.messageRepository.createFromSocket(payload);
        });
    };
    return WebsocketService;
}());
export { WebsocketService };
