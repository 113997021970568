import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CanDirective } from "src/app/directives/can.directive";
import { RangePickerModule } from "src/app/directives/rangepicker/rangepicker.module";

@NgModule({
  declarations: [CanDirective],
  entryComponents: [],
  imports: [CommonModule, RangePickerModule],
  exports: [CanDirective, RangePickerModule]
})
export class DirectivesModule {}
