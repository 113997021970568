import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {

  @Input() title = "Weet u het zeker?";
  @Input() confirmButtonText = "Bevestigen";
  @Input() confirmButtonClass = "btn-primary";
  @Input() cancelButtonText = "Annuleren";
  @Input() cancelButtonClass = "btn-secondary";
  @Input() hideCancelButton = false;
  @Input() message: string = undefined;

  public confirmed = false;

  constructor(
    public bsModalRef: BsModalRef
  ) {
  }
  confirm(): void {
    this.confirmed = true;
    this.bsModalRef.hide();
  }

  close(): void {
    this.confirmed = false;
    this.bsModalRef.hide();
  }
}
