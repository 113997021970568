import "moment/locale/nl";

import * as moment from "moment";

import { Injectable } from "@angular/core";

moment.locale("nl");

@Injectable()
export class WeekStateService {

  private week: number;
  private year: number;

  constructor() {}

  public init(): void {
    this.week = localStorage.getItem("week")
      ? +localStorage.getItem("week")
      : moment().isoWeek();
    this.year = localStorage.getItem("year")
      ? +localStorage.getItem("year")
      : moment().year();
  }

  public reset(): void {
    localStorage.removeItem("week");
    localStorage.removeItem("year");
  }

  public getWeek(): number {
    return this.week ? this.week : moment().isoWeek();
  }

  public getYear(): number {
    return this.year ? this.year : moment().year();
  }

  public setWeek(week: number): void {
    this.week = week;
    localStorage.setItem("week", week.toString());
  }

  public setYear(year: number): void {
    this.year = year;
    localStorage.setItem("year", year.toString());
  }
}
