import { Sorting } from "../../models/enums/sorting.enum";
import { VehicleActivityState } from "./vehicle-activity.state";
import { VehicleActivityActions } from "./vehicle-activity.actions";
import { vehicleActivityAdapter } from "./vehicle-activity.adapter";

const initialState: VehicleActivityState = vehicleActivityAdapter.getInitialState({
  sort: Sorting.asc,
  lastUpdate: undefined
});

export function VehicleActivityReducer(state: VehicleActivityState = initialState, action): VehicleActivityState {
  switch (action.type) {
    case VehicleActivityActions.ADD_VEHICLE_ACTIVITIES:
      return {
        ...vehicleActivityAdapter.addMany(
          action.payload.vehicleActivities,
          vehicleActivityAdapter.removeAll(state)
        ),
        lastUpdate: action.payload.lastUpdate
      };
    case VehicleActivityActions.ADD_VEHICLE_ACTIVITY:
      return vehicleActivityAdapter.addOne(action.payload, state);
    case VehicleActivityActions.EDIT_VEHICLE_ACTIVITY:
      return vehicleActivityAdapter.updateOne({
          id: action.payload.id,
          changes: action.payload,
        },
        state
      );
    case VehicleActivityActions.DELETE_VEHICLE_ACTIVITY:
      return vehicleActivityAdapter.removeOne(action.payload, state);
    case VehicleActivityActions.TOGGLE_VEHICLE_SORT:
      return {
        ...state,
        sort: state.sort === Sorting.asc ? Sorting.desc : Sorting.asc
      };
    default:
      return state;
  }
}

