import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoginResponse } from "@responses/login.response";
import { Observable } from "rxjs";
import { prepareAuthHeaders, prepareBody } from "@helpers/http";
import { LoginRequest } from "@requests/login.request";
import { ForgotPasswordRequest } from "@requests/forgot-password.request";
import { ResetPasswordRequest } from "@requests/reset-password.request";
import { ResetPasswordResponse } from "@responses/reset-password.response";
import { ForgotPasswordResponse } from "@responses/forgot-password.response";
import { MeResponse } from "@responses/me.response";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {}

    public me(): Observable<MeResponse> {
        const headers = prepareAuthHeaders();
        return this.http.get<MeResponse>(`${environment.api}/me`, { headers });
    }

    public login(request: LoginRequest): Observable<LoginResponse> {
        const body = prepareBody(request);
        return this.http.post<LoginResponse>(`${environment.api}/login`, body);
    }

    public logout(): Observable<any> {
        const headers = prepareAuthHeaders();
        return this.http.post(`${environment.api}/logout`, {}, { headers });
    }

    public forgotPassword(
        request: ForgotPasswordRequest
    ): Observable<ForgotPasswordResponse> {
        const body = prepareBody(request);
        return this.http.post<ForgotPasswordResponse>(
            `${environment.api}/password/email`,
            body
        );
    }

    public resetPassword(
        request: ResetPasswordRequest
    ): Observable<ResetPasswordResponse> {
        const body = prepareBody(request);
        return this.http.post<ResetPasswordResponse>(
            `${environment.api}/password/reset`,
            body
        );
    }

    public activateAccount(
        request: ResetPasswordRequest
    ): Observable<ResetPasswordResponse> {
        const body = prepareBody(request);
        return this.http.post<ResetPasswordResponse>(
            `${environment.api}/activate`,
            body
        );
    }
}
