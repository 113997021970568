import { Component, OnInit } from "@angular/core";
import { Category } from "@interfaces/category.interface";
import { FileService } from "@services/file/file.service";
import { CategoryService } from "@services/category/category.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-general-file-upload-modal-component",
  templateUrl: "./general-file-upload-modal-component.component.html",
  styleUrls: ["./general-file-upload-modal-component.component.scss"]
})
export class GeneralFileUploadModalComponentComponent implements OnInit {
  public selectedFile: File = undefined;
  public category: string;
  public fd: FormData;
  public categories: Category[];
  public validFile: boolean;

  constructor(
    private fileService: FileService,
    private categoryService: CategoryService,
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  public getCategories(): void {
    this.categoryService.index()
      .subscribe(res => {
        this.categories = res.categories;
      });
  }

  public onCategorySelected(event): void {
    this.category = event.target.value;
    this.completeFormData();
  }

  public onFileSelected(event): void {
    this.selectedFile = <File>event.target.files[0];
    this.validFile = this.validateFileType(event.target.files[0]);
    this.completeFormData();
  }

  public validateFileType(file) {
    const ext = file.name.split(".").pop();
    return ext === "xlsx" || ext === "pdf" || ext === "docx";
  }

  private completeFormData(): void {
    if (!this.validateFileType(this.selectedFile)) {
      return;
    }
    if (!this.formValidation()) {
      return;
    }

    this.fd = new FormData();
    this.fd.append("category_id", this.category);
    this.fd.append("document", this.selectedFile, this.selectedFile.name);
  }

  onUpload(): void {
    this.fileService.upload(this.fd)
      .subscribe(() => {
        this.close();
      });
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public formValidation(): any {
    return this.category || this.selectedFile;
  }
}
