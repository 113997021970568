import { Store } from "@ngrx/store";
import { NotificationActions } from "../states/notification/notification.actions";
import { AppState } from "../states/app/app.state";
import { Observable } from "rxjs";

export abstract class Repository {
  public info$: Observable<string> = this.store.select(s => s.notification.info);
  public error$: Observable<string> = this.store.select(s => s.notification.error);
  public success$: Observable<string> = this.store.select(s => s.notification.success);
  public warning$: Observable<string> = this.store.select(s => s.notification.warning);

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
  ) { }

  protected setError(message: string): void {
    // Clear the previous error to make sure all subscriptions are triggered when the same error occurs again
    this.store.dispatch(NotificationActions.clearError());
    this.store.dispatch(NotificationActions.setError(message));
  }

  protected setInfo(message: string): void {
    // Clear the previous info to make sure all subscriptions are triggered when the same info occurs again
    this.store.dispatch(NotificationActions.clearInfo());
    this.store.dispatch(NotificationActions.setInfo(message));
  }

  protected setSuccess(message: string): void {
    // Clear the previous success to make sure all subscriptions are triggered when the same success occurs again
    this.store.dispatch(NotificationActions.clearSuccess());
    this.store.dispatch(NotificationActions.setSuccess(message));
  }

  protected setWarning(message: string): void {
    // Clear the previous warning to make sure all subscriptions are triggered when the same warning occurs again
    this.store.dispatch(NotificationActions.clearWarning());
    this.store.dispatch(NotificationActions.setWarning(message));
  }
}
