import { ResetPassword as IResetPassword } from "./interfaces/reset-password.interface";
import { JsonProperty, deserialize } from "json-typescript-mapper";
import { User } from "./user";
import { ResetPasswordResponse } from "../responses/reset-password.response";
import { Auth as IAuth } from "./interfaces/auth.interface";

export const initialState: IResetPassword = {
  success: undefined,
  message: undefined,
  apiToken: undefined,
  user: new User,
};

export class ResetPassword implements IResetPassword, IAuth {
  @JsonProperty("success")
  public success: boolean;
  @JsonProperty("message")
  public message: string;
  @JsonProperty("api_token")
  public apiToken: string;
  @JsonProperty({ clazz: User, name: "user" })
  public user: User;

  constructor(init?: Partial<IResetPassword>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parse(data: ResetPasswordResponse): ResetPassword {
    return deserialize(ResetPassword, data);
  }
}
