import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders, prepareBody } from "@helpers/http";
import { VehiclesResponse } from "@responses/vehicles.response";
import { VehicleCreateRequest } from "@requests/vehicle-create.request";
import { VehicleResponse } from "@responses/vehicle.response";
import { VehicleUpdateRequest } from "@requests/vehicle-update.request";
import { environment } from "../../../environments/environment";

@Injectable()
export class VehicleService {

    constructor(
        private http: HttpClient
    ) {
    }

  public index(): Observable<VehiclesResponse> {
    const headers = prepareAuthHeaders();
    return this.http.get<VehiclesResponse>(`${environment.api}/vehicles`, {headers});
  }

  public create(request: VehicleCreateRequest): Observable<VehicleResponse> {
    const body = prepareBody(request);
    const headers = prepareAuthHeaders();
    return this.http.post<VehicleResponse>(`${environment.api}/vehicle`, body, {headers});
  }

  public update(id: number, request: VehicleUpdateRequest): Observable<VehicleResponse> {
    const body = prepareBody(request);
    const headers = prepareAuthHeaders();
    return this.http.put<VehicleResponse>(`${environment.api}/vehicle/${id}`, body, {headers});
  }

  public delete(id: number): Observable<boolean> {
    const headers = prepareAuthHeaders();
    return this.http.delete<boolean>(`${environment.api}/vehicle/${id}`, {headers});
  }

}
