import { SchemeState } from "./scheme.state";
import { SchemeActions } from "./scheme.actions";
import { schemeAdapter } from "./scheme.adapter";

const initialState: SchemeState = schemeAdapter.getInitialState();

export function SchemeReducer(state: SchemeState = initialState, action): SchemeState {
  switch (action.type) {
    case SchemeActions.ADD_SCHEMES:
      return schemeAdapter.addMany(
        action.payload,
        schemeAdapter.removeAll(state)
      );
    case SchemeActions.ADD_SCHEME:
      return schemeAdapter.upsertOne(
        action.payload,
        state
      );
    default:
      return state;
  }
}
