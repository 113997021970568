import { AuthState } from "./auth.state";
import { AuthActions } from "./auth.actions";

export const initialState: AuthState = {
  apiToken: undefined,
  socketToken: undefined,
  isAuthenticated: false,
  user: undefined
};

export function AuthReducer(state: AuthState = initialState, action): AuthState {
  switch (action.type) {
    case AuthActions.SET_AUTHENTICATION:
      return {
        apiToken: action.payload.apiToken,
        isAuthenticated: true,
        user: action.payload.user
      };
    case AuthActions.CLEAR_AUTHENTICATION:
      return initialState;
    case AuthActions.SET_SOCKET_TOKEN:
      return {
        ...state,
        socketToken: action.payload
      };
    default:
      return state;
  }
}
