import { Injectable } from "@angular/core";
import { Repository } from "../repository.abstract";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";

@Injectable()
export class NotificationRepository extends Repository {

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
  ) {
    super(store, notificationActions);
  }
}
