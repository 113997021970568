import { type } from "../utils/utils";
import { Vehicle as IVehicle } from "../../models/interfaces/vehicle.interface";

export class VehicleActions {
    static ADD_VEHICLES = type("ADD_VEHICLES");
    static ADD_VEHICLE = type("ADD_VEHICLE");
    static EDIT_VEHICLE = type("EDIT_VEHICLE");
    static DELETE_VEHICLE = type("DELETE_VEHICLE");

    static addVehicles(payload: IVehicle[]) {
        return {
            type: VehicleActions.ADD_VEHICLES,
            payload
        };
    }

    static addVehicle(payload: IVehicle) {
        return {
            type: VehicleActions.ADD_VEHICLE,
            payload
        };
    }

    static editVehicle(payload: IVehicle) {
        return {
            type: VehicleActions.EDIT_VEHICLE,
            payload
        };
    }

    static deleteVehicle(id: number) {
        return {
            type: VehicleActions.DELETE_VEHICLE,
            payload: id
        };
    }
}
