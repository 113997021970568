import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
// import { AuthRepository, IConfirmDialogOptions, IMultipleChoiceDialogOptions, IUser, Rule } from "../shared/src";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { Observable } from "rxjs";
import { MultipleChoiceDialogComponent } from "src/app/components/multiple-choice-dialog/multiple-choice-dialog.component";
import { User as IUser } from "@models/interfaces/user.interface";
import { AuthRepository } from "@repositories/auth/auth.repository";
import { Rule } from "@models/rule";
import { ConfirmDialogOptions as IConfirmDialogOptions } from "@interfaces/confirm-dialog-options.interface";
import { MultipleChoiceDialogOptions as  IMultipleChoiceDialogOptions } from "@interfaces/multiple-choice-dialog-options.interface";

@Injectable()
export class AppShared {

  // Confirm dialogs
  private modalRef: BsModalRef;

  constructor(
    public authRepository: AuthRepository,
    private modalService: BsModalService
  ) {
  }

  /**
   * Checks if current logged in user has sufficient rights to execute action
   * @param action
   * @returns {Promise<any>}
   */
  public canUser(action: string) {
    return new Promise((resolve, reject) => {
      this.authRepository.auth$.pipe(map(x => x.user)).subscribe((user: IUser) => {
        user.role.rules.forEach((rule: Rule) => {
          if (rule.name === action) {
            resolve();
          }
        });
        reject("User cannot execute " + action);
      });
    });
  }

  /**
   * Checks if current logged in user has sufficient rights to execute action
   * @returns {Promise<any>}
   */
  public getUserRole() {
    return new Promise((resolve, reject) => {
      this.authRepository.auth$.pipe(map(x => x.user)).subscribe((user: IUser) => {
        resolve(user.role.name);
      });
    });
  }

  /**
   * Shows custom confirm dialog
   * @param {ConfirmDialogOptions} options
   * @returns {Observable<any>}
   */
  public confirmDialog(options: IConfirmDialogOptions) {
    this.modalRef = this.modalService.show(
      ConfirmDialogComponent,
      Object.assign({}, {
        class: "modal-lg",
        initialState: options
      })
    );

    return new Observable((observer) => {
      this.modalService.onHide.subscribe((reason) => {
        if (!reason) {
          observer.next(this.modalRef.content.confirmed);
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }

  /**
   * Shows custom multiple choice dialog
   * @param {MultipleChoiceDialogOptions} options
   * @returns {Observable<any>}
   */
  public multipleChoiceDialog(options: IMultipleChoiceDialogOptions) {
    this.modalRef = this.modalService.show(
      MultipleChoiceDialogComponent,
      Object.assign({}, {
        class: "modal-lg",
        initialState: options
      })
    );

    return new Observable((observer) => {
      this.modalService.onHide.subscribe((reason) => {
        if (!reason) {
          observer.next({
            firstChoice: this.modalRef.content.firstChoice,
            secondChoice: this.modalRef.content.secondChoice
          });
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }

}
