<div class="modal-header">
  <h4 class="modal-title">{{category.category_name}}</h4>
  <h3>Weet u zeker dat u deze categorie wilt verwijderen?</h3>
</div>

<div class="modal-body ">
  <div class="row my-2">
    <div class="col">
      <button (click)="delete()" class="btn btn-danger float-left">Verwijderen</button>
      <a class="close-modal float-right" (click)="close()">Sluiten</a>
    </div>
  </div>
</div>
