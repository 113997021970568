import { type } from "../utils/utils";
import { Message as IMessage } from "../../models/interfaces/message.interface";

export class MessageActions {
    static ADD_MESSAGES = type("ADD_MESSAGES");
    static ADD_MESSAGE = type("ADD_MESSAGE");
    static DELETE_MESSAGE = type("DELETE_MESSAGE");

    static addMessages(payload: IMessage[]) {
        return {
            type: MessageActions.ADD_MESSAGES,
            payload
        };
    }

    static addMessage(payload: IMessage) {
        return {
            type: MessageActions.ADD_MESSAGE,
            payload
        };
    }

    static deleteMessage(id: number) {
      return {
        type: MessageActions.DELETE_MESSAGE,
        payload: id
      };
    }
}
