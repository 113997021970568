var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { deserialize, JsonProperty } from "json-typescript-mapper";
import { Rule } from "./rule";
export var initialState = {
    id: undefined,
    name: undefined,
    rules: undefined
};
var Role = /** @class */ (function () {
    function Role(init) {
        // Assign this, args and initialState of make sure all properties are available on creation
        Object.assign(this, initialState, init);
    }
    Role.parseMany = function (data) {
        return data.roles.map(function (role) { return deserialize(Role, role); });
    };
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], Role.prototype, "id", void 0);
    __decorate([
        JsonProperty("name"),
        __metadata("design:type", String)
    ], Role.prototype, "name", void 0);
    __decorate([
        JsonProperty({ clazz: Rule, name: "rules" }),
        __metadata("design:type", Array)
    ], Role.prototype, "rules", void 0);
    return Role;
}());
export { Role };
