var Resource = /** @class */ (function () {
    function Resource(data, idKey) {
        if (idKey === void 0) { idKey = "id"; }
        switch (typeof data) {
            case "string": // Data is supplied as complete resource string
                var dataString = data;
                this.resource = dataString;
                break;
            case "object": // Data is supplied as an model
                var dataObject = data;
                this.resourceId = dataObject[idKey];
                this.resourceDaypart = dataObject["activityDaypart"];
                break;
            default:
                break;
        }
    }
    Object.defineProperty(Resource.prototype, "resource", {
        get: function () {
            return this.resourceId + "." + this.resourceDaypart;
        },
        set: function (resource) {
            var resources = resource.split(".");
            this.resourceId = +resources[0];
            this.resourceDaypart = +resources[1];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "id", {
        get: function () {
            return this.resourceId;
        },
        set: function (id) {
            this.resourceId = id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "daypart", {
        get: function () {
            return this.resourceDaypart;
        },
        set: function (daypart) {
            this.resourceDaypart = daypart;
        },
        enumerable: true,
        configurable: true
    });
    return Resource;
}());
export { Resource };
