import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { prepareAuthHeaders, prepareBody } from "@helpers/http";
import { MessagesResponse } from "@responses/messages.response";
import { MessageResponse } from "@responses/message.response";
import { MessageUpdateRequest } from "@requests/message-update.request";
import { environment } from "../../../environments/environment";

@Injectable()
export class MessageService {

    constructor(
        private http: HttpClient
    ) {
    }

  public index(): Observable<MessagesResponse> {
    const headers = prepareAuthHeaders();
    return this.http.get<MessagesResponse>(`${environment.api}/messages`, {headers});
  }

  public update(request: MessageUpdateRequest): Observable<MessageResponse> {
    const headers = prepareAuthHeaders();
    const body = prepareBody(request);
    return this.http.post<MessageResponse>(`${environment.api}/message/update`, body, {headers});
  }

}
