import { NotificationActions } from "./notification.actions";
import { NotificationState } from "./notification.state";

export const initialState: NotificationState = {
  error: undefined,
  info: undefined,
  warning: undefined,
};

export function NotificationReducer(state: NotificationState = initialState, action) {
  switch (action.type) {
    case NotificationActions.SET_ERROR:
      return {
        error: action.payload
      };
    case NotificationActions.CLEAR_ERROR:
      return {
        error: undefined
      };
    case NotificationActions.SET_INFO:
      return {
        info: action.payload
      };
    case NotificationActions.CLEAR_INFO:
      return {
        info: undefined
      };
    case NotificationActions.SET_WARNING:
      return {
        warning: action.payload
      };
    case NotificationActions.CLEAR_WARNING:
      return {
        warning: undefined
      };
    default:
      return state;
  }
}
