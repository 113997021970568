import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthService } from "./auth/auth.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { UserService } from "./user/user.service";
import { EmployeeActivityService } from "./employee-activity/employee-activity.service";
import { VehicleService } from "./vehicle/vehicle.service";
import { WebsocketService } from "./websocket/websocket.service";
import { RoleService } from "./role/role.service";
import { AuthenticationInterceptor } from "@helpers/http-interceptor";
import { DeviceInterceptor } from "@helpers/device-interceptor";
import { VehicleActivityService } from "./vehicle-activity/vehicle-activity.service";
import { MessageService } from "./message/message.service";
import { SchemeService } from "./scheme/scheme.service";
import { DashboardService } from "./dashboard/dashboard.service";
import { FileService } from "./file/file.service";
import { CategoryService } from "./category/category.service";
import { WeekStateService } from "@services/week-state/week-state.service";
import { StatusService } from "./status/status.service";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [],
    providers: [
        WeekStateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DeviceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        EmployeeActivityService,
        VehicleActivityService,
        StatusService,
        AuthService,
        UserService,
        VehicleService,
        RoleService,
        MessageService,
        WebsocketService,
        SchemeService,
        DashboardService,
        FileService,
        CategoryService,
    ]
})
export class ServicesModule {}
