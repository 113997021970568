import { Observable, of as observableOf } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../states/app/app.state";
import { NotificationActions } from "../../states/notification/notification.actions";
import { Repository } from "../repository.abstract";
import { MessageActions } from "../../states/message/message.actions";
import { MessageService } from "../../services/message/message.service";
import { MessageSocketResponse } from "../../responses/message-socket.response";
import { Message } from "../../models/message";
import { Message as IMessage } from "../../models/interfaces/message.interface";
import * as MessageSelectors from "../../states/message/message.selectors";
import { AuthRepository } from "../auth/auth.repository";
import { AuthState } from "../../states/auth/auth.state";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageUpdateRequest } from "../../requests/message-update.request";

@Injectable()
export class MessageRepository extends Repository {
  public messages$: Observable<IMessage[]> = this.store.select(MessageSelectors.selectAll);

  constructor(
    protected store: Store<AppState>,
    protected notificationActions: NotificationActions,
    private messageService: MessageService,
    private authRepository: AuthRepository
  ) {
    super(store, notificationActions);
  }

  public index(): Observable<{} | IMessage[]> {
    return this.messageService.index().pipe(map(response => {
        const messages = Message.parseMany(response);
        this.store.dispatch(MessageActions.addMessages(messages));
        return messages;
      }), catchError(response => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

  public createFromSocket(response: MessageSocketResponse): void {
    this.authRepository.auth$.subscribe((auth: AuthState) => {
      const message = Message.parseSocket(response);
      if (message && auth.user.id === message.userId) {
        this.store.dispatch(MessageActions.addMessage(message));
      }
    });
  }

  public confirm(id: number): Observable<{} | IMessage> {
    const request = new MessageUpdateRequest({
      id,
      confirmed: true
    });
    return this.messageService.update(request).pipe(map(response => {
        const message = Message.parse(response);
        this.store.dispatch(MessageActions.deleteMessage(message.id));
        return message;
      }), catchError((response: HttpErrorResponse) => {
        this.setError(JSON.stringify(response.error));
        return observableOf(undefined);
      })
    );
  }

}
