import { deserialize, JsonProperty } from "json-typescript-mapper";
import { Role as IRole } from "./interfaces/role.interface";
import { RolesResponse } from "../responses/roles.response";
import { Rule } from "./rule";

export const initialState: IRole = {
  id: undefined,
  name: undefined,
  rules: undefined
};

export class Role implements IRole {
  @JsonProperty("id")
  public id: number;
  @JsonProperty("name")
  public name: string;
  @JsonProperty({ clazz: Rule, name: "rules" })
  public rules: Rule[];

  constructor(init?: Partial<IRole>) {
    // Assign this, args and initialState of make sure all properties are available on creation
    Object.assign(this, initialState, init);
  }

  public static parseMany(data: RolesResponse): Role[] {
    return data.roles.map(role => deserialize(Role, role));
  }
}
