import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as _moment from "moment";
import { Variables } from "@app/shared.variables";
import { EmployeeActivity } from "@models/employee-activity";

@Component({
  selector: "app-notification-item",
  templateUrl: "./notification-item.component.html",
  styleUrls: ["./notification-item.component.scss"]
})
export class NotificationItemComponent implements OnInit {
  // Readonly fields
  // Inputs & Outputs
  @Input() activity: EmployeeActivity;
  @Output("accept") accepted = new EventEmitter<EmployeeActivity>();
  @Output("decline") declined = new EventEmitter<EmployeeActivity>();
  // Observables
  // Public properties
  public moment = _moment;
  public dateFormat = Variables.ACTIVITY_DATE_FORMAT;
  // Private fields

  constructor() { }

  ngOnInit() {
  }

  /**
   * Fire the accepted eventEmitter
   * @param accepted
   */
  public accept(): void {
    this.accepted.emit(this.activity);
  }

  /**
   * Fire the declined eventEmitter
   * @param declined
   */
  public decline(): void {
    this.declined.emit(this.activity);
  }
}
