<div class="container-fluid">
  <div class="row sidebar-container" *ngIf="(loaded$ | async) || !(isAuthenticated$ | async)">
    <div class="sidebar" *ngIf="isAuthenticated$ | async" [hidden]="sidebarIsClosed">
      <app-nav-bar></app-nav-bar>
    </div>
    <div [ngClass]="(isAuthenticated$ | async) ? 'sidebar-main' : 'col'">
      <app-top-bar [(sidebarToggle)]="sidebarIsClosed" *ngIf="isAuthenticated$ | async" [activities]="(pendingActivities$ | async)"></app-top-bar>
      <div class="router-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div *ngIf="!(loaded$|async) && (isAuthenticated$|async)">
    <app-loader></app-loader>
    <app-footer></app-footer>
  </div>
</div>