var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JsonProperty, deserialize } from "json-typescript-mapper";
export var initialState = {
    id: undefined,
    title: undefined,
    color: undefined
};
var Status = /** @class */ (function () {
    function Status(init) {
        Object.assign(this, initialState, init);
    }
    Status.parseMany = function (data) {
        return data.statuses.map(function (status) { return deserialize(Status, status); });
    };
    Status.parse = function (data) {
        return deserialize(Status, data);
    };
    __decorate([
        JsonProperty("id"),
        __metadata("design:type", Number)
    ], Status.prototype, "id", void 0);
    __decorate([
        JsonProperty("title"),
        __metadata("design:type", String)
    ], Status.prototype, "title", void 0);
    __decorate([
        JsonProperty("color"),
        __metadata("design:type", String)
    ], Status.prototype, "color", void 0);
    return Status;
}());
export { Status };
