import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import * as io_ from "socket.io-client";
const io = io_;
import { AuthRepository } from "@repositories/auth/auth.repository";
import { EmployeeActivityRepository } from "@repositories/employee-activity/employee-activity.repository";
import { UserRepository } from "@repositories/user/user.repository";
import { EmployeeActivitySocketResponse } from "@responses/employee-activity-socket.response";
import { VehicleActivitySocketResponse } from "@responses/vehicle-activity-socket.response";
import { UserSocketResponse } from "@responses/user-socket.response";
import { VehicleActivityRepository } from "@repositories/vehicle-activity/vehicle-activity.repository";
import { VehicleSocketResponse } from "@responses/vehicle-socket.response";
import { VehicleRepository } from "@repositories/vehicle/vehicle.repository";
import { MessageSocketResponse } from "@responses/message-socket.response";
import { MessageRepository } from "@repositories/message/message.repository";
import { environment } from "../../../environments/environment";

@Injectable()
export class WebsocketService {

    private subscriptions: Subscription[] = [];
    private socket: SocketIOClient.Socket;

    constructor(
        private authRepository: AuthRepository,
        private activityRepository: EmployeeActivityRepository,
        private vehicleActivityRepository: VehicleActivityRepository,
        private userRepository: UserRepository,
        private messageRepository: MessageRepository,
        private vehicleRepository: VehicleRepository,
    ) {
    }

    /**
     * Disconnect the socket server and destroy subscriptions
     */
    disconnect(): void {
        if (this.socket) {
            this.socket.disconnect();
        }
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    /**
     * On authenticated; connect to the socket server and handle events
     */
    connect(): void {
        this.subscriptions.push(this.authRepository.auth$.subscribe((auth) => {
            if (auth.isAuthenticated && (!this.socket || !this.socket.connected)) {
                // Set up connection details
                const socketOptions: any = {
                    broadcaster: "socket.io",
                    host: `${environment.socket}`,
                    auth: {
                        headers: {
                            Authorization: "Bearer " + auth.apiToken
                        }
                    },
                    authEndpoint: `${environment.api}/broadcasting/auth`,
                    namespace: undefined,
                    key: undefined,
                    path: "/socket.io"
                };
                // Subscribe to channel
                this.socket = io(socketOptions.host, socketOptions);
                this.socket.emit("subscribe", {
                    channel: "private-REDUX",
                    auth: socketOptions.auth
                });
                // Subscribe to socket events
                this.userSocketEvents();
                this.activitySocketEvents();
                this.vehicleActivitySocketEvents();
                this.vehicleSocketEvents();
                this.messageSocketEvents();
                // On connect
                this.socket.on("connect", () => {
                    this.authRepository.setSocketToken(this.socket.id);
                });
            }
        }));
    }

    /**
     * EmployeeActivity socket events
     */
    private activitySocketEvents(): void {
        this.socket.on("ACTIVITY_STORED", (channel: string, payload: EmployeeActivitySocketResponse) => {
            this.activityRepository.createFromSocket(payload);
        });
        this.socket.on("ACTIVITY_UPDATED", (channel: string, payload: EmployeeActivitySocketResponse) => {
            this.activityRepository.updateFromSocket(payload);
        });
        this.socket.on("ACTIVITY_DELETED", (channel: string, payload: EmployeeActivitySocketResponse) => {
            this.activityRepository.deleteFromSocket(payload);
        });
    }

    /**
     * Vehicle-activity socket events
     */
    private vehicleActivitySocketEvents(): void {
        this.socket.on("VEHICLE_ACTIVITY_STORED", (channel: string, payload: VehicleActivitySocketResponse) => {
            this.vehicleActivityRepository.createFromSocket(payload);
        });
        this.socket.on("VEHICLE_ACTIVITY_UPDATED", (channel: string, payload: VehicleActivitySocketResponse) => {
            this.vehicleActivityRepository.updateFromSocket(payload);
        });
        this.socket.on("VEHICLE_ACTIVITY_DELETED", (channel: string, payload: VehicleActivitySocketResponse) => {
            this.vehicleActivityRepository.deleteFromSocket(payload);
        });
    }

    /**
     * User socket events
     */
    private userSocketEvents(): void {
        this.socket.on("USER_STORED", (channel: string, payload: UserSocketResponse) => {
            this.userRepository.createFromSocket(payload);
        });
        this.socket.on("USER_UPDATED", (channel: string, payload: UserSocketResponse) => {
            this.userRepository.updateFromSocket(payload);
        });
        this.socket.on("USER_DELETED", (channel: string, payload: UserSocketResponse) => {
            this.userRepository.deleteFromSocket(payload);
        });
    }

    /**
     * Vehicle socket events
     */
    private vehicleSocketEvents(): void {
        this.socket.on("VEHICLE_STORED", (channel: string, payload: VehicleSocketResponse) => {
            this.vehicleRepository.createFromSocket(payload);
        });
        this.socket.on("VEHICLE_UPDATED", (channel: string, payload: VehicleSocketResponse) => {
            this.vehicleRepository.updateFromSocket(payload);
        });
        this.socket.on("VEHICLE_DELETED", (channel: string, payload: VehicleSocketResponse) => {
            this.vehicleRepository.deleteFromSocket(payload);
        });
    }


    /**
     * Message socket events
     */
    private messageSocketEvents(): void {
      this.socket.on("MESSAGE_CREATED", (channel: string, payload: MessageSocketResponse) => {
        this.messageRepository.createFromSocket(payload);
      });
    }


}
